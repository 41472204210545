#slideslist li {
  z-index: 1;
}

.badgee.dropdown:hover .dropdown-menu {
  display: block !important;
}

.text-left {
  text-align: left;
}

.md_close {
  text-align: right;
}

.md_close img {
  width: 20px;
  cursor: pointer;
}

#ghost_user_modal .btn-close:hover {
  background-color: #22c6ab !important;
}

#ghost_user_modal .form-control {
  box-shadow: none !important;
  min-height: 36px !important;
  height: 2.25rem !important;
  font-size: 14px !important;
  display: flex;
  font-weight: normal;
}
#ghost_user_modal .k-widget.form-control .k-input {
  padding: 15px !important;
  position: inherit !important;
}
#ghost_user_modal .k-auto-complete #ghost_user_selection.form-control {
  border-color: transparent;
  box-shadow: 0px 0px 5px #ccc !important;
}
#ghost_user_modal .k-auto-complete.form-control {
  padding-left: 0px !important;
  height: auto !important;
}
input#ghost_user_selection {
  border-color: transparent;
  height: 2.25rem;
  box-shadow: none !important;
}

#ghost_user_modal span.k-widget.k-autocomplete.form-control {
  display: flex;
  height: 2.25rem !important;
  box-shadow: 0px 0px 5px #ccc !important;
}
.markasready {
  margin: 0;
}

.topbar {
  z-index: 9;
}

.bdge-100 {
  width: 100%;
}

.game_badge .dropdown-menu {
  padding-bottom: 0;
}

.game_badge {
  cursor: pointer;
  width: 70px;
  padding-left: 20px;
}

a.badgee {
  color: #fff !important;
}

.icon.badgee {
  height: 35px;
}

.dropdown-toggle:after {
  display: inline-block;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0.3em solid;
  border-right: 0.3em solid transparent;
  border-bottom: 0;
  border-left: 0.3em solid transparent;
}

a.badgee.dropdown-item {
  padding: 12px 15px;
  font-weight: 600;
  background-image: linear-gradient(45deg, #323743, #323743bd);
  color: #fff;
}

ul.badge_list {
  text-align: left;
  padding-left: 0px !important;
  display: flex;
  justify-content: space-between;
  align-items: center;
  list-style: none;
  width: 100%;
  height: 65px;
}

.hgss {
  padding: 0px !important;
}

#left-btn {
  background: #4d5061 !important;
  width: 40px !important;
  position: relative;
  left: 0px;
}

#left-btn:before {
  top: 10px !important;
  left: 10px !important;
  content: "\f060" !important;
  font-family: "Font Awesome 5 Free";
  font: normal normal normal 4px/1 FontAwesome;
  font-size: 14px;
}

#right-btn {
  background: #4d5061 !important;
  margin: 0px !important;
  width: 40px !important;
  position: relative;
  right: 0px;
}

#right-btn:before {
  top: 10px !important;
  right: 10px !important;
  content: "\f061" !important;
  font-family: "Font Awesome 5 Free";
  font: normal normal normal 4px/1 FontAwesome;
  font-size: 14px;
}

.pro-pic {
  padding-right: 0px !important;
  padding-left: 0px !important;
}

.cu-h {
  height: 40px;
}

.custombgg {
  background: linear-gradient(
    45deg,
    rgba(39, 41, 51, 1) 0%,
    rgba(55, 61, 75, 1) 100%
  );
}

.bw-ui {
  list-style: none;
}

.bw-img {
  margin-top: 0.5em;
}

#bw-mn {
  min-width: 24rem !important;
  margin: 0 !important;
  padding: 0px 0px;
  z-index: 9999;
  background-color: #fff !important;
  min-width: 24rem !important;
  width: inherit !important;
  position: absolute;
  top: 45px;
  border-radius: 4px;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
}

.shwntfy:hover > .dropdown-menu {
  display: block !important;
}

.bw-img img {
  border-radius: 40px !important;
  width: 40px !important;
  height: 40px !important;
}

#allntfcns:hover > .dropdown-menu {
  display: block !important;
}

.bw-nt-hd {
}

.hid {
  display: none;
}

.t-5 {
  top: 5px !important;
}

.shwntfy {
  text-align: right;
  font-size: 6px !important;
  padding: 5px 0px 10px 0px !important;
}

#allntfcns {
  text-align: right;
  font-size: 6px !important;
  padding: 10px 15px 10px 0px;
}

a:hover {
  text-decoration: none !important;
}

#allntfcns.dropdown-menu a {
  font-size: 12px !important;
}

.bw-nt-hd-sub {
  margin-bottom: 0px !important;
  padding: 2px 0px 0px 0px;
  font-size: 13px !important;
  white-space: normal;
}

.bw-nt-hd-type {
  font-size: 12px !important;
  color: #99abb5 !important;
  margin-bottom: 0px !important;
  padding: 2px 0px !important;
}

#notificationbody {
  /*  min-height: 300px;
     height: 300px;*/
  height: 80vh !important;
  overflow-y: auto;
}

.unread {
  background-color: rgba(0, 154, 255, 0.2);
  border-left: 2px solid #a25ddc;
}

.bw-nt-mn {
  padding: 0px;
  border-top: 1px solid rgba(204, 204, 204, 0.35);
}

.no-notfctn {
  font-weight: normal;
}

.bw-nt-mn span i {
  color: #f8b042 !important;
  font-size: 12px !important;
  padding-right: 10px;
}

.fa-user-circle {
  font-weight: lighter !important;
  color: #000 !important;
  padding-left: 5px;
}

.fa-clock {
  font-weight: lighter !important;
  font-size: 12px !important;
  padding-right: 3px;
}

.bw-con {
  padding-left: 15px;
}

.bw-pro-con {
  background-color: #13a3e3;
  padding: 10px;
  border-radius: 50%;
  color: #fff;
  font-weight: bold;
}

.bw-con span {
  color: #8ca1ac !important;
  font-size: 12px !important;
}

.bw-img img {
  border-radius: 50%;
}

.bw-ntfcs {
  font-size: 18px !important;
  font-weight: bold;
  padding: 8px 15px;
  color: #323338 !important;
  margin-bottom: 0 !important;
}

.bw-inln {
  display: inline-flex;
}

.bw-num {
  /*padding: 12px 0px 0px 0px;*/
  margin-top: -20px;
}

#dropdownMenuLink {
  display: inline-flex;
  color: #ccc !important;
  height: 65px;
  align-items: center;
}

.DTFC_ScrollWrapper {
  z-index: 1;
}

.fa-bell {
  font-size: 18px;
  color: #fff !important;
}

.lite-unique {
  color: #fff;
  font-size: 10px;
  background-color: #21c7a9;
  padding: 3px 6px;
  border-radius: 10px;
}

h4.m-b-0 {
  background-color: none !important;
  border: none !important;
  margin-top: 0px !important;
}

.dropdown-menu > li > a:hover {
  background-color: none !important;
}

li.u-nav a {
  padding: 0px !important;
}

.dropdown-menu > li > a:focus,
.dropdown-menu > li > a:hover {
  background-color: none !important;
}

ul.dropdown a.nav-link.dropdown-item {
  color: #000 !important;
}

ul.dropdown a.nav-link.dropdown-item:hover {
  color: #fff !important;
  background-color: #333 !important;
}

ul.dropdown a.nav-link.dropdown-item.active-sub1 {
  color: #fff !important;
}

ul.cu-nav-user li {
  display: block !important;
  position: relative;
  text-decoration: none;
  transition-duration: 0.5s;
}

.cu-img-circle {
  width: 35px !important;
  height: 35px !important;
}

a.active-sub1 {
  color: #fff !important;
}

li.u-nav a {
  background: none !important;
}

ul.cu-nav li {
  display: block !important;
  position: relative;
  text-decoration: none;
  transition-duration: 0.5s;
}

li.nav-item {
  font-weight: 600;
}

li.dmenu a {
  /*font-style:600;*/
  font-weight: 600;
}

a.nav-item {
  font-weight: 600;
}

ul.active-sub li a {
  color: #fff !important;
}

li.u-nav:hover a {
  color: #fff !important;
  background-color: none !important;
}

ul.dropdown li {
  padding: 0px !important;
}

ul.dropdown li a:hover {
  padding: 5px 15px 5px 15px !important;
  border-bottom: 2px solid #21c7a9;
  color: #fff !important;
}

ul.dropdown li a {
  padding: 5px 15px 5px 15px !important;
  font-family: Lato, sans-serif;
  border-bottom: 1px solid #21c7a9;
  box-shadow: 2px 0 0px 0px #e6f2fa, 0 5px 5px -5px #333, 5px 0 5px -5px #333;
}

ul.dropdown li a:active,
ul.dropdown li a:focus {
  background-color: #313542;
}

#bungie li {
  margin-left: 0px !important;
}

li.u-nav {
  color: #999 !important;
  border-bottom: 1px solid #eaeaea !important;
  font: bold 12px Lato, sans-serif !important;
  background-color: #f9f9f9 !important;
  text-decoration: none !important;
  text-shadow: none !important;
  padding: 5px !important;
}

li.u-nav a {
  color: #999 !important;
  background-color: none !important;
}

ul.cu-nav li ul {
  visibility: hidden;
  opacity: 0;
  color: #323743 !important;
  position: absolute;
  transition: all 0.5s ease;
  margin-top: 0px;
  left: 0;
  display: none;
}

ul.cu-nav-user li ul {
  visibility: hidden;
  opacity: 0;
  color: #323743 !important;
  position: absolute;
  transition: all 0.5s ease;
  margin-top: 0px;
  left: 0;
  display: none;
}

ul.cu-nav li:hover > ul,
ul.cu-nav li ul:hover {
  visibility: visible;
  opacity: 1;
  z-index: 9999;
  background-color: #fff !important;
  display: block;
}

ul.cu-nav-user li:hover > ul,
ul.cu-nav-user li ul:hover {
  visibility: visible;
  opacity: 1;
  z-index: 9999;
  background-color: #fff !important;
  display: block;
}

ul.cu-nav li {
  clear: both;
}

li.u-nav:hover {
  background-image: linear-gradient(
    45deg,
    rgba(39, 41, 51, 1) 0%,
    rgba(55, 61, 75, 1) 100%
  ) !important;
  color: #fff !important;
  border-bottom: 2px solid #21c7a9 !important;
}

li.u-nav a:hover {
  color: #fff !important;
}

ul.user-dd li {
  /*padding: 5px 0px 0px 5px !important;*/
  border-bottom: 1px solid #ccc;
  background-color: #fff;
  /*box-shadow: 0px 0px 5px #e9f9f7;*/
  width: 100%;
  color: #000;
}

ul.dropdown li a {
  color: #212529 !important;
}

ul.user-dd {
  overflow-x: hidden;
  overflow: inherit !important;
  position: absolute;
}

ul.user-dd li a:hover {
  color: #fff !important;
}

ul.dropdown li a.active-sub1 {
  color: #fff !important;
}

.navbar-light .navbar-nav .nav-link:focus {
  background-color: #313542 !important;
  color: #fff;
  font-weight: normal;
  font-style: normal;
}

ul.user-dd li a {
  color: #000;
}

.navbar-expand-lg .navbar-nav .dropdown-menu {
  z-index: 9999 !important;
}

@media (min-width: 992px) {
  .navbar-expand-lg .navbar-nav .dropdown-menu {
    position: absolute;
    z-index: 9999 !important;
  }
}

.navbar-light .navbar-nav .nav-link:focus {
  color: #fff !important;
}

ul.smile-mood {
  float: left;
  padding: 0px 0 0 0;
  margin: 0px;
}

ul.smile-mood li {
  float: right;
  list-style: none;
  width: 29px !important;
}

ul.smile-mood li a {
  float: right;
  padding: 0 5px;
}

ul.smile-mood li a > img {
  width: 30px;
  height: 29px;
}

ul.smile-mood li a.last-smile {
  float: right;
  padding: 0 0px;
}

ul.smile-mood1 li a.red {
  background: rgba(0, 0, 0, 0) url("../images/smile3small.png") no-repeat scroll
    left top;
  height: 20px;
  margin: 0 0 5px 2px;
  opacity: 0.2;
  width: 19px;
}

ul.smile-mood1 li a.active-red {
  background: rgba(0, 0, 0, 0) url("../images/smile3small.png") no-repeat scroll
    left top !important;
  height: 20px !important;
  margin: 0 0 5px 2px !important;
  opacity: 20;
  width: 19px !important;
}

ul.smile-mood1 li a.green {
  background: rgba(0, 0, 0, 0) url("../images/smile1small.png") no-repeat scroll
    left top;
  height: 20px;
  margin: 0 0 5px 2px;
  opacity: 0.2;
  width: 19px;
}

ul.smile-mood1 li a.active-green {
  background: rgba(0, 0, 0, 0) url("../images/smile1small.png") no-repeat scroll
    left top !important;
  height: 20px !important;
  margin: 0 0 5px 2px !important;
  opacity: 20;
  width: 19px !important;
}

ul.smile-mood1 li a.yellow {
  background: rgba(0, 0, 0, 0) url("../images/smile2small.png") no-repeat scroll
    left top;
  height: 20px;
  margin: 0 0 5px 2px;
  opacity: 0.2;
  width: 19px;
}

ul.smile-mood1 li a.active-yellow {
  background: rgba(0, 0, 0, 0) url("../images/smile2small.png") no-repeat scroll
    left top !important;
  height: 20px !important;
  margin: 0 0 5px 2px !important;
  opacity: 20;
  width: 19px !important;
}

.navbar-light .navbar-nav .show > .nav-link {
  color: #e9ecef;
}

.navbar-light .navbar-nav .active > .nav-link,
.navbar-light .navbar-nav .nav-link.active,
.navbar-light .navbar-nav .nav-link.show,
.navbar-light .navbar-nav .show > .nav-link {
  color: #fff !important;
}

.navbar-light .navbar-nav .nav-link:hover {
  color: #fff !important;
}

.flipInY ul li {
  list-style: none;
  padding: 5px 0px;
  background: #323743;
  border-bottom: 1px solid #dee2e69c;
}

.dropdown-item:hover {
  color: #fff;
  text-decoration: none;
  background-color: #313542;
  border-bottom: 2px solid #22c6ab;
}

.dropdown-item {
  /* padding: 0.5rem 1rem; */
}

.flipInY ul li a {
  padding: 3px 10px;
  color: #fff;
}

.flipInY ul li a:hover {
  padding: 3px 10px;
  color: #4798e8;
}

.active-sub {
  border-bottom: 2px solid #21c7a9;
  color: #fff;
  /*padding: 0px !important;*/
}

.active-sub1 {
  border-bottom: 2px solid #21c7a9;
  color: #fff !important;
  background: linear-gradient(
    45deg,
    rgba(39, 41, 51, 1) 0%,
    rgba(55, 61, 75, 1) 100%
  );
}

::-webkit-scrollbar {
  width: 19px;
  margin: 0px;
  /*border-left: 1px solid rgba(50, 52, 65, 0.1);
     border-right: 1px solid rgba(50, 52, 65, 0.1);*/
}

::-webkit-scrollbar-track {
  width: 15px;
}

::-webkit-scrollbar-thumb {
  border-radius: 10px;
  background-color: #c5c7cb;
  border: 4px solid transparent;
  background-clip: content-box;
}

::-webkit-scrollbar-corner {
  display: none;
  height: 0px;
  width: 0px;
}

.SCROLLABLE-Div-Class-Name {
  overflow-x: hidden;
}

.demoNav {
  width: 100%;
  height: 50px;
  background-color: #333;
  color: white;
}

#bungie ul {
  padding-left: 0;
}

#bungie li {
  color: #999;
}

#bungie li:hover {
  color: white;
}

.demoForm {
  padding-top: 12px;
}

.demoForm input {
  height: 20px;
}

.demoForm button {
  height: 26px;
}

#smile {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 65px;
}

#smile ul {
  display: flex;
  align-items: center;
}

.user_access_icons {
  /*line-height: 64px;*/
  cursor: pointer;
}

.user_access_icons ul {
  list-style: none;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0;
}

.user_access_icons ul li {
  padding: 0px 2px;
}

.user_access_icons .committ-tooltip {
  position: absolute;
  top: 40px;
  z-index: 9;
}

.user_access_icons .committ-tooltip div {
  line-height: 25px;
  color: #fff;
  white-space: nowrap;
  background-color: #000;
  border-radius: 3px;
  justify-content: center;
  align-items: center;
  padding-left: 8px;
  padding-right: 8px;
  font-size: 12px;
}

.game_icon img {
  width: 18px;
  height: 18px;
}

.notification_icon img {
  width: 18px;
  height: 18px;
}

.task_icon img {
  width: 18px;
  height: 18px;
}

.activity_icon img {
  width: 18px;
  height: 18px;
}

.notification_count {
  position: absolute;
  background: #ff5722;
  min-width: 18px;
  min-height: 18px;
  top: -15px;
  left: 13px;
  border-radius: 18px;
  color: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 11px;
  font-weight: 600;
  padding: 0px 5px;
}

.divide_block {
  height: 28px;
  border-left: 1px solid rgba(255, 255, 255, 0.22);
  align-self: center;
  margin: 0px 10px;
}

.user_login_profile {
  color: #fff;
  padding-right: 15px;
  line-height: 64px;
  height: 64px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}

.login_icn {
  /* color: #fff; */
  background-color: rgba(222, 227, 229, 0.29);
  margin-right: 8px;
  width: 32px;
  height: 32px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 32px;
  font-weight: normal;
  font-size: 13px;
}

.align-center {
  align-items: center;
}

.user_access_icons .tool {
  width: 40px;
  height: 40px;
  background-color: transparent;
  border-radius: 8px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.user_access_icons .tool:hover {
  background-color: rgba(231, 230, 243, 0.15);
  width: 40px;
  height: 40px;
  border-radius: 8px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.notification_icon:hover {
  background-color: rgba(231, 230, 243, 0.15);
  width: 40px;
  height: 40px;
  border-radius: 8px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.user_login_profile .tool {
  /* width: 40px;
     height: 40px;*/
  background-color: transparent;
  border-radius: 8px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.user_login_profile .tool:hover {
  background-color: rgba(231, 230, 243, 0.15);
  border-radius: 8px;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 32px;
}

.arrow_down img {
  width: 20px;
  margin-left: 5px;
}

.login_icn span {
  letter-spacing: 1px;
}

.dropdown_block ul {
  display: block !important;
  padding: 0;
  margin: 0;
}

.dropdown_block ul li {
  color: #fff;
  background-image: linear-gradient(#373d4b, #272933);
  border-top: 1px solid rgba(0, 0, 0, 0.2);
  border-radius: 0;
  font-size: 12px;
  line-height: 1.5em;
  padding: 10px 15px;
  cursor: pointer;
  list-style: none;
  white-space: nowrap;
  pointer-events: auto;
  margin: 0 !important;
}

.dropdown_block ul li a {
  color: #fff;
}

.committ-tooltip.dropdown_block {
  border: 1px solid #000;
  min-width: 280px;
  /* width: 280px;*/
  margin: 0;
  padding: 0;
  box-shadow: 0 2px 20px rgba(20, 20, 43, 0.36);
}

.user_login_profile .committ-tooltip.dropdown_block {
  border: 1px solid #000;
  min-width: 200px;
  /* width: 280px; */
  margin: 0;
  position: absolute;
  padding: 0;
  box-shadow: 0 2px 20px rgba(20, 20, 43, 0.36);
  right: 0;
  z-index: 99;
  top: 35px;
  right: 5px;
  border-radius: 3px;
}

.static_bg {
  background-image: none !important;
  background-color: #000 !important;
}

.login_img img {
  width: 25px;
  height: 25px;
  border-radius: 25px;
  margin-right: 5px;
}

.hid {
  display: none;
}

.notification_icon {
  width: 40px;
  height: 40px;
  background-color: transparent;
  border-radius: 8px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.dropdown_block ul li:hover {
  border-bottom: 1px solid #01c0c8;
}

#bw-mn .h-50 {
  height: 50px !important;
}

.shwntfy #dropdownMenuLink {
  display: flex;
  justify-content: flex-start;
  align-items: start;
}

.login_name {
  font-size: 12px;
}

.header {
  box-shadow: 0 11px 16px 0 rgba(0, 0, 0, 0.06);
  padding: 8px 0;
}

.header .user-info .user-name {
  display: block;
  font-size: 13px;
  color: #828282;
}
/* .header .user-info .dropdown-toggle:after {
  position: relative;
  top: -9px;
  margin-left: 10px;
} */

.header .user-info .dropdown-menu {
  box-shadow: 0 7px 28px 0 rgba(0, 0, 0, 0.12);
  border: 0;
}

.header .user-info .dropdown-item {
  font-size: 13px;
  font-weight: 700;
  padding: 6px 15px;
  line-height: 20px;
}

.header .user-info .dropdown-toggle::after {
  vertical-align: 0.255em;
  border-top: 0.5em solid;
  border-right: 0.4em solid transparent;
  border-left: 0.4em solid transparent;
}
