/* Styles for sticky content demo
 * Author: Andrew Henderson (@AndrewHenderson)
 */

#header {
  border-bottom: 1px solid #ccc;
  width: 100%;
}

.align-center {
  align-items: center;
}

.space-between {
  justify-content: space-between;
}

.card {
  border-radius: 0px;
}

#sticky-1 {
  position: sticky;
  width: 100%;
  top: 0px;
  z-index: 3;
}

#sticky-2 {
  position: sticky;
  width: 100%;
  top: 64px;
  z-index: 2;
}

#sticky-3 {
  width: 100%;
  background: #f0f0f0;
  padding-bottom: 15px;
  padding-top: 120;
}

#sticky-4 {
  width: 100%;
  background: #ececec;
  padding-bottom: 15px;
}

#sticky-5 {
  width: 100%;
  background: #f0f0f0;
  padding-bottom: 15px;
}

#eval_history_details .form-control {
  font-size: 13px !important;
  padding: 5px 10px;
  font-weight: 600;
  min-height: 31px;
}