.custom-data-table {}

/* Table head */
.custom-data-table .rdt_TableHead {}

.custom-data-table .rdt_TableHead .rdt_TableHeadRow {}

.custom-data-table .rdt_TableHead .rdt_TableHeadRow .rdt_TableCol {
  border-right: 1px solid #dfe3eb;
  background-color: #f5f8fa;
  border-bottom-color: #dfe3eb !important;
  border-top: 1px solid #dfe3eb !important;
  text-transform: uppercase;
  font-size: 12px;
  text-align: center;
  font-weight: 500;
}

.custom-data-table .erkbiK div:first-child {
  white-space: pre-line;
}

/* .custom-data-table .rdt_TableCol div:first-child {
  white-space: normal !important;
  overflow: visible !important;
} */

/* .custom-data-table .erkbiK div:first-child {
  overflow: visible !important;
} */


/* .custom-data-table
  .rdt_TableHead
  .rdt_TableHeadRow
  .rdt_TableCol
  > div:first-child {
  display: inline-flex;
  align-items: center;
  justify-content: inherit;
  height: 100%;
  width: 100%;
  outline: none;
  user-select: none;
  overflow: hidden;
} */

/* Table Body */
.custom-data-table .rdt_TableBody {}

/* Table Row */
.custom-data-table .rdt_TableRow {}

/* Table Row */
.custom-data-table .rdt_TableCell {
  /* justify-content: left; */
  /* padding-left: 10px;
  padding-right: 10px; */
  text-align: center;
}

.bg-transparent {
  background-color: transparent !important;
}

.custom-sort table {
  width: 100%;
  border-collapse: collapse;
}

.custom-sort th,
.custom-sort td {
  padding: 8px 12px;
  border: 1px solid #ddd;
  text-align: left;
  cursor: pointer;
}

.custom-sort th.sort-asc::after {
  content: " \25B2";
  font-size: 10px;
  font-weight: normal;
  color: #6c757d;
  /* Up arrow */
}

.custom-sort th.sort-desc::after {
  content: " \25BC";
  font-size: 10px;
  font-weight: normal;
  color: #6c757d;
  /* Down arrow */
}

.custom-sort thead>tr>th.sort-desc,
.custom-sort thead>tr>th.sort-asc {
  background-color: #E5EFF8 !important;
}

#gamesnot_won_table_wrapper .table thead tr th {
  background-color: transparent !important;
  border-top-color: transparent !important;
  border-right-color: transparent !important;
  border-left-color: transparent !important;
  text-align: center !important;
  text-transform: capitalize !important;
  border-bottom-width: 1px;
}

.table-navigation-action {
  color: #0e7e97 !important;
  font-weight: 600;
  cursor: pointer;
  pointer-events: auto;
  pointer-events: auto;
  text-decoration: none;
}

.question_icon,
.badge .badge_icon {
  position: relative;
  display: inline-block;
}

#custom-tooltip,
.badge .position-relative .commit-tooltip {
  display: none;
  position: absolute;
  background-color: #000;
  color: #fff;
  padding: 5px;
  border-radius: 3px;
  /* z-index: 1; */
}


.badge .badge_icon:hover .commit-tooltip,
.question_icon:hover #custom-tooltip {
  display: block;
}