html {
    font-family: "Lato", sans-serif !important;
    -ms-text-size-adjust: 100%;
    -webkit-text-size-adjust: 100%;
}

.pointer {
    display: none;
}

.dis {
    font-size: 12px;
    padding: 10px 0px 5px 0px;
    font-weight: 600;
}

.disclaim {
    font-weight: bold;
    text-transform: uppercase;
    padding-right: 5px;
    color: #000;
}

.last_updated {
    position: absolute;
    left: 0;
    top: 0;
    padding: 8px 45px 8px 45px;
    border-right: 1px solid #dfe3eb;
    border-bottom: 1px solid #dfe3eb;
    font-weight: 400;
    text-transform: uppercase;
    font-size: 12px;
    margin-bottom: 0px;
}

.block {
    display: block !important;
}

/*.dataTables_scrollHeadInner {
    width: 100% !important;
}*/

.incentive-tile {
    background-color: #90b433;
    text-align: left;
    font-size: 14px;
}

.justify-content-center {
    justify-content: center;
}

div#nps-incentive.dropdown-menu.show {
    transform: none !IMPORTANT;
    top: 8em !IMPORTANT;
    z-index: 1 !important;
    left: 0 !important;
    right: 0 !important;
}

.m-0 {
    margin: 0px !important;
}

#agent_recognition_modal .modal-dialog {
    max-width: 45%;
}

#agent_recognition_modal .modal-content {
    padding: 50px 30px;
    border: 1px solid #000;
    border-radius: 4px !important;
    background-color: rgba(0, 0, 0, .5);
}

#agent_recognition_modal.show {
    background-color: rgba(0, 0, 0, .70);
}

/*#agent_recognition_modal .position-relative{
    margin:1em;
}*/
#agent_recognition_modal .w-60 {
    width: 60% !important;
}

#agent_recognition_modal .w-40 {
    width: 40% !important;
    display: flex;
    justify-content: center;
}

#agent_recognition_modal .anim-close {
    z-index: 999;
}

#agent_recognition_modal .position-relative {
    padding: 0px 15px;
}

.like_bg {
    background-color: hsla(0, 0%, 100%, .1);
    margin: 0 5px;
    width: 36px;
    height: 36px;
    border-radius: 30px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.like_unlike_thumb {
    display: flex;
    justify-content: flex-start;
    align-items: center;
}

.like_unlike_thumb img {
    width: 18px;
    cursor: pointer;
}

.anim-close img {
    width: 23px;
}

.thumb_up:hover {
    background-color: rgba(108, 204, 118, .5);
}

.thumb_up {
    position: relative;
    cursor: pointer;
}

.thumb_down:hover {
    background-color: rgba(239, 83, 80, .5);
}

.thumb_down {
    cursor: pointer;
}

.anim-close:hover {
    background-color: rgba(255, 255, 255, .46);
}

.like_unlike_thumb i {
    color: #fff;
    cursor: pointer;
}

.appreciation {
    color: #fff;
    padding: 0px 0px 0px 0px;
}

.appreciation .datee {
    font-size: 14px;
    margin-bottom: 10px;
}

.appreciation p {
    font-weight: 600;
    padding: 10px 0px;
    line-height: 1.5em;
    font-size: 18px;
}

.text-center {
    text-align: center;
}

.bg-darkk {
    background-color: rgb(30 30 30 / 90%);
}

.ticker-wrap .ticker {
    display: flex;
    box-sizing: content-box;
}

div#lotie {
    /*background-color: #7460ee;*/
    border-radius: 4px;
    background-image: linear-gradient(180deg, rgba(92, 107, 192, .71), rgba(92, 107, 192, .71));
    width: 200px;
    height: 200px;
}

.ticker-wrap .ticker {
    display: flex;
    box-sizing: content-box;
}

#incentivemodal td {
    white-space: nowrap;
}

.inc_hd {
    /*background-color: #fff;*/
    padding: 10px 15px;
    font-weight: 700;
}

span.pound {
    padding-left: 5px;
    font-weight: 900;
    font-size: 16px;
}

.metric-number-tile {
    position: relative;
    overflow: hidden;
    min-height: 120px;
    width: auto;

    padding: 20px;
    -webkit-box-flex: 0;
    -webkit-flex: 0 0 auto;
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
    border-radius: 3px;
    background-color: #fff;
    box-shadow: 0 0 4px 0 rgb(0 0 0 / 25%);
    color: #272933;
}

.metric-number-tile-header {
    margin-bottom: 20px;
    font-weight: 600;
}

.metric-numbers {
    margin-bottom: 4px;
    font-size: 16px;
    line-height: 1em;
    font-weight: 700;
    color: #272933;
}

.incentive1 tbody td:first-child {
    text-align: center !important;
}

.div-block-723 {
    min-height: 42px;
    padding: 10px 20px;
    -webkit-align-self: center;
    -ms-flex-item-align: center;
    -ms-grid-row-align: center;
    align-self: center;
}

@media screen and (min-width: 1280px) {

    .div-block-723.header-robert.metric-cell,
    .div-block-723.metric-cell {
        width: 160px;
    }
}

.metric-number-tile.no-right-margin {
    margin-right: 21px;
    margin-left: 3px;
    min-width: 260px;
    width: 260px;
    height: 160px;
    padding: 20px 20px 16px;
}

@media screen and (min-width: 1550px) {
    .metric-number-tile.no-right-margin {
        margin-right: 0;
    }

    .metric-number-tile {
        position: relative;
        overflow: hidden;
        min-height: 120px;
        min-width: 260px;
        width: 260px;
        margin: 3px 0px 20px 0px;
        padding: 20px;
        -webkit-box-flex: 0;
        -webkit-flex: 0 0 auto;
        -ms-flex: 0 0 auto;
        flex: 0 0 auto;
        border-radius: 3px;
        background-color: #fff;
        box-shadow: 0 0 4px 0 rgb(0 0 0 / 25%);
        color: #272933;
    }

    .new_game_tile {
        min-width: 260px !important;
        width: 260px !important;
        padding: 0px;
        margin: 5px 0px 20px 2px !important;
        white-space: normal !important;
    }
}

.metric-number-tile-header {
    margin-bottom: 20px;
    font-weight: 600;
}

.div-block-588 {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: end;
    -webkit-align-items: flex-end;
    -ms-flex-align: end;
    align-items: flex-end;
}

.mt-2 {
    margin-top: 8px;
}

.div-block-588.mt-2 {
    margin-top: 10px;
    justify-content: flex-start !important;
}

.div-block-590 {
    margin-right: 24px;
}

.metric-numbers {
    margin-bottom: 4px;
    font-size: 24px;
    line-height: 1em;
    font-weight: 700;
}

.div-block-588 {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: end;
    -webkit-align-items: flex-end;
    -ms-flex-align: end;
    align-items: flex-end;
}

.div-block-588.mt-2 {
    margin-top: 10px;
}

.mt-2 {
    margin-top: 8px;
}

.div-block-732-copy {
    min-height: 500px;
    overflow: auto;
}

.dashboard-row-wrap {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    overflow: visible;
    margin-bottom: 48px;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-pack: start;
    -webkit-justify-content: flex-start;
    -ms-flex-pack: start;
    justify-content: flex-start;
    -webkit-box-align: start;
    -webkit-align-items: flex-start;
    -ms-flex-align: start;
    align-items: flex-start;
}

@media screen and (min-width: 1280px) {
    .dashboard-row-wrap {
        -webkit-box-orient: horizontal;
        -webkit-box-direction: normal;
        -webkit-flex-direction: row;
        -ms-flex-direction: row;
        flex-direction: row;
    }
}

.component-tile-wrap {
    position: relative;
    width: 100%;
    margin-bottom: 0;
    padding: 0;
    -webkit-box-flex: 1;
    -webkit-flex: 1;
    -ms-flex: 1;
    flex: 1;
    border-radius: 3px;
    background-color: #fff;
    box-shadow: 0 0 4px 0 rgb(0 0 0 / 25%);
}

.tile-header.new-robert {
    height: 48px;
    padding-right: 20px;
    padding-left: 20px;
    background-color: #fff;
    background-image: none;
    font-weight: 700;
    text-transform: capitalize;
}

.tile-header {
    position: relative;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    height: 45px;
    padding-right: 10px;
    padding-left: 10px;
    -webkit-box-pack: justify;
    -webkit-justify-content: space-between;
    -ms-flex-pack: justify;
    justify-content: space-between;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
    border-bottom: 1px solid rgba(50, 52, 65, .1);
    border-top-left-radius: 3px;
    border-top-right-radius: 3px;
    background-color: #fff;
    background-image: -webkit-gradient(linear, left top, left bottom, from(rgba(50, 52, 65, .03)), to(rgba(50, 52, 65, .03)));
    background-image: linear-gradient(180deg, rgba(50, 52, 65, .03), rgba(50, 52, 65, .03));
    font-weight: 400;
    text-transform: uppercase;
}

.div-block-809 {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    flex-wrap: wrap;
    padding: 20px;
}

.div-block-810 {
    margin-right: 15px;
    padding-right: 15px;
    margin-bottom: 24px;
    min-width: 200px !important;
    min-height: 200px !important;
    max-height: 100% !Important;
    border-right: 1px solid #dfe3eb;
}

.fa-folder-open:before {
    font-size: 18px;
}

.file_text {
    padding: 5px 0px 0px 0px;
}

.file_text p {
    margin-bottom: 0px;
}

.fa-download {
    vertical-align: middle;
    display: flex;
    height: 100%;
    text-align: center;
    color: #fff;
    justify-content: center;
    align-items: center;
    background-color: rgb(0 0 0 / 32%);
}

.hdn {
    display: none;
}

.fa-dwld {
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;
    height: 100%;
    border-radius: 5px;
}

.modal-content {
    border-radius: 10px !important;
}

.dwnld-file {
    cursor: pointer;
}

.text-center {
    justify-content: center !important;
}

.modal-footer>.btn {
    font-weight: 600;
}

.modal-footer>.btn-primary {
    background-color: #3e5569;
    border-color: #3e556985;
}

.dis-block {
    padding-right: 15px;
    padding-bottom: 15px;
}

.flex {
    display: flex;
}

.flex-wrap {
    flex-wrap: wrap;
}

.dwld-file {
    padding: 0px 15px;
    border-radius: 5px;
    font-size: 25px;
    background-color: #cbd6e2;
    color: #373d4b;
}

.pointer:hover {
    cursor: pointer;
}

.btn {
    border-radius: 3px;
}

.b-r-3 {
    border-radius: 3px;
}

.player {
    width: 100% !important;
    height: 100% !important;
}

.w-inline-block {
    max-width: 100%;
    padding-bottom: 15px;
}

.btn-secondary {
    color: #fff;
    font-weight: 600;
    background-color: #22c6ab !important;
    border-color: #22c6ab !important;
}

.div-block-811 {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    min-height: 100%;
    margin-bottom: 8px;
    padding: 16px;
    -webkit-box-pack: end;
    -webkit-justify-content: flex-end;
    -ms-flex-pack: end;
    justify-content: flex-end;
    -webkit-box-align: start;
    -webkit-align-items: flex-start;
    -ms-flex-align: start;
    align-items: flex-start;
    border-radius: 4px;
    background-image: -webkit-gradient(linear, left top, left bottom, from(rgba(39, 41, 51, .45)), to(rgba(39, 41, 51, .45))), url(https://assets-global.website-files.com/5cdb1b9…/5fe2363…_Sales%20Desktop-half.png);
    background-image: linear-gradient(180deg, rgba(39, 41, 51, .45), rgba(39, 41, 51, .45)), url(https://assets-global.website-files.com/5cdb1b9…/5fe2363…_Sales%20Desktop-half.png);
    background-position: 0 0, 50% 50%;
    background-size: cover;
}

.div-block-812 {
    display: inline-block;
    padding: 2px 8px;
    grid-auto-columns: 1fr;
    -ms-grid-columns: 1fr 1fr;
    grid-template-columns: 1fr 1fr;
    -ms-grid-rows: auto auto;
    grid-template-rows: auto auto;
    border-radius: 4px;
    background-color: #fff;
    color: #272933;
    font-size: 12px;
    font-weight: 700;
    text-transform: uppercase;
}

.text-link-robert {
    color: #0e7e97;
    font-weight: 700;
}

.div-block-823 {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
}

.skillset-series-preview {
    overflow: hidden;
    max-width: 104px;
    color: #7e7e7e;
    font-size: 13px;
    white-space: nowrap;
}

.text-block-223 {
    margin-right: 4px;
    margin-left: 4px;
    color: #cecece;
}

.metric-number-tile-header>a {
    color: #323441 !important;
}

.metric-number-tile.no-right-margin:hover {
    outline-offset: 0px;
    outline: rgba(14, 126, 151, .33) solid 5px;
}

.intradayy {
    background-color: #fff;
    padding: 10px 15px;
    margin: 10px 30px;
}

.intraday_heading {
    font-weight: bold;
    padding-bottom: 15px;
}

.intraday_metric_name {
    font-weight: bold;
    padding-bottom: 5px;
    white-space: nowrap;
}

.intraday_last_updated_date {
    color: gray;
}

.intraday_green {
    background-color: #d8f1e3;
    color: #016d2f;
    font-weight: 700;
}

.intraday_red {
    background-color: #f0d6d6;
    color: #b60300;
    font-weight: 700;
}

h3.metric-number-tile-header {
    color: #272933;
    font-size: 14px;
    margin-bottom: 30px;
    text-align: left !important;
}

.metric-number-tile a {
    color: #000 !important;
}

.Orange {
    color: #FFA500 !important;
}

#highlightstatus {
    width: 50px;
    height: 50px;
    border-radius: 50%;
    position: absolute;
    right: -15px;
    bottom: -25px;
}

#highlightstatus.Orange {
    background-color: #FFA500 !important;
}

.General {
    color: #00bfff !important;
}

.preselect {
    min-height: 130px !important;
    /* background-color: #000000b3;*/
}

#intraday_metrics table:first-child td:first-child {
    text-align: left;
}

#intraday_metrics table:first-child th:first-child {
    text-align: left;
}

.pd-0 {
    padding: 0px !important;
}

.icPlay {
    z-index: 9999;
}

.bal-val.rec_date {
    color: #272933 !important;
}

#highlightstatus.General {
    background-color: #00bfff !important;
}

#highlightstatus.Certification {
    background-color: #fff !important;
}

.Certification {
    color: #fff !important;
}

.Gold {
    color: #FEE901 !important;
}

#highlightstatus.Gold {
    background-color: #FEE901 !important;
}

.Silver {
    color: #90949C !important;
}

#highlightstatus.Silver {
    background-color: #90949C !important;
}

.Bronze {
    color: #783201 !important;
}

#highlightstatus.Bronze {
    background-color: #783201 !important;
}

p.preselect_status {
    margin-bottom: 4px;
    font-weight: 900;
    font-size: 18px;
}

.text-block-214.abs {
    white-space: nowrap !Important;
    word-break: break-word !Important;
    padding-left: 25px !Important;
    line-height: 16px !Important;
    font-size: 13px !important;
}

.m-t-4.abs {
    white-space: nowrap !Important;
    word-break: break-word !Important;
    padding-left: 25px !Important;
    line-height: 16px !Important;
    font-size: 13px !important;
}

div#congrates {
    position: absolute;
    width: 400px;
    height: 400px;
}

.green_bg {
    background-color: #3c934f;
}

#programtabs,
#organizationtabs {
    white-space: nowrap;
}

.marquee {
    width: 100%;
    overflow: hidden;
}

.intra-sticky {
    text-align: left;
    position: sticky;
    left: 0;
    background: #fff;
    position: -webkit-sticky;
    z-index: 9999;
}

#cox_incentive .flex {
    display: flex;
    align-items: center;
    justify-content: flex-start;
}

.inc_profile.flex {
    align-items: start;
}

.inc_name_letters .fs {
    width: 36px;
    line-height: 23px;
    height: 36px;
    font-size: 16px;
    padding: 0;
    justify-content: center;
    align-items: center;
    border-radius: 36px;
    font-weight: normal;
    letter-spacing: 1px;
}

.namee {
    font-size: 23px;
    font-weight: 500;
}

.inc_name_details p {
    font-size: 12px;
    margin-bottom: 0px;
}

.incentive_block1 .space-between {
    justify-content: space-between !important;
}

.estimate_block {
    background: #fff;
    box-shadow: 0 4px 4px 1px rgb(0 0 0 / 15%);
    padding: 15px;
    min-height: 85px;
    min-width: 195px;
    width: 195px;
    border-radius: 5px;
    height: 117px;
    margin-right: 20px;
}

.incentive_block1 {
    padding: 10px 0px 35px 0px;
    min-height: 231px;
}

.inc_profile.flex.align-start {
    padding-bottom: 15px;
}

.inc_month.flex.align-center {
    font-weight: 600;
    font-size: 19px;
}

.mb-10 {
    margin-bottom: 10px;
}

#cox_incentive .modal-content {
    background-color: #f5f8fa;
}

.inc_leftblock {
    background-color: #f5f8fa;
    padding: 20px 0px 0px 20px;
}

.inc_right_block1 {
    background-color: #f5f8fa;
    padding: 30px 20px 0px 0px;
    height: 100%;
}

.white_bg {
    color: #75b48f;
}

.white_bg .mb-10 {
    font-size: 14px;
    font-weight: 600;
}

.inc_dollar_cxpb {
    font-size: 30px;
    font-weight: 600;
    margin-top: 15px;
    margin-bottom: 10px;
}

.inc_dollar_s2s {
    font-size: 30px;
    font-weight: 600;
    margin-top: 15px;
    margin-bottom: 10px;
}

#cox_incentive .table {
    box-shadow: none;
}

#cox_incentive .table thead tr th {
    border-left: none;
    border-top: none;
    text-transform: capitalize;
    background: none;
}

#cox_incentive .table tbody tr td {
    font-weight: 700;
    padding: 15px 20px !important;
}

.inc_green {
    color: #75b48f;
}

.inc_red {
    color: #e34819;
}

.bgg {
    background: #fff;
    padding: 15px 0px 0px 0px;
    box-shadow: 0px 0px 5px #ccc;
    border-radius: 5px;
}

.bgg h3 {
    font-size: 16px;
    font-weight: 600;
}

.inc_potential_tile {
    background: #868686;
    min-width: 195px;
    width: 195px;
    color: #fff;
    padding: 15px;
    min-height: 85px;
    box-shadow: 0 4px 4px 1px rgb(0 0 0 / 15%);
    border-radius: 5px;
    margin: 0px 0px 15px 0px;
    height: 117px;
}

.inc_val {
    margin: 15px 0px;
    /* font-weight: 600; */
    letter-spacing: 3px;
    font-size: 25px;
}

.show_money {
    margin-bottom: 13px;
    padding: 10px 6px;
}

.show_money .btn {
    width: 100%;
    background: #414d5a;
    text-transform: uppercase;
    border-color: #414d5a;
    font-size: 12px;
    padding: 5px;
}

.inc_terms_cond {
    font-size: 12px;
    padding: 0px 20px 15px 20px;
}

.bgg .pl-15 {
    padding: 0px 15px;
}

.inc_input input {
    height: 25px;
}

#cox_incentive .potential_table .table tbody tr td {
    padding: 11px 20px !important;
}

.potential_table .table {
    margin-bottom: 0;
}

.pr-0 {
    padding-right: 0px;
}

.pl-0 {
    padding-left: 0px;
}

.inc_details {
    padding: 15px;
}

.inc_details button.btn.btn-primary {
    background: #348bf2;
    border-color: #348bf2;
    text-transform: uppercase;
    font-size: 12px;
}

.inc_details .mr-5 {
    margin-right: 5px !important;
}

#cox_incentive .modal-dialog {
    max-width: 95%;
}

.show_money_gif {
    margin-bottom: 10px;
}

.show_money_gif img {
    box-shadow: 0 4px 4px 1px rgb(0 0 0 / 15%);
    width: 156px;
}

#cox_incentive_s2s .modal-dialog {
    max-width: 95%;
}

#cox_incentive_cxpb .modal-dialog {
    max-width: 95%;
}

.potential_table {
    padding-top: 0px;
}

.potential_table .table tr td {
    padding: 6px 20px !important;
}

.incentive_block2 .table {
    margin-bottom: 0px;
}

.incentive_block2 .table tr td {
    white-space: nowrap;
}

.term_cond {
    padding-top: 15px;
}

button.btn.btn-primary.balance_color {
    font-size: 12px;
    width: 100%;
    border-radius: 0px;
    color: #fff;
    font-weight: normal;
}

.pbc a {
    margin: 0 !important;
}

.w-35-per {
    width: 35% !important;
}

.w-15-per {
    width: 15% !important;
}

.w-30-per {
    width: 40% !important;
}

.nowrap {
    white-space: nowrap;
}

.org_prog_block {
    min-height: auto;
    height: auto;
    max-height: 78px;
    overflow: auto;
}

.metric-number-tile .badge_img {
    padding: 0 15px;
}

.new_game_tile {
    box-shadow: 0 0 4px 0 rgb(0 0 0 / 25%);
    border-radius: 3px;
    background: #fff;
    margin: 5px 15px 7px 0px;
}

.new_game_tile:hover {
    outline-offset: 0px;
    outline: rgba(14, 126, 151, .33) solid 5px;
}

.tile_block {
    padding: 20px 20px 16px;
    height: 120px;
    white-space: normal;
}

.flex.justify-content-center.align-start {
    justify-content: center !important;
}

.game_status_indication img {
    width: 24px;
    height: 24px;
}

.game_status_indication .med {
    border: 1px solid #ddd;
    padding: 8px;
    border-radius: 100%;
}

.new_game_tile .metric_name {
    color: #7e7e7e;
    font-size: 13px;
    text-transform: uppercase;
    margin-top: 1px;
    overflow: hidden;
    text-overflow: ellipsis;
    width: 100%;
    white-space: nowrap;
}

.new_game_tile .game_name {
    font-size: 14px !important;
    color: #323441 !important;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    width: 100%;
}

.game_metric_name {
    width: calc(100% - 45px);
    padding-right: 5px;
}

.chat_count {
    display: flex;
    font-size: 14px;
    padding: 20px 0px 10px 0px;
    color: rgba(39, 41, 51, .7);
    /* font-weight: bold; */
    opacity: 0.7;
}

.chat_count .absolute {
    position: absolute;
    top: -3px;
    font-size: 10px;
    left: 8px;
}

.chat_count img {
    height: 14px;
    width: 100%;
    opacity: 0.5;
}

.chat_count div {
    padding-right: 5px;
}

.chat_count>div {
    color: #323441;
    font-weight: bold;
    padding-right: 10px;
}

li a {
    cursor: pointer;
}

.tile_bottom {
    color: rgba(39, 41, 51, .7);
    background-color: rgba(227, 231, 235, .52);
    font-size: 13px;
    padding: 8px 0px 8px 20px;
    height: 40px;
    /*  opacity:0.5;*/
}

/*.tile_bottom .pr-10{
    opacity:0.7;
}*/
.tile_team_icon {
    display: flex;
    background-color: #dadfe0;
    font-size: 12px;
    border-radius: 30px;
    width: 30px;
    height: 27px;
    justify-content: center;
    align-items: center;
    font-weight: normal;
}

.tile_team_icon>div {
    font-weight: 600;
}

.pr-15 {
    padding-right: 15px;
}

.no_opacity {
    opacity: 1 !important;
}

.tile_block .yellow {
    background: #F6BE00;
    /* width: 18px; */
    /* height: 18px; */
    display: flex;
    padding: 5px;
    border-radius: 100%;
    justify-content: center;
    align-items: center;
}

.game_status_indication .position_absolute img {
    width: 18px !important;
    height: 18px !important;
}

.game_status_indication .position_absolute .yellow img {
    width: 8px !important;
    height: 8px !important;
}

.game_status_indication .position-absolute {
    top: -5px;
    left: 30px;
}

.red img {
    width: 8px;
    height: 8px;
}

.position_absolute {
    /* padding: 10px; */
    position: absolute;
    top: -5px;
    left: 32px;
}

.position_absolute .greenn {
    width: 20px;
    height: 20px;
}

.new_game_tile {
    white-space: nowrap;
    min-width: 260px;
}

game_metric_name {
    padding-right: 15px;
    width: calc(100% - 45px);
}

.pr-2 {
    padding-right: 2px !important;
}

.absolute-s {
    font-size: 10px;
    margin-top: -5px;
}

.game_status_indication .yellow {
    background-color: #F6BE00;
}

.scorecard_type i.fa.fa-circle {
    color: #dee2e6;
    margin-right: 3px;
    font-size: 5px;
}

.scorecard_type>.d-flex {
    line-height: 12px;
}

.show_scorecards {
    position: absolute;
    right: 0;
    white-space: nowrap;
    background: #fff;
    font-size: 12px;
    border-radius: 3px;
    box-shadow: 0px 0px 5px #ccc;
    padding: 10px;
    /* top:23px;*/
}

.show_scorecards ul {
    padding: 0;
    margin: 0;
    list-style: none;
}

.show_scorecards {
    display: none;
}

.show_scorecards ul li {
    padding: 3px 5px;
}

.show_scorecards ul li:hover {
    background-color: #e9e8f3;
    border-radius: 3px;
}

.scorecard_type:hover .show_scorecards {
    display: block !important;
}


.metric-number-tile.pbc.ncc a {
    color: #9400D3 !important;
    font-size: 14px !important;
    margin-bottom: 5px !important;
}

.ncc .metric-number-tile-header {
    margin-top: 5px;
    color: #323411;
    font-size: 12px;
}

.incentive-tile.premiercard {
    text-align: center;
    /* display: flex; */
    justify-content: center;
    align-items: center;
    font-size: 16px;
    color: #54589d;
    min-height: 130px;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    /* text-decoration: underline; */
}

.pbc a {
    margin: 0 !important;
}

.metric-number-tile a {
    color: #000 !important;
}

.premiercard a {
    text-decoration: underline;
}