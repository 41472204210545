 .leaderboard__video {
     position: absolute;
     right: 0;
     bottom: 0;
     left: 0px;
     top: 0;
     height: auto;
     min-width: 100%;
 }

 /* body {
     overflow: hidden;
 } */

 input:focus,
 textarea:focus,
 select:focus {
     outline: none;
 }

 .flxx {
     display: flex;
 }

 .hei-100 {
     height: 100%;
 }


 .video_hei {
     height: 100%;
     width: 100%;
 }

 .menu_icon {
     cursor: pointer;
     width: 48px;
     text-align: center;
     padding-top: 7px;
     padding-right: 1px;
     background-color: aliceblue;
     height: 48px;
     margin-top: 26px;
     margin-right: 14px;
     margin-left: -2px;
     border-radius: 100%;
 }

 .menu_icon:hover {
     background-color: aquamarine;
 }