@charset 'utf-8';

.w-form-formradioinput--inputType-custom {
  border-top-width: 1px;
  border-bottom-width: 1px;
  border-left-width: 1px;
  border-right-width: 1px;
  border-top-color: #ccc;
  border-bottom-color: #ccc;
  border-left-color: #ccc;
  border-right-color: #ccc;
  border-top-style: solid;
  border-bottom-style: solid;
  border-left-style: solid;
  border-right-style: solid;
  width: 12px;
  height: 12px;
  border-bottom-left-radius: 50%;
  border-bottom-right-radius: 50%;
  border-top-left-radius: 50%;
  border-top-right-radius: 50%;
}

.max-w-100 {
  max-width: 100% !important;
}

.max-w-600 {
  max-width: 600px;
  width: 600px;
}

#upcoming_modal_table_wrapper .dataTables_scrollBody {
  height: 30vh;
  overflow: auto;
}

#history_modal_table_wrapper .dataTables_scrollBody {
  height: 30vh;
  overflow: auto;
}

.modal-open .modal {
  background-color: rgba(39, 41, 51, 0.76);
}

.md_close {
  position: absolute;
  top: 48px;
  right: 48px;
  cursor: pointer;
}

.md_close:hover {
  cursor: pointer;
}

.md_close>img {
  width: 24px;
  height: 24px !important;
  cursor: pointer;
}

.md_bd {
  background-color: #fff !important;
  border-radius: 3px !important;
}

.md_his {
  max-width: 1300px;
  margin: auto;
  margin-right: auto;
  margin-left: auto;
  justify-content: center;
  padding: 48px;
  border: none !important;
  background-color: transparent !important;
}

.w-form-formradioinput--inputType-custom.w--redirected-focus {
  box-shadow: 0px 0px 3px 1px #3898ec;
}

.w-form-formradioinput--inputType-custom.w--redirected-checked {
  border-top-width: 4px;
  border-bottom-width: 4px;
  border-left-width: 4px;
  border-right-width: 4px;
  border-top-color: #3898ec;
  border-bottom-color: #3898ec;
  border-left-color: #3898ec;
  border-right-color: #3898ec;
}

#leaderboard-game_modal a {
  text-decoration: none;
}

#leaderboard-game_modal label {
  display: block;
  margin-bottom: 5px;
  color: #83828d;
  font-size: 13px;
  font-weight: 700;
  text-transform: uppercase;
}

.mr-4 {
  margin-right: 20px;
}

.mr-4.alert-icon-wrap {
  position: relative;
}

.demologo {
  height: 30px;
  margin-right: 10px;
}

.demo-nav-dropdown {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  padding: 12px 8px;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  color: #fff;
}

.image-136 {
  height: 20px;
  margin-left: 5px;
  -webkit-box-flex: 0;
  -webkit-flex: 0 0 auto;
  -ms-flex: 0 0 auto;
  flex: 0 0 auto;
}

.insite-logo {
  height: 42px;
}

.insite-logo.insite-demo {
  display: none;
}

.insite-logo.insite-demo.small-insite-logo {
  height: 32px;
}

.demo-nav {
  position: fixed;
  left: 0px;
  right: 0px;
  z-index: 1005;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  height: 60px;
  padding-right: 20px;
  padding-left: 20px;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  border-bottom: 1px solid rgba(0, 0, 0, 0.2);
  background-color: #272933;
  color: #fff;
}

.demo-nav.static-nav {
  position: static;
}

#leaderboard-game_modal .avatar {
  width: 35px;
  height: 35px;
  border-radius: 100px;
}

.avatar.mr-2 {
  margin-right: 12px;
}

.demodropdownmenu {
  border-bottom-left-radius: 5px;
}

.demodropdownmenu.w--open {
  top: 50px;
  overflow: hidden;
  border-bottom-right-radius: 5px;
  box-shadow: 1px 1px 3px 0 rgba(0, 0, 0, 0.39);
}

#leaderboard-game_modal .d-flex {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
}

#leaderboard-game_modal .d-flex.align-items-center {
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
}

.text-block-197 {
  -webkit-box-flex: 0;
  -webkit-flex: 0 0 auto;
  -ms-flex: 0 0 auto;
  flex: 0 0 auto;
  font-size: 12px;
}

.demodropdownmenulink-2 {
  border-top: 1px solid rgba(0, 0, 0, 0.2);
  border-radius: 0px;
  background-image: -webkit-gradient(linear, left bottom, left top, from(#272933), to(#373d4b));
  background-image: linear-gradient(0deg, #272933, #373d4b);
  color: #fff;
  font-size: 12px;
  line-height: 1.5em;
}

.demodropdownmenulink-2:hover {
  background-image: -webkit-gradient(linear, left bottom, left top, from(#ff5722), to(#ff8a65));
  background-image: linear-gradient(0deg, #ff5722, #ff8a65);
}

.demodropdownmenulink-2.w--current {
  border-bottom: 5px solid #ff5722;
  color: #fff;
}

#leaderboard-game_modal .icon {
  height: 15px;
  margin-top: 3px;
  margin-right: 7px;
  cursor: pointer;
}

#leaderboard-game_modal .icon.medium {
  width: 24px;
  height: 24px;
  margin-top: 0px;
  margin-right: 0px;
  margin-bottom: 0px;
}

#leaderboard-game_modal .icon.medium.fade {
  opacity: 0.25;
}

#leaderboard-game_modal .icon.badge {
  height: 35px;
  margin-top: 0px;
  margin-right: 0px;
}

.demologowrap {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: end;
  -webkit-align-items: flex-end;
  -ms-flex-align: end;
  align-items: flex-end;
  color: #fff;
  font-size: 14px;
  line-height: 1.5em;
}

.demologowrap.accountbased {
  display: none;
}

.demologowrap.generic {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  grid-auto-columns: 1fr;
  -ms-grid-columns: 1fr 1fr;
  grid-template-columns: 1fr 1fr;
  -ms-grid-rows: auto auto;
  grid-template-rows: auto auto;
}

.demologowrap.accountbased {
  display: none;
  -webkit-box-align: end;
  -webkit-align-items: flex-end;
  -ms-flex-align: end;
  align-items: flex-end;
}

.demologowrap.generic {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  grid-auto-columns: 1fr;
  -ms-grid-columns: 1fr 1fr;
  grid-template-columns: 1fr 1fr;
  -ms-grid-rows: auto auto;
  grid-template-rows: auto auto;
}

.nav-menu-2 {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: end;
  -webkit-justify-content: flex-end;
  -ms-flex-pack: end;
  justify-content: flex-end;
  -webkit-box-flex: 1;
  -webkit-flex: 1;
  -ms-flex: 1;
  flex: 1;
}

#leaderboard-game_modal .body {
  background-color: #f5f8fa;
  font-family: Lato, sans-serif;
  color: #323441;
}

.performnace-goal-break-slash {
  margin-right: 4px;
  margin-left: 4px;
}

.ticker-child-level-4 {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
}

.ticker-child-level-4.ticker-card {
  height: 60px;
  max-width: 220px;
  min-width: 220px;
  margin-right: 0px;
  padding-right: 24px;
  padding-left: 24px;
  border-right: 1px solid #dfe3eb;
  -webkit-transition: background-color 200ms ease;
  transition: background-color 200ms ease;
  font-weight: 700;
  cursor: pointer;
}

.ticker-child-level-4.ticker-card:hover {
  background-color: #dbe9e1;
}

.ticker-child-level-4.ticker-card.below-goal:hover {
  background-color: #f3e0e0;
}

.full-ticker-parent {
  position: relative;
  padding-left: 180px;
  cursor: pointer;
}

.ticker-child-level-1 {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  height: 80px;
  padding: 11px 19px;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  background-color: rgba(0, 0, 0, 0.03);
}

.ticker-child-level-1.wrapper {
  position: static;
  left: 0px;
  top: 0px;
  right: 0px;
  display: block;
  overflow: hidden;
  height: 60px;
  padding: 0px;
  -webkit-box-pack: start;
  -webkit-justify-content: flex-start;
  -ms-flex-pack: start;
  justify-content: flex-start;
  border-bottom: 1px solid #dfe3eb;
  background-color: hsla(0, 0%, 100%, 0.03);
}

.ticker-child-level-1.for-program-copy {
  position: static;
  left: 0px;
  top: 0px;
  right: 0px;
  height: 60px;
  padding: 0px 48px;
  -webkit-box-pack: start;
  -webkit-justify-content: flex-start;
  -ms-flex-pack: start;
  justify-content: flex-start;
  border-bottom: 1px solid #dfe3eb;
  background-color: hsla(0, 0%, 100%, 0.03);
}

.text-block-220 {
  font-size: 12px;
  font-weight: 400;
  text-transform: uppercase;
}

.ticker-child-level-2 {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 200%;
  -webkit-box-flex: 0;
  -webkit-flex: 0 0 auto;
  -ms-flex: 0 0 auto;
  flex: 0 0 auto;
}

.ticker-metric-title {
  overflow: hidden;
  height: 20px;
  font-size: 12px;
  font-weight: 700;
}

#leaderboard-game_modal .ticker-value-number {
  color: #272933;
  font-size: 18px;
  font-weight: 900;
}

#leaderboard-game_modal .ticker-time {
  position: absolute;
  left: 0px;
  top: 0px;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 180px;
  height: 60px;
  padding-left: 48px;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  border-right: 1px solid #dfe3eb;
  border-bottom: 1px solid #dfe3eb;
}

#leaderboard-game_modal .div-block-797 {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: end;
  -webkit-align-items: flex-end;
  -ms-flex-align: end;
  align-items: flex-end;
  font-weight: 400;
}

#leaderboard-game_modal .div-block-798 {
  overflow: hidden;
  width: 75px;
  height: 25px;
  margin-left: 24px;
}

.ticker-child-level-3 {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 50%;
  -webkit-box-align: stretch;
  -webkit-align-items: stretch;
  -ms-flex-align: stretch;
  align-items: stretch;
  -webkit-box-flex: 0;
  -webkit-flex: 0 0 auto;
  -ms-flex: 0 0 auto;
  flex: 0 0 auto;
}

#leaderboard-game_modal .div-block-792 {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
}

#leaderboard-game_modal .org-program-tile {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
}

#leaderboard-game_modal .org-program-tile.organization-group {
  height: 60px;
  margin-right: 24px;
  padding-right: 24px;
  border-right: 1px solid #dfe3eb;
}

#leaderboard-game_modal .header-control-text {
  font-size: 12px;
  text-transform: uppercase;
}

#leaderboard-game_modal .header-control-text.active-time {
  font-weight: 900;
}

#leaderboard-game_modal .active-button {
  margin-left: 8px;
  padding: 6px 24px;
  border-radius: 3px;
  background-color: #22c6ab;
  -webkit-transition: background-color 200ms ease;
  transition: background-color 200ms ease;
  font-size: 14px;
  font-weight: 700;
}

#leaderboard-game_modal .active-button:hover {
  background-color: #1e6d60;
}

#leaderboard-game_modal .active-button.inactive-button {
  background-color: #cbd6e2;
  color: #373d4b;
  font-weight: 400;
}

#leaderboard-game_modal .commit-value-dot {
  position: relative;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 10px;
  height: 10px;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-flex: 0;
  -webkit-flex: 0 0 auto;
  -ms-flex: 0 0 auto;
  flex: 0 0 auto;
  border-style: solid;
  border-width: 2px;
  border-color: #fff;
  border-radius: 10px;
  background-color: #373d4b;
  -webkit-transition: box-shadow 200ms ease;
  transition: box-shadow 200ms ease;
  cursor: pointer;
}

#leaderboard-game_modal .commit-value-dot:hover {
  box-shadow: 0 0 0 10px rgba(55, 61, 75, 0.24);
}

#leaderboard-game_modal .commit-value-dot.current-spot {
  width: 16px;
  height: 16px;
  -webkit-box-flex: 0;
  -webkit-flex: 0 0 auto;
  -ms-flex: 0 0 auto;
  flex: 0 0 auto;
  background-color: #ef5350;
}

#leaderboard-game_modal .commit-value-dot.current-spot:hover {
  box-shadow: 0 0 0 10px rgba(239, 83, 80, 0.24);
}

#leaderboard-game_modal .commit-value-dot.current-spot.above-commitment {
  background-color: #6ccc76;
}

#leaderboard-game_modal .commit-value-dot.current-spot.above-commitment:hover {
  box-shadow: 0 0 0 10px rgba(108, 204, 118, 0.24);
}

#leaderboard-game_modal .commit-value-dot.end-coach {
  margin-left: 0px;
  border-color: #cdced2;
  background-color: #cdced2;
}

#leaderboard-game_modal .commit-value-dot.end-coach:hover {
  box-shadow: 0 0 0 10px rgba(205, 206, 210, 0.45);
}

#leaderboard-game_modal .commit-value-dot.start-dot {
  border-color: #373d4b;
  background-color: #fff;
}

#leaderboard-game_modal .icon-new {
  display: block;
  width: 20px;
  height: 20px;
}

#leaderboard-game_modal .div-block-788 {
  width: 20px;
  height: 20px;
  border-radius: 20px;
  background-color: #373d4b;
  box-shadow: 1px 1px 3px 0 rgba(0, 0, 0, 0.35);
}

.text-block-217 {
  margin-right: 12px;
}

.text-gray {
  color: #7e7e7e;
}

.text-gray.small-text {
  margin-top: 2px;
  font-weight: 400;
}

#leaderboard-game_modal .scorecard-comaprison-title {
  width: 140px;
  font-family: Lato, sans-serif;
  color: #929292;
  font-weight: 700;
}

#leaderboard-game_modal .scorecard-comaprison-title.user {
  color: #373d4b;
}

.badgewrap {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  margin-top: 10px;
}

.ranking-user-wrap {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
}

.new-dashboard-body {
  position: relative;
  padding: 48px;
}

.component-tile-wrap {
  position: relative;
  width: 100%;
  margin-bottom: 0px;
  padding: 0px;
  -webkit-box-flex: 1;
  -webkit-flex: 1;
  -ms-flex: 1;
  flex: 1;
  border-radius: 3px;
  background-color: #fff;
  box-shadow: 0 0 4px 0 rgba(0, 0, 0, 0.25);
}

.component-tile-wrap.quadrant-1 {
  width: 100%;
  margin-right: 24px;
  margin-bottom: 48px;
  -webkit-box-flex: 0;
  -webkit-flex: 0 0 auto;
  -ms-flex: 0 0 auto;
  flex: 0 0 auto;
  border-radius: 4px;
}

.component-tile-wrap.quadrant-2 {
  width: 100%;
  -webkit-box-flex: 1;
  -webkit-flex: auto;
  -ms-flex: auto;
  flex: auto;
  border-radius: 4px;
}

.component-tile-wrap.table-scroll-wrap-container {
  width: 100%;
  -webkit-box-flex: 0;
  -webkit-flex: 0 0 auto;
  -ms-flex: 0 0 auto;
  flex: 0 0 auto;
}

.component-tile-wrap.table-scroll-wrap-container.game-table {
  margin-bottom: 48px;
}

.component-tile-wrap.table-scroll-wrap-container.game-table.dark-mode {
  border-style: solid solid none;
  border-width: 1px;
  border-color: #000;
  background-color: transparent;
}

#leaderboard-game_modal .component-tile-wrap.bottom-margin-24 {
  overflow: hidden;
  margin-bottom: 24px;
}

#leaderboard-game_modal .coaching-commitment-placeholder {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  padding: 48px 20px;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  border-bottom-left-radius: 4px;
  border-bottom-right-radius: 4px;
  background-color: #fff;
}

#leaderboard-game_modal .tile-header {
  position: relative;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  height: 45px;
  padding-right: 10px;
  padding-left: 10px;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  border-bottom: 1px solid rgba(50, 52, 65, 0.1);
  border-top-left-radius: 3px;
  border-top-right-radius: 3px;
  background-color: #fff;
  background-image: -webkit-gradient(linear, left top, left bottom, from(rgba(50, 52, 65, 0.03)), to(rgba(50, 52, 65, 0.03)));
  background-image: linear-gradient(180deg, rgba(50, 52, 65, 0.03), rgba(50, 52, 65, 0.03));
  font-weight: 400;
  text-transform: uppercase;
}

#leaderboard-game_modal .tile-header.new-robert {
  height: 48px;
  padding-right: 20px;
  padding-left: 20px;
  background-color: #fff;
  background-image: none;
  font-size: 14px;
  font-weight: 700;
  text-transform: capitalize;
}

#leaderboard-game_modal .tile-header.new-robert.dark-mode {
  border-bottom-color: #000;
  background-color: rgba(0, 0, 0, 0.5);
  color: #fff;
}

#leaderboard-game_modal .text-link-new {
  color: #0e7e97;
  font-weight: 700;
}

#leaderboard-game_modal .text-link-new:hover {
  text-decoration: underline;
}

#leaderboard-game_modal .div-block-588 {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: end;
  -webkit-align-items: flex-end;
  -ms-flex-align: end;
  align-items: flex-end;
}

.div-block-588.mt-2 {
  margin-top: 10px;
}

#leaderboard-game_modal .marker {
  position: absolute;
  bottom: -6px;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-align-self: flex-end;
  -ms-flex-item-align: end;
  align-self: flex-end;
  background-color: #fff;
}

#leaderboard-game_modal .marker.program {
  left: 66%;
}

#leaderboard-game_modal .marker.high-persona {
  left: 91%;
}

#leaderboard-game_modal .marker.agent {
  left: 81%;
  z-index: 1;
}

#leaderboard-game_modal .marker.agent.balance-scorecard-dot-copy-copy {
  left: 67%;
  bottom: -8px;
  background-color: transparent;
}

#leaderboard-game_modal .marker.agent.balance-scorecard-dot {
  bottom: -8px;
  background-color: transparent;
}

#leaderboard-game_modal .marker.agent.program-slot {
  left: 54%;
  background-color: transparent;
}

#leaderboard-game_modal .marker.agent.balance-scorecard-dot-copy {
  left: 33%;
  bottom: -8px;
  background-color: transparent;
}

#leaderboard-game_modal .marker.agent.below-goal-example {
  left: 21%;
}

#leaderboard-game_modal .marker.team {
  left: 47%;
}

#leaderboard-game_modal .heading-robert {
  margin-top: 0px;
  margin-bottom: 4px;
  line-height: 1em;
}

#leaderboard-game_modal .text-block-214 {
  font-size: 16px;
  font-weight: 700;
}

#leaderboard-game_modal .metric-number-tile {
  position: relative;
  overflow: hidden;
  min-height: 120px;
  min-width: 180px;
  margin-right: 24px;
  margin-bottom: 24px;
  padding: 20px;
  -webkit-box-flex: 0;
  -webkit-flex: 0 0 auto;
  -ms-flex: 0 0 auto;
  flex: 0 0 auto;
  border-radius: 3px;
  background-color: #fff;
  box-shadow: 0 0 4px 0 rgba(0, 0, 0, 0.25);
  color: #272933;
}

#leaderboard-game_modal .metric-number-tile.no-right-margin {
  margin-right: 48px;
}

#leaderboard-game_modal .best-practice-element {
  width: 240px;
  margin-right: 24px;
  padding-right: 24px;
  border-right: 1px solid #dfe3eb;
}

#leaderboard-game_modal .scorecard-comparison-row {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 100%;
  margin-top: 4px;
  margin-bottom: 4px;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
}

#leaderboard-game_modal .scorecard-comparison-row.hide {
  display: none;
}

.div-block-746 {
  width: 6px;
  height: 16px;
  border-style: solid;
  border-width: 2px;
  border-color: #fff;
  border-radius: 8px;
  background-color: #ef5350;
}

.div-block-746.team-and-program {
  width: 6px;
  background-color: #ef5350;
}

.div-block-746.team-and-program.high-persona {
  background-color: #20bafc;
}

.div-block-746.team-and-program.green {
  background-color: #6ccc76;
}

.div-block-746.no-goal-line {
  width: 6px;
  background-color: #5c6bc0;
}

#leaderboard-game_modal .user-profile-image {
  width: 70px;
  height: 70px;
  margin-right: 24px;
}

#leaderboard-game_modal .user-profile-image.rounded {
  border-radius: 100px;
  box-shadow: 0 4px 4px 1px rgba(0, 0, 0, 0.15);
}

#leaderboard-game_modal .metric-number-tile-header {
  margin-bottom: 20px;
  font-weight: 700;
}

#leaderboard-game_modal .quadrant-body-scroll {
  overflow: scroll;
  height: 380px;
}

.div-block-743-copy {
  width: 60%;
  height: 4px;
  -webkit-box-flex: 1;
  -webkit-flex: 1;
  -ms-flex: 1;
  flex: 1;
  border-radius: 8px;
  background-color: #cdced2;
}

#leaderboard-game_modal .metric-details {
  display: none;
  padding: 20px;
  border-bottom: 1px solid #dfe3eb;
}

.div-block-807 {
  margin-left: 24px;
  padding-right: 0px;
  color: #ccc;
  font-size: 16px;
  line-height: 1.5em;
  font-weight: 900;
}

.div-block-719 {
  display: inline-block;
}

#leaderboard-game_modal .agent-top-section {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  margin-right: -24px;
  margin-bottom: 24px;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
}

#leaderboard-game_modal .dashboard-body {
  position: absolute;
  left: 296px;
  top: 0px;
  right: 0px;
  bottom: 0px;
  overflow: scroll;
  padding: 48px 48px 60px;
  -webkit-box-flex: 0;
  -webkit-flex: 0 auto;
  -ms-flex: 0 auto;
  flex: 0 auto;
}

.dashboard-body.static-dashboard-contents {
  position: static;
  left: 0px;
  top: 60px;
  overflow: visible;
  padding: 0px;
}

#leaderboard-game_modal .metric-numbers {
  margin-bottom: 4px;
  font-size: 24px;
  line-height: 1em;
  font-weight: 900;
}

#leaderboard-game_modal .best-practice-body {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  padding: 20px;
}

#leaderboard-game_modal .div-block-800 {
  position: relative;
  width: 60%;
  height: 180px;
  -webkit-box-flex: 1;
  -webkit-flex: 1;
  -ms-flex: 1;
  flex: 1;
}

#leaderboard-game_modal .agent-detail-info {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-align: start;
  -webkit-align-items: flex-start;
  -ms-flex-align: start;
  align-items: flex-start;
}

#leaderboard-game_modal .no-metric-data-available {
  position: absolute;
  left: 0px;
  top: 1px;
  right: 60px;
  bottom: 1px;
  z-index: 1;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  padding-right: 20px;
  padding-left: 20px;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  background-image: -webkit-gradient(linear, left top, left bottom, from(#fff), to(#fff));
  background-image: linear-gradient(180deg, #fff, #fff);
  color: #7e7e7e;
}

.no-metric-data-available.metric-detail-overlay {
  right: 0px;
}

#leaderboard-game_modal .commit-date {
  color: #979797;
  font-size: 12px;
  font-weight: 400;
}

#leaderboard-game_modal .dashboard-row-wrap {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  overflow: visible;
  margin-bottom: 48px;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-pack: start;
  -webkit-justify-content: flex-start;
  -ms-flex-pack: start;
  justify-content: flex-start;
  -webkit-box-align: start;
  -webkit-align-items: flex-start;
  -ms-flex-align: start;
  align-items: flex-start;
}

.div-block-806 {
  position: absolute;
  left: 0px;
  top: -20px;
  right: 0px;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
}

.div-block-806.date-wrap {
  top: 16px;
}

.text-block-222 {
  position: absolute;
  left: 16px;
  top: -12px;
  padding-right: 4px;
  padding-left: 4px;
  border-radius: 20px;
  background-color: #f5f8fa;
  color: #929292;
  font-size: 13px;
}

.div-block-811 {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  height: 120px;
  margin-bottom: 8px;
  padding: 16px;
  -webkit-box-pack: end;
  -webkit-justify-content: flex-end;
  -ms-flex-pack: end;
  justify-content: flex-end;
  -webkit-box-align: start;
  -webkit-align-items: flex-start;
  -ms-flex-align: start;
  align-items: flex-start;
  border-radius: 4px;
  background-image: -webkit-gradient(linear, left top, left bottom, from(rgba(39, 41, 51, 0.45)), to(rgba(39, 41, 51, 0.45))), url('../../images/gamifyy/Sales-Desktop-half.png');
  background-image: linear-gradient(180deg, rgba(39, 41, 51, 0.45), rgba(39, 41, 51, 0.45)), url('../../images/gamifyy/Sales-Desktop-half.png');
  background-position: 0px 0px, 50% 50%;
  background-size: auto, cover;
}

#leaderboard-game_modal .performance-bar-positive {
  width: 40%;
  height: 4px;
  margin-left: 1%;
  border-radius: 8px;
  background-color: #6ccc76;
}

#leaderboard-game_modal .performance-bar-positive.coaching-timeline-remaining {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  height: 4px;
  margin-left: 0%;
  -webkit-box-pack: end;
  -webkit-justify-content: flex-end;
  -ms-flex-pack: end;
  justify-content: flex-end;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-flex: 1;
  -webkit-flex: 1;
  -ms-flex: 1;
  flex: 1;
  background-color: rgba(55, 61, 75, 0.25);
}

#leaderboard-game_modal .performance-bar-positive.inactive {
  background-color: #caebce;
}

.div-block-805 {
  position: relative;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-align-self: center;
  -ms-flex-item-align: center;
  align-self: center;
  -webkit-box-flex: 1;
  -webkit-flex: 1;
  -ms-flex: 1;
  flex: 1;
}

#leaderboard-game_modal .scorecard-color-indicator {
  position: absolute;
  right: -40px;
  bottom: -40px;
  width: 80px;
  height: 80px;
  border-radius: 40px;
  background-color: #e4e4e4;
}

#leaderboard-game_modal .scorecard-color-indicator.highest-5 {
  background-color: #00b9ff;
}

#leaderboard-game_modal .scorecard-color-indicator.higher-4 {
  background-color: #6ccc76;
}

#leaderboard-game_modal .scorecard-color-indicator.average-3 {
  background-color: #fc0;
}

#leaderboard-game_modal .scorecard-color-indicator.lower-2 {
  background-color: #ff8a65;
}

#leaderboard-game_modal .user-dot {
  position: absolute;
  bottom: 16px;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 24px;
  height: 24px;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  border-radius: 100%;
  background-color: #ef5350;
  color: #fff;
  font-size: 12px;
  font-weight: 900;
}

#leaderboard-game_modal .user-dot.green {
  background-color: #6ccc76;
}

#leaderboard-game_modal .user-dot.balance-scorecard-dot {
  position: static;
}

#leaderboard-game_modal .user-dot.balance-scorecard-dot.green-value-example {
  background-color: #6ccc76;
}

#leaderboard-game_modal .user-dot.balance-scorecard-dot.high-persona {
  background-color: #00b9ff;
  color: #fff;
}

#leaderboard-game_modal .user-dot.balance-scorecard-dot.red-value-example {
  color: #fff;
}

#leaderboard-game_modal .user-dot.balance-scorecard-dot.blue-value-example {
  background-color: #20bafc;
}

#leaderboard-game_modal .user-dot.no-goal-dot {
  bottom: -18px;
  width: 18px;
  height: 18px;
  border-radius: 24px;
  background-color: #e0e2ee;
  color: #43519e;
}

#leaderboard-game_modal .user-dot.no-goal-dot:hover {
  z-index: 1;
}

#leaderboard-game_modal .user-dot.agent-dot-no-goal {
  background-color: #5c6bc0;
}

#leaderboard-game_modal .user-dot.team-and-program {
  bottom: -18px;
  width: 18px;
  height: 18px;
  border-radius: 24px;
  background-color: rgba(239, 83, 80, 0.2);
  color: #b60300;
}

#leaderboard-game_modal .user-dot.team-and-program:hover {
  z-index: 1;
}

#leaderboard-game_modal .user-dot.team-and-program.green {
  background-color: rgba(70, 211, 130, 0.2);
  color: #016d2f;
}

#leaderboard-game_modal .user-dot.team-and-program.persona {
  background-color: #c5eeff;
  color: #20bafc;
}

.text-block-223 {
  margin-right: 4px;
  margin-left: 4px;
  color: #cecece;
}

.button-9 {
  border-radius: 4px;
  background-color: #ffe9a8;
  color: #25282c;
  font-weight: 700;
  text-align: center;
}

#leaderboard-game_modal .button-9:hover {
  background-image: -webkit-gradient(linear, left top, left bottom, from(rgba(39, 41, 51, 0.1)), to(rgba(39, 41, 51, 0.1)));
  background-image: linear-gradient(180deg, rgba(39, 41, 51, 0.1), rgba(39, 41, 51, 0.1));
}

#leaderboard-game_modal .performance-status-bar-wrap {
  position: relative;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 100%;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-flex: 1;
  -webkit-flex: 1;
  -ms-flex: 1;
  flex: 1;
}

#leaderboard-game_modal .div-block-590 {
  margin-right: 24px;
}

#leaderboard-game_modal .scorecard-comparison-tile {
  position: relative;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  min-height: 120px;
  min-width: 180px;
  margin-right: 24px;
  margin-bottom: 24px;
  padding: 20px;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-box-flex: 1;
  -webkit-flex: 1;
  -ms-flex: 1;
  flex: 1;
  border-style: solid;
  border-width: 1px;
  border-color: #dfe3eb;
  border-radius: 4px;
  background-color: transparent;
  box-shadow: none;
  color: #272933;
}

#leaderboard-game_modal .performance-bar-negative {
  width: 60%;
  height: 4px;
  border-radius: 8px;
  background-color: #ef5350;
}

#leaderboard-game_modal .performance-bar-negative.coaching-commit {
  position: relative;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 60%;
  height: 4px;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  background-color: #373d4b;
}

#leaderboard-game_modal .performance-bar-negative.inactive {
  opacity: 0.3;
}

#leaderboard-game_modal .scroll-table-wrap {
  overflow: scroll;
}

#leaderboard-game_modal .div-block-741 {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  margin-right: 6px;
  margin-left: 6px;
}

.persona-icon {
  width: 14px;
  height: 14px;
}

#leaderboard-game_modal .div-block-796 {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 48px;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-pack: end;
  -webkit-justify-content: flex-end;
  -ms-flex-pack: end;
  justify-content: flex-end;
  -webkit-box-align: end;
  -webkit-align-items: flex-end;
  -ms-flex-align: end;
  align-items: flex-end;
  font-size: 12px;
  line-height: 1em;
}

#leaderboard-game_modal .div-block-739-copy {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  margin-left: 48px;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-flex: 0;
  -webkit-flex: 0 0 auto;
  -ms-flex: 0 0 auto;
  flex: 0 0 auto;
}

#leaderboard-game_modal .div-block-739-copy.tests {
  overflow: auto;
  width: 100%;
  margin: -5px -5px 24px;
  padding-top: 5px;
  padding-right: 5px;
  padding-left: 5px;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -webkit-flex-direction: row;
  -ms-flex-direction: row;
  flex-direction: row;
  -webkit-box-ordinal-group: 0;
  -webkit-order: -1;
  -ms-flex-order: -1;
  order: -1;
}

#leaderboard-game_modal .div-block-802 {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 240px;
  margin-left: 24px;
  padding-left: 24px;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-box-flex: 0;
  -webkit-flex: 0 0 auto;
  -ms-flex: 0 0 auto;
  flex: 0 0 auto;
  border-left: 1px solid #dfe3eb;
}

#leaderboard-game_modal .new-cell {
  min-height: 42px;
  padding: 10px 20px;
  -webkit-align-self: center;
  -ms-flex-item-align: center;
  -ms-grid-row-align: center;
  align-self: center;
}

#leaderboard-game_modal .new-cell.coach-rank-cell {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 100px;
  height: 72px;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-align-self: stretch;
  -ms-flex-item-align: stretch;
  align-self: stretch;
  -webkit-box-flex: 0;
  -webkit-flex: 0 auto;
  -ms-flex: 0 auto;
  flex: 0 auto;
  border-bottom: 1px solid #dfe3eb;
  text-align: center;
}

#leaderboard-game_modal .new-cell.coach-rank-cell.rank-cell {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
}

#leaderboard-game_modal .new-cell.coaching-history-cell {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 120px;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-align-self: stretch;
  -ms-flex-item-align: stretch;
  align-self: stretch;
  -webkit-box-flex: 0;
  -webkit-flex: 0 0 auto;
  -ms-flex: 0 0 auto;
  flex: 0 0 auto;
  border-top: 1px none #000;
  border-bottom: 1px solid #dfe3eb;
  font-size: 12px;
  line-height: 1.4em;
  text-align: center;
}

#leaderboard-game_modal .new-cell.coaching-history-cell.icon-cell {
  width: 60px;
}

#leaderboard-game_modal .new-cell.coaching-history-cell.id-cell {
  width: 100px;
  -webkit-box-pack: start;
  -webkit-justify-content: flex-start;
  -ms-flex-pack: start;
  justify-content: flex-start;
  border-left: 4px solid #cbd6e2;
}

#leaderboard-game_modal .new-cell.coaching-history-cell.id-cell.red-border {
  border-left-color: #ef5350;
}

#leaderboard-game_modal .new-cell.coaching-history-cell.id-cell.green-border {
  border-left-color: #6ccc76;
}

#leaderboard-game_modal .new-cell.coaching-history-cell.sub-behavior-cell {
  width: 300px;
}

#leaderboard-game_modal .new-cell.coaching-history-cell.score-cell {
  width: 80px;
}

#leaderboard-game_modal .new-cell.header-robert {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  height: 100%;
  min-height: 48px;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  border-right: 1px solid #dfe3eb;
  background-color: #f5f8fa;
}

#leaderboard-game_modal .new-cell.header-robert.performnace-bar-cell {
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-box-flex: 1;
  -webkit-flex: 1 0 300px;
  -ms-flex: 1 0 300px;
  flex: 1 0 300px;
  border-bottom: 1px solid #dfe3eb;
  text-align: center;
}

#leaderboard-game_modal .new-cell.header-robert.coach-rank-cell {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-flex: 0;
  -webkit-flex: 0 auto;
  -ms-flex: 0 auto;
  flex: 0 auto;
  border-bottom-color: #dfe3eb;
}

#leaderboard-game_modal .new-cell.header-robert.coaching-history-cell {
  width: 150px;
  height: 42px;
  -webkit-box-flex: 0;
  -webkit-flex: 0 0 auto;
  -ms-flex: 0 0 auto;
  flex: 0 0 auto;
}

#leaderboard-game_modal .new-cell.header-robert.coaching-history-cell.sub-behavior-cell {
  -webkit-box-flex: 0;
  -webkit-flex: 0 0 auto;
  -ms-flex: 0 0 auto;
  flex: 0 0 auto;
}

#leaderboard-game_modal .new-cell.header-robert.coaching-history-cell.id-cell {
  width: 100px;
  -webkit-box-pack: start;
  -webkit-justify-content: flex-start;
  -ms-flex-pack: start;
  justify-content: flex-start;
  border-left-style: solid;
  border-left-color: #f5f8fa;
}

#leaderboard-game_modal .new-cell.header-robert.metric-cell {
  width: 180px;
  height: 42px;
  -webkit-box-flex: 0;
  -webkit-flex: 0 0 auto;
  -ms-flex: 0 0 auto;
  flex: 0 0 auto;
}

#leaderboard-game_modal .new-cell.header-robert.commitment-status-bar {
  height: 42px;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-box-flex: 300px;
  -webkit-flex: 300px;
  -ms-flex: 300px;
  flex: 300px;
  text-align: center;
}

#leaderboard-game_modal .new-cell.header-robert.intraday-cell {
  -webkit-box-flex: 0;
  -webkit-flex: 0 0 auto;
  -ms-flex: 0 0 auto;
  flex: 0 0 auto;
  border-bottom: 1px solid #dfe3eb;
  white-space: nowrap;
}

#leaderboard-game_modal .new-cell.header-robert.intraday-cell.stretch-cell {
  -webkit-box-flex: 1;
  -webkit-flex: 1;
  -ms-flex: 1;
  flex: 1;
}

#leaderboard-game_modal .new-cell.header-robert.bs-cell {
  width: 96px;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-flex: 0;
  -webkit-flex: 0 0 auto;
  -ms-flex: 0 0 auto;
  flex: 0 0 auto;
  border-bottom: 1px solid #dfe3eb;
  white-space: nowrap;
}

#leaderboard-game_modal .new-cell.header-robert.metric-min-cell {
  min-width: 172px;
  -webkit-box-flex: 1;
  -webkit-flex: 1;
  -ms-flex: 1;
  flex: 1;
}

#leaderboard-game_modal .new-cell.header-robert.metric-min-cell.no-stretch {
  -webkit-box-flex: 0;
  -webkit-flex: 0 0 auto;
  -ms-flex: 0 0 auto;
  flex: 0 0 auto;
}

#leaderboard-game_modal .new-cell.header-robert.coaching-history-cell {
  width: 150px;
  height: 42px;
  -webkit-box-flex: 0;
  -webkit-flex: 0 0 auto;
  -ms-flex: 0 0 auto;
  flex: 0 0 auto;
  font-size: 12px;
}

#leaderboard-game_modal .new-cell.header-robert.coaching-history-cell.start-cell.dark-mode {
  border-right-color: #000;
}

#leaderboard-game_modal .new-cell.header-robert.coaching-history-cell.expand-full {
  font-size: 12px;
}

#leaderboard-game_modal .new-cell.header-robert.coaching-history-cell.expand-full.dark-mode {
  background-color: transparent;
}

#leaderboard-game_modal .new-cell.header-robert.coaching-history-cell.dark-mode {
  border-right-color: #000;
  background-color: transparent;
}

#leaderboard-game_modal .new-cell.metric-cell {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 180px;
  height: 72px;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-align-self: stretch;
  -ms-flex-item-align: stretch;
  align-self: stretch;
  -webkit-box-flex: 0;
  -webkit-flex: 0 0 auto;
  -ms-flex: 0 0 auto;
  flex: 0 0 auto;
  border-bottom: 1px solid #dfe3eb;
  line-height: 1.25em;
}

#leaderboard-game_modal .new-cell.commitment-status-bar {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  height: 72px;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-align-self: stretch;
  -ms-flex-item-align: stretch;
  align-self: stretch;
  -webkit-box-flex: 300px;
  -webkit-flex: 300px;
  -ms-flex: 300px;
  flex: 300px;
  border-bottom: 1px solid #dfe3eb;
}

#leaderboard-game_modal .new-cell.performance-bar-cell {
  position: relative;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  height: 72px;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-align-self: stretch;
  -ms-flex-item-align: stretch;
  align-self: stretch;
  -webkit-box-flex: 1;
  -webkit-flex: 1 0 300px;
  -ms-flex: 1 0 300px;
  flex: 1 0 300px;
  border-bottom: 1px solid #dfe3eb;
}

#leaderboard-game_modal .new-cell.metric-min-cell {
  width: 172px;
  border-bottom: 1px solid #dfe3eb;
  font-weight: 700;
}

#leaderboard-game_modal .new-cell.metric-min-cell.higher-4 {
  position: relative;
  min-width: 172px;
  padding-left: 16px;
  -webkit-box-flex: 1;
  -webkit-flex: 1;
  -ms-flex: 1;
  flex: 1;
  border-left: 4px solid #6ccc76;
  line-height: 1em;
  font-weight: 700;
}

#leaderboard-game_modal .new-cell.metric-min-cell.average-3 {
  position: relative;
  min-width: 172px;
  padding-left: 16px;
  -webkit-box-flex: 1;
  -webkit-flex: 1;
  -ms-flex: 1;
  flex: 1;
  border-left: 4px solid #fc0;
  line-height: 1em;
  font-weight: 700;
}

#leaderboard-game_modal .new-cell.metric-min-cell.lower-2 {
  position: relative;
  min-width: 172px;
  padding-left: 16px;
  -webkit-box-flex: 1;
  -webkit-flex: 1;
  -ms-flex: 1;
  flex: 1;
  border-left: 4px solid #ff8a65;
  line-height: 1em;
  font-weight: 700;
}

#leaderboard-game_modal .new-cell.metric-min-cell.highest-5 {
  position: relative;
  min-width: 172px;
  padding-left: 16px;
  -webkit-box-flex: 1;
  -webkit-flex: 1;
  -ms-flex: 1;
  flex: 1;
  border-left: 4px solid #00b9ff;
  line-height: 1em;
  font-weight: 700;
}

#leaderboard-game_modal .new-cell.metric-min-cell.lowest-1 {
  position: relative;
  min-width: 172px;
  padding-left: 16px;
  -webkit-box-flex: 1;
  -webkit-flex: 1;
  -ms-flex: 1;
  flex: 1;
  border-left: 4px solid #ef5350;
  line-height: 1em;
  font-weight: 700;
}

#leaderboard-game_modal .new-cell.bs-cell {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 96px;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-align-self: stretch;
  -ms-flex-item-align: stretch;
  align-self: stretch;
  -webkit-box-flex: 0;
  -webkit-flex: 0 0 auto;
  -ms-flex: 0 0 auto;
  flex: 0 0 auto;
  border-bottom: 1px solid #dfe3eb;
}

#leaderboard-game_modal .new-cell.intraday-cell {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 72px;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-align-self: stretch;
  -ms-flex-item-align: stretch;
  align-self: stretch;
  -webkit-box-flex: 0;
  -webkit-flex: 0 0 auto;
  -ms-flex: 0 0 auto;
  flex: 0 0 auto;
  border-bottom: 1px solid #dfe3eb;
}

#leaderboard-game_modal .new-cell.intraday-cell.stretch-cell {
  -webkit-box-flex: 1;
  -webkit-flex: 1;
  -ms-flex: 1;
  flex: 1;
}

#leaderboard-game_modal .new-cell.coaching-history-cell {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 150px;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-align-self: stretch;
  -ms-flex-item-align: stretch;
  align-self: stretch;
  -webkit-box-flex: 0;
  -webkit-flex: 0 0 auto;
  -ms-flex: 0 0 auto;
  flex: 0 0 auto;
  border-top: 1px none #000;
  border-bottom: 1px solid #dfe3eb;
  font-size: 13px;
  line-height: 1.4em;
  text-align: center;
}

#leaderboard-game_modal .new-cell.coaching-history-cell.start-cell {
  -webkit-box-pack: start;
  -webkit-justify-content: flex-start;
  -ms-flex-pack: start;
  justify-content: flex-start;
}

#leaderboard-game_modal .modal-content {
  border-radius: 5px !important;
}

#leaderboard-game_modal .z-ind {
  position: relative;
}

#leaderboard-game_modal .bg_hv {
  background-color: #22c6ab21;
  position: absolute;
  top: 0;
  height: 240px;
  width: 360px;
  right: 0;
  left: 0;
  bottom: 0;
  z-index: 99999;
}

#leaderboard-game_modal .slct {
  box-shadow: 0px 0px 5px #141516;
  background-color: #cccccc59;
}

#leaderboard-game_modal .slctt {
  background-color: #cccccc59;
}

#leaderboard-game_modal .video-preview-card:hover {
  box-shadow: 0px 0px 5px #141516;
  background-color: #cccccc59 !important;
}

#leaderboard-game_modal .new-cell.coaching-history-cell.start-cell.stretch-cell {
  min-width: 230px;
  -webkit-box-flex: 1;
  -webkit-flex: 1;
  -ms-flex: 1;
  flex: 1;
  text-align: left;
}

#leaderboard-game_modal .new-cell.coaching-history-cell.expand-full {
  -webkit-box-flex: 1;
  -webkit-flex: 1;
  -ms-flex: 1;
  flex: 1;
  font-size: 13px;
}

#leaderboard-game_modal .new-cell.coaching-history-cell.dark-mode {
  border-bottom-color: #000;
  background-color: rgba(0, 0, 0, 0.5);
  color: #fff;
}

#leaderboard-game_modal .commit-tooltip {
  position: absolute;
  top: 16px;
  z-index: 10;
  display: none;
  padding: 4px 11px;
  border-style: solid;
  border-width: 1px;
  border-color: #dfe3eb;
  border-radius: 4px;
  background-color: #fff;
  box-shadow: 1px 1px 12px 0 rgba(0, 0, 0, 0.19);
  color: #272933;
  font-size: 12px;
  line-height: 1.25em;
  font-weight: 400;
}

#leaderboard-game_modal .div-block-812 {
  display: inline-block;
  padding: 2px 8px;
  grid-auto-columns: 1fr;
  -ms-grid-columns: 1fr 1fr;
  grid-template-columns: 1fr 1fr;
  -ms-grid-rows: auto auto;
  grid-template-rows: auto auto;
  border-radius: 4px;
  background-color: #fff;
  color: #272933;
  font-size: 12px;
  font-weight: 700;
  text-transform: uppercase;
}

#leaderboard-game_modal .div-block-718 {
  display: inline-block;
  padding: 4px 8px;
  border-style: solid;
  border-width: 1px;
  border-color: transparent;
  border-radius: 3px;
  color: #0e7e97;
  font-size: 13px;
}

#leaderboard-game_modal .div-block-718.inactive-page {
  color: #949494;
}

#leaderboard-game_modal .div-block-718.current-page {
  border-style: solid;
  border-width: 1px;
  border-color: #0e7e97;
  background-color: rgba(34, 198, 171, 0.17);
  color: #0e7e97;
  font-weight: 700;
}

#leaderboard-game_modal .skillset-series-preview {
  overflow: hidden;
  max-width: 104px;
  color: #7e7e7e;
  font-size: 13px;
  white-space: nowrap;
}

#leaderboard-game_modal .current-commitment-image {
  height: 120px;
}

#leaderboard-game_modal .scorecard-comparison-value {
  width: 48px;
  color: #929292;
  font-size: 16px;
  font-weight: 700;
  text-align: right;
}

.text-block-221 {
  margin-right: 2px;
  margin-left: 2px;
}

.text-block-192 {
  display: inline-block;
}

.text-secondary {
  color: #696969;
}

#leaderboard-game_modal .div-block-794 {
  position: absolute;
  left: 20px;
  top: 0px;
  z-index: 1;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  height: 20px;
  min-width: 20px;
  padding-right: 6px;
  padding-left: 6px;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  border-radius: 25px;
  background-color: #22c6ab;
  color: #fff;
  font-size: 12px;
}

.text-block-186 {
  white-space: nowrap;
}

.text-block-227 {
  margin-top: 4px;
}

#leaderboard-game_modal .table-row-robert {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  min-height: 40px;
  border-bottom: 1px solid #dfe3eb;
}

#leaderboard-game_modal .table-row-robert:hover {
  background-color: #f5f8fa;
}

#leaderboard-game_modal .table-row-robert.table-header-row-robert-copy {
  -webkit-box-align: end;
  -webkit-align-items: flex-end;
  -ms-flex-align: end;
  align-items: flex-end;
  border-bottom-style: none;
  background-color: #f5f8fa;
  font-size: 12px;
  font-weight: 700;
  text-transform: uppercase;
}

#leaderboard-game_modal .table-row-robert.table-header-row-robert-copy.dark-mode {
  background-color: rgba(0, 0, 0, 0.5);
  color: #fff;
}

#leaderboard-game_modal .organization-summary-tile {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
}

#leaderboard-game_modal .div-block-787 {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 40px;
  height: 16px;
  margin-right: 4px;
  margin-left: 4px;
  -webkit-box-pack: end;
  -webkit-justify-content: flex-end;
  -ms-flex-pack: end;
  justify-content: flex-end;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  border-radius: 25px;
  background-color: #aaaaaf;
}

#leaderboard-game_modal .div-block-803 {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  height: 40px;
  padding-top: 12px;
  padding-bottom: 8px;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  border-top: 1px solid #dfe3eb;
  border-bottom: 1px none #dfe3eb;
  line-height: 1em;
}

#leaderboard-game_modal .div-block-803._w-140 {
  width: 140px;
}

#leaderboard-game_modal .div-block-824 {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 20px;
  height: 20px;
  margin-left: 20px;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  border-radius: 20px;
  background-color: #cfcfcf;
  color: #575757;
  font-weight: 900;
}

#leaderboard-game_modal .div-block-804 {
  overflow: hidden;
  width: 100%;
}

#leaderboard-game_modal .profile-agent {
  margin-bottom: 24px;
}

#leaderboard-game_modal .tile-footer {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  padding-top: 16px;
  padding-bottom: 16px;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
}

#leaderboard-game_modal .agent-scorecard-wrap {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  margin-left: 48px;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-flex: 1;
  -webkit-flex: 1;
  -ms-flex: 1;
  flex: 1;
  cursor: pointer;
}

#leaderboard-game_modal .performance-bar-no-goal {
  width: 100%;
  height: 4px;
  -webkit-box-flex: 0;
  -webkit-flex: 0 0 auto;
  -ms-flex: 0 0 auto;
  flex: 0 0 auto;
  border-radius: 4px;
  background-color: #cdced2;
}

#leaderboard-game_modal .dashboard-table-row {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  min-height: 40px;
  border-bottom: 1px none #dfe3eb;
}

#leaderboard-game_modal .dashboard-table-row:hover {
  background-color: rgba(255, 255, 255, 0.25);
}

#leaderboard-game_modal .dashboard-table-row.detail-expands {
  cursor: pointer;
}

#leaderboard-game_modal .dashboard-table-row.clickable-row {
  cursor: pointer;
}

#leaderboard-game_modal .badge-item {
  position: relative;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  margin-right: 20px;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  color: #373d4b;
  line-height: 1em;
  font-weight: 700;
}

#leaderboard-game_modal .smiley-wrap {
  margin-top: 5px;
  padding: 0px;
  float: right;
}

#leaderboard-game_modal .demo-menu-button-2 {
  float: left;
}

#leaderboard-game_modal .demonavbar2 {
  position: fixed;
  left: 0px;
  top: 60px;
  right: 0px;
  z-index: 1001;
  display: block;
  height: 50px;
  padding-right: 14px;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  border-bottom: 1px solid rgba(50, 52, 65, 0.1);
  border-left: 1px none rgba(0, 0, 0, 0.25);
  background-color: #fff;
  background-image: -webkit-gradient(linear, left bottom, left top, from(#272933), to(#373d4b));
  background-image: linear-gradient(0deg, #272933, #373d4b);
  color: #323441;
}

#leaderboard-game_modal .demonavbar2.static-nav {
  position: static;
}

#leaderboard-game_modal .navbar2wrap {
  position: static;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  height: 50px;
  margin-left: 16px;
  padding-top: 0px;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-box-align: stretch;
  -webkit-align-items: stretch;
  -ms-flex-align: stretch;
  align-items: stretch;
  border-right: 1px none rgba(50, 52, 65, 0.1);
  font-size: 18px;
}

#leaderboard-game_modal .back-button {
  margin-top: 0px;
  padding: 16px 15px 16px 0px;
  border-right: 1px solid rgba(0, 0, 0, 0.2);
  color: #fff;
  font-size: 12px;
}

#leaderboard-game_modal .demonavbarlink {
  margin-right: 0px;
  padding: 16px 10px;
  border-right: 1px solid rgba(0, 0, 0, 0.22);
  border-bottom: 5px solid transparent;
  -webkit-transition: border-color 200ms ease;
  transition: border-color 200ms ease;
  color: #fff;
  font-size: 12px;
  text-transform: capitalize;
  white-space: nowrap;
}

#leaderboard-game_modal .demonavbarlink:hover {
  border-bottom-color: #ff5722;
}

#leaderboard-game_modal .demonavbarlink.w--current {
  border-bottom-color: #ff5722;
  color: #fff;
  font-weight: 700;
}

#leaderboard-game_modal .demoicon {
  height: 18px;
  margin-right: 10px;
  margin-left: 41px;
  line-height: 100%;
}

#leaderboard-game_modal .demoicon.nav {
  position: static;
  margin: 0px 5px 0px 9px;
  padding-top: 4px;
  background-color: transparent;
  box-shadow: none;
}

#leaderboard-game_modal .navbar-dropdown-copy {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  margin-top: 0px;
  margin-bottom: 1px;
  padding: 16px 5px 8px 14px;
  border-right: 1px solid rgba(0, 0, 0, 0.21);
  color: #fff;
  font-size: 12px;
  text-align: right;
}

#leaderboard-game_modal .navbar-dropdown-copy:hover {
  border-bottom: 5px solid #ff5722;
}

#leaderboard-game_modal .ranking-badge {
  position: absolute;
  left: 0px;
  top: 0px;
  display: inline-block;
  padding: 4px;
  border: 1px solid #cbd6e2;
  border-radius: 0px 0px 3px;
  background-color: #dfe3eb;
  font-size: 12px;
  line-height: 1em;
  font-weight: 700;
}

#leaderboard-game_modal .profile-image {
  width: 36px;
  height: 36px;
  margin-right: 4px;
  border-radius: 40px;
}

#leaderboard-game_modal .close-icon {
  position: absolute;
  top: 25px;
  right: 48px;
  width: 20px;
  height: 20px;
  cursor: pointer;
  -o-object-fit: fill;
  object-fit: fill;
}

#leaderboard-game_modal .scorecard-container {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  overflow: scroll;
}

#leaderboard-game_modal .tile-body-wrap {
  overflow: visible;
}

#leaderboard-game_modal .tile-body-wrap.intraday {
  overflow: scroll;
}

#leaderboard-game_modal .scorecard-col-2 {
  width: 360px;
  margin-left: 24px;
  -webkit-box-flex: 0;
  -webkit-flex: 0 0 auto;
  -ms-flex: 0 0 auto;
  flex: 0 0 auto;
}

.text-block-230 {
  -webkit-align-self: center;
  -ms-flex-item-align: center;
  -ms-grid-row-align: center;
  align-self: center;
}

#leaderboard-game_modal .chart-body {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  height: 240px;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
}

#leaderboard-game_modal .scorecard-col-1 {
  -webkit-box-flex: 1;
  -webkit-flex: 1;
  -ms-flex: 1;
  flex: 1;
}

#leaderboard-game_modal .scorecard-modal {
  position: fixed;
  left: 0%;
  top: 0%;
  right: 0%;
  bottom: 0%;
  z-index: 10000;
  display: none;
  overflow: scroll;
  padding-top: 96px;
  padding-right: 48px;
  padding-left: 48px;
  background-color: rgba(39, 41, 51, 0.9);
  -o-object-fit: none;
  object-fit: none;
}

#leaderboard-game_modal .chart-wrap {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 640px;
  height: 240px;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-align-self: center;
  -ms-flex-item-align: center;
  align-self: center;
  -webkit-box-flex: 0;
  -webkit-flex: 0 0 auto;
  -ms-flex: 0 0 auto;
  flex: 0 0 auto;
}

#leaderboard-game_modal .center-align-wrap {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
}

#leaderboard-game_modal .center-align-wrap.autoselect-x {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
}

#leaderboard-game_modal .div-block-580 {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  margin-top: 50px;
  -webkit-box-pack: end;
  -webkit-justify-content: flex-end;
  -ms-flex-pack: end;
  justify-content: flex-end;
}

#leaderboard-game_modal .div-block-580.header {
  margin-top: 0px;
  margin-bottom: 25px;
  padding-top: 0px;
  padding-bottom: 0px;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
}

#leaderboard-game_modal .button-robert {
  padding: 10px 24px;
  border-radius: 3px;
  background-color: #22c6ab;
  font-size: 14px;
}

#leaderboard-game_modal .mr-2 {
  margin-right: 10px;
}

#leaderboard-game_modal .div-block-564 {
  margin-bottom: 50px;
}

#leaderboard-game_modal .div-block-562 {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-flex-wrap: nowrap;
  -ms-flex-wrap: nowrap;
  flex-wrap: nowrap;
  border: 1px none #000;
}

#leaderboard-game_modal .ml-12 {
  margin-left: 48px;
}

#leaderboard-game_modal .form-block-12 {
  position: relative;
}

#leaderboard-game_modal .select-input-robert {
  width: 100%;
  height: 42px;
  padding: 8px 12px;
  border: 1px solid #dfe3eb;
  border-radius: 4px;
  background-color: #fff;
  font-size: 14px;
}

#leaderboard-game_modal .select-input-robert.disabled-input {
  background-color: #e7ebee;
}

.autoselect-x {
  display: none;
}

.autoselect-x.one-x {
  display: none;
}

.image-155 {
  height: 200px;
}

#leaderboard-game_modal .div-block-586-copy {
  position: absolute;
  left: 0px;
  top: 0px;
  right: 0px;
  bottom: 0px;
  z-index: 980;
  display: none;
  overflow: scroll;
  padding: 48px;
  background-color: #f0f0f0;
}

.h-48 {
  height: 48px;
}

.mb-11 {
  margin-bottom: 44px;
}

.autoselect-x-2 {
  display: none;
}

.autoselect-x-2.five-x {
  display: none;
}

.autoselect-x-2.six-x {
  display: none;
}

#leaderboard-game_modal .text-link-new-2 {
  color: #0e7e97;
  font-weight: 700;
}

#leaderboard-game_modal .text-link-new-2:hover {
  text-decoration: underline;
}

#leaderboard-game_modal .text-link-new-2.dark-mode {
  color: #fff;
}

.mb-8 {
  margin-bottom: 32px;
}

.mb-8.game-detail-half {
  width: 45%;
  margin-right: 24px;
}

#leaderboard-game_modal .div-block-575 {
  position: absolute;
  top: 0px;
  right: 0px;
  bottom: 0px;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  overflow: hidden;
  width: 50%;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  border-right: 1px none hsla(0, 0%, 100%, 0.26);
  border-left: 1px solid hsla(0, 0%, 100%, 0.26);
  background-color: rgba(40, 50, 62, 0.1);
  background-image: url('../../images/gamifyy/morphing-diamonds-light.svg');
  background-position: 0px 0px;
  background-size: 125px;
}

.hid {
  display: none;
}

#leaderboard-game_modal .body {
  background-color: #f5f8fa;
}

.form-block-7 {
  margin-top: 50px;
}

#leaderboard-game_modal .ml-2 {
  margin-left: 10px;
}

#leaderboard-game_modal .radio-button-label-2 {
  color: #272727;
  font-size: 14px;
  line-height: 1.5em;
  text-transform: capitalize;
}

#leaderboard-game_modal .new_ori {
  display: none;
  -webkit-transform: translate3d(100%, 0, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0);
  -moz-transform: translate3d(100%, 0, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0);
  -ms-transform: translate3d(100%, 0, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0);
  transform: translate3d(100%, 0, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0);
  transition: 0.25s;
  transform-style: preserve-3d;
}

#leaderboard-game_modal .pd-0 {
  padding: 0px;
}

#leaderboard-game_modal .cus_ori {
  display: block !important;
  -webkit-transform: translate3d(0%, 0px, 0px) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg) rotateZ(0deg) skew(0deg, 0deg);
  -moz-transform: translate3d(0%, 0px, 0px) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg) rotateZ(0deg) skew(0deg, 0deg);
  -ms-transform: translate3d(0%, 0px, 0px) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg) rotateZ(0deg) skew(0deg, 0deg);
  transform: translate3d(0%, 0px, 0px) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg) rotateZ(0deg) skew(0deg, 0deg);
  transition: all 0.25s;
  transform-style: preserve-3d;
}

#leaderboard-game_modal .radio-button {
  position: absolute;
  bottom: -30px;
  width: 25px;
  height: 25px;
  margin-left: 0px;
  -webkit-box-flex: 0;
  -webkit-flex: 0 0 auto;
  -ms-flex: 0 0 auto;
  flex: 0 0 auto;
  border-style: none;
  border-radius: 0%;
  background-color: #fff;
}

.mt-0 {
  margin-top: 0px;
}

.radio-button-field-7 {
  margin-bottom: 15px;
  color: #272933;
}

.mb-4 {
  margin-bottom: 20px;
}

.mb-4.collections-metric {
  display: none;
  margin-bottom: 0px;
}

.mb-4.generic-metric {
  display: block;
  margin-bottom: 0px;
}

#leaderboard-game_modal .dashboard {
  position: absolute;
  left: 0%;
  top: 110px;
  right: 0px;
  bottom: 0px;
  overflow: auto;
  height: auto;
  margin-right: 0px;
  padding: 36px 20px;
  color: #252525;
  font-size: 16px;
}

#leaderboard-game_modal .div-block-577 {
  position: absolute;
  left: 0%;
  top: 0px;
  right: 50%;
  bottom: 0px;
  overflow: scroll;
  padding: 24px 48px 48px;
}

#leaderboard-game_modal .game-row {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: stretch;
  -webkit-align-items: stretch;
  -ms-flex-align: stretch;
  align-items: stretch;
  border-top: 1px solid hsla(0, 0%, 100%, 0.25);
  background-color: rgba(0, 0, 0, 0.7);
  font-size: 12px;
}

.game-row.alt {
  background-color: transparent;
}

.game-row.alt.preview {
  border-top-color: hsla(0, 0%, 60.8%, 0.25);
}

.game-row.leader-collection {
  height: 60px;
  border-top-color: #000;
  background-color: rgba(0, 0, 0, 0.5);
}

.radio-button-field-3 {
  position: relative;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 45%;
  height: 250px;
  margin-right: 2.5%;
  margin-bottom: 75px;
  padding-left: 0px;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-pack: end;
  -webkit-justify-content: flex-end;
  -ms-flex-pack: end;
  justify-content: flex-end;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  border-style: solid;
  border-width: 1px;
  border-color: #e6e6e6;
  border-radius: 5px;
  background-image: url('../../images/gamifyy/Screen-Shot-2019-11-25-at-3.11.11-PM.png');
  background-position: 50% 50%;
  background-size: cover;
  -webkit-transition: border-color 250ms ease;
  transition: border-color 250ms ease;
  cursor: pointer;
}

#leaderboard-game_modal .radio-button-field-3:hover {
  border-color: #0056ff;
  background-size: cover;
}

#leaderboard-game_modal .radio-button-field-3.horse-racing {
  background-image: url('../../images/gamifyy/Screen-Shot-2019-11-25-at-3.10.48-PM.png');
}

#leaderboard-game_modal .radio-button-field-3.baseball {
  width: 45%;
  background-image: url('../../images/gamifyy/Screen-Shot-2019-11-25-at-3.10.59-PM.png');
}

#leaderboard-game_modal .radio-button-field-3.car-racing {
  background-image: url('../../images/gamifyy/Screen-Shot-2019-11-25-at-3.11.19-PM.png');
}

.heading-57 {
  margin-top: 24px;
  margin-bottom: 24px;
}

.mb-5 {
  margin-bottom: 25px;
}

#leaderboard-game_modal .pop-up-game-pane {
  position: absolute;
  left: 0px;
  top: 0px;
  right: 0px;
  bottom: 0px;
  z-index: 998;
  display: block;
  overflow: scroll;
  padding: 48px;
  background-color: #f5f8fa;
}

#leaderboard-game_modal .pop-up-game-pane.challenge {
  display: none;
}

#leaderboard-game_modal .pop-up-game-pane.game {
  display: none;
}

.link-block-16 {
  width: 100%;
  margin-bottom: 12px;
  margin-left: 0px;
  padding: 12px 24px;
  border-style: solid;
  border-width: 1px;
  border-color: #e9eaeb;
  border-radius: 8px;
  background-color: #f3f1f1;
  color: #272933;
  font-size: 14px;
  cursor: pointer;
}

.link-block-16:hover {
  background-color: #f9db94;
  color: #28323e;
}

.link-block-16.new-game {
  background-color: #27323e;
  color: #fff;
}

.link-block-16.new-game:hover {
  background-color: #fc6;
  color: #272933;
}

.text-block-238 {
  padding: 12px;
  border-style: solid;
  border-width: 1px;
  border-color: #f1ce63;
  border-radius: 4px;
  background-color: #fff6dc;
  font-size: 14px;
}

.form-14 {
  display: block;
  -webkit-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  grid-auto-columns: 1fr;
  -ms-grid-columns: 1fr 1fr;
  grid-template-columns: 1fr 1fr;
  -ms-grid-rows: auto auto;
  grid-template-rows: auto auto;
}

.select-input-robert-2 {
  height: 42px;
  margin-bottom: 12px;
  padding-top: 8px;
  padding-bottom: 8px;
  border-style: solid;
  border-width: 1px;
  border-color: #dfe3eb;
  border-radius: 3px;
  background-color: #fff;
}

.pop-up-game-pan-alt {
  position: absolute;
  left: 0px;
  top: 0px;
  right: 0px;
  bottom: 0px;
  z-index: 998;
  display: block;
  overflow: scroll;
  padding: 48px;
  background-color: #f5f8fa;
}

.pop-up-game-pan-alt.game-details {
  position: absolute;
  left: 0%;
  top: 5px;
  z-index: 1000;
  display: none;
  grid-auto-columns: 1fr;
  -ms-grid-columns: 1fr 1fr;
  grid-template-columns: 1fr 1fr;
  -ms-grid-rows: auto auto;
  grid-template-rows: auto auto;
}

.pop-up-game-pan-alt.game-details-completed {
  display: none;
}

.pop-up-game-pan-alt.game-details-upcoming {
  display: none;
}

#leaderboard-game_modal .radio-button-label {
  position: relative;
  z-index: 1;
  display: none;
  width: 100%;
  margin-bottom: 0px;
  padding: 4px 8px;
  border-radius: 3px 0px 0px;
  background-color: #ebebeb;
  font-size: 16px;
  text-transform: capitalize;
}

#leaderboard-game_modal .field-label-11 {
  margin-bottom: 15px;
  color: #272933;
  font-size: 18px;
  text-transform: capitalize;
}

#leaderboard-game_modal .div-block-844 {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
  -ms-flex-pack: justify;
  justify-content: space-between;
}

#leaderboard-game_modal .div-block-844.one-button {
  -webkit-box-pack: end;
  -webkit-justify-content: flex-end;
  -ms-flex-pack: end;
  justify-content: flex-end;
}

#leaderboard-game_modal .game-leaderboard-wrap {
  border-bottom: 1px solid hsla(0, 0%, 100%, 0.25);
  border-left: 1px solid hsla(0, 0%, 100%, 0.25);
}

#leaderboard-game_modal .game-leaderboard-wrap.preview {
  border-bottom-color: hsla(0, 0%, 60.8%, 0.25);
  border-left-color: hsla(0, 0%, 60.8%, 0.25);
}

.select-future-date {
  margin-top: 8px;
  margin-bottom: 12px;
}

#leaderboard-game_modal .active-button-2 {
  margin-left: 8px;
  padding: 6px 16px;
  border-radius: 3px;
  background-color: #22c6ab;
  -webkit-transition: background-color 200ms ease;
  transition: background-color 200ms ease;
  color: #fff !important;
  font-size: 14px;
  font-weight: 700;
  cursor: pointer;
}

#challenge_cancel {
  color: #0053f1;
}

.block {
  display: block !important;
}

.hid {
  display: none !important;
}

#leaderboard-game_modal a.challenge_cancel {
  color: #0053f1 !important;
  cursor: pointer;
}

#leaderboard-game_modal a.ml-2 {
  color: #0053f1 !important;
}

.pd-20 {
  padding: 0px 20px;
}

#leaderboard-game_modal .active-button-2:hover {
  background-color: #1e6d60;
  color: #fff !important;
}

.mt-50 {
  margin-top: 50px;
}

#leaderboard-game_modal .active-button-2.danger-outline {
  border: 1px solid #ef5350;
  background-color: transparent;
  color: #ef5350 !important;
}

#leaderboard-game_modal .active-button-2.danger-outline.ml-0 {
  margin-left: 0px;
}

#leaderboard-game_modal .active-button-2.secondary-outline {
  border-style: solid;
  border-width: 1px;
  border-color: #dfe3eb;
  background-color: #fff;
  color: #272933 !important;
  font-weight: 700;
}

#leaderboard-game_modal .active-button-2.secondary-outline:hover {
  background-color: #d3d3d3;
}

#leaderboard-game_modal .active-button-2.secondary-outline.ml-0 {
  display: inline-block;
  margin-left: 0px;
}

#leaderboard-game_modal .active-button-2.secondary-outline.disabled {
  background-color: #dfe3eb;
}

#leaderboard-game_modal .game-cell {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  padding: 12px 18px;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  border-right: 1px solid hsla(0, 0%, 100%, 0.25);
}

#leaderboard-game_modal .game-cell.sm {
  width: 100px;
  padding-right: 8px;
  padding-left: 8px;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  background-color: hsla(0, 0%, 100%, 0);
  text-align: center;
}

#leaderboard-game_modal .game-cell.sm.preview {
  border-right-color: hsla(0, 0%, 60.7%, 0.25);
}

#leaderboard-game_modal .game-cell.stretch-copy {
  overflow: hidden;
  padding-top: 24px;
  padding-bottom: 24px;
  -webkit-box-flex: 1;
  -webkit-flex: 1;
  -ms-flex: 1;
  flex: 1;
}

#leaderboard-game_modal .game-cell.stretch-copy.preview {
  border-right-color: hsla(0, 0%, 60.8%, 0.25);
}

#leaderboard-game_modal .game-cell.score {
  width: 100px;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  border-right-color: #000;
  font-size: 15px;
  font-weight: 700;
}

#leaderboard-game_modal .game-cell.name {
  width: 175px;
  height: 60px;
  -webkit-box-flex: 0;
  -webkit-flex: 0 0 auto;
  -ms-flex: 0 0 auto;
  flex: 0 0 auto;
  border-right-color: #000;
  font-weight: 700;
}

#leaderboard-game_modal .game-cell.stretch {
  overflow: hidden;
  -webkit-box-flex: 1;
  -webkit-flex: 1;
  -ms-flex: 1;
  flex: 1;
  border-right-color: rgba(0, 0, 0, 0.25);
}

#leaderboard-game_modal .game-cell.rank {
  width: 64px;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  border-right-color: #000;
  font-size: 15px;
  font-weight: 700;
}

#leaderboard-game_modal .game-cell.stretch {
  overflow: hidden;
  border-right-color: rgba(0, 0, 0, 0.25);
}

#leaderboard-game_modal .div-block-563 {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 100%;
  height: 100%;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  background-image: -webkit-gradient(linear, left top, left bottom, from(rgba(0, 0, 0, 0.5)), to(rgba(0, 0, 0, 0.5)));
  background-image: linear-gradient(180deg, rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5));
  -webkit-transition: background-color 250ms ease;
  transition: background-color 250ms ease;
  color: #fff;
  font-size: 18px;
  line-height: 1.25em;
  text-align: center;
}

#leaderboard-game_modal .div-block-563:hover {
  background-image: -webkit-gradient(linear, left top, left bottom, from(rgba(0, 0, 0, 0.1)), to(rgba(0, 0, 0, 0.1)));
  background-image: linear-gradient(180deg, rgba(0, 0, 0, 0.1), rgba(0, 0, 0, 0.1));
  text-shadow: 1px 1px 14px #000;
}

.radio-button-field-4 {
  margin-bottom: 12px;
  color: #272933;
}

.html-embed {
  border-style: solid;
  border-width: 1px;
  border-color: #000 #000 #dfe3eb;
}

#leaderboard-game_modal .div-block-56 {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
}

#leaderboard-game_modal .dropdown-link {
  position: relative;
  width: 100%;
  padding: 14px 23px;
  float: none;
}

#leaderboard-game_modal .dropdown-link:hover {
  background-color: #323441;
  color: #fff;
}

#leaderboard-game_modal .dropdown-link.w--current {
  color: #ff5722;
  font-weight: 700;
}

#leaderboard-game_modal .div-block-649 {
  overflow: scroll;
  height: 440px;
  padding: 16px;
  color: #272933;
}

.text-block-170 {
  color: #686c86;
  font-size: 12px;
}

.mb-2 {
  margin-bottom: 10px;
}

#leaderboard-game_modal .div-block-648 {
  position: fixed;
  top: 60px;
  right: 30px;
  z-index: 10000;
  display: none;
  overflow: hidden;
  width: 300px;
  height: 500px;
  border-radius: 5px;
  background-color: #caccda;
  box-shadow: 1px 1px 6px 0 rgba(0, 0, 0, 0.55);
}

#leaderboard-game_modal .div-block-652 {
  position: absolute;
  right: -9px;
  bottom: -9px;
  display: none;
  width: 20px;
  height: 20px;
  border-style: solid;
  border-width: 1px;
  border-color: #272933;
  border-radius: 20px;
  background-color: #ff5722;
  color: #fff;
  font-size: 10px;
  font-weight: 700;
  text-align: center;
}

#leaderboard-game_modal .button {
  position: static;
  right: 0px;
  display: inline-block;
  margin-top: 0px;
  margin-bottom: 0px;
  margin-left: 0px;
  padding: 10px 15px;
  float: none;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-flex: 0;
  -webkit-flex: 0 auto;
  -ms-flex: 0 auto;
  flex: 0 auto;
  border-style: none none solid;
  border-width: 2px;
  border-color: #fff #fff #c3421a;
  border-radius: 5px;
  background-color: #ff5722;
  -webkit-transition: background-color 350ms ease, all 200ms ease;
  transition: background-color 350ms ease, all 200ms ease;
  color: #fff;
  font-size: 16px;
  font-weight: 700;
  text-transform: capitalize;
  text-shadow: none;
  cursor: pointer;
}

#leaderboard-game_modal .button:hover {
  border-color: #ff3d00 #ff3d00 #ff5722;
  border-top-style: none;
  border-top-width: 1px;
  border-right-style: none;
  border-right-width: 1px;
  border-left-style: none;
  border-left-width: 1px;
  background-color: #ff8a65;
}

#leaderboard-game_modal .button.w--current {
  background-color: #ff5722;
}

#leaderboard-game_modal .button.small {
  padding: 8px 16px;
  border-radius: 3px;
  font-size: 12px;
}

#leaderboard-game_modal .button.small.outline {
  border: 1px solid #272933;
  background-color: #fff;
  color: #272933;
}

#leaderboard-game_modal .button.small.outline.fill {
  background-color: #272933;
  color: #fff;
}

.mt-4-robert {
  margin-top: 16px;
}

#leaderboard-game_modal .notificaiton-header {
  padding: 8px 16px;
  background-color: #686c86;
  color: #fff;
}

#leaderboard-game_modal .div-block-650 {
  margin-top: 9px;
  padding: 16px;
  border-radius: 3px;
  background-color: #fff;
}

.collection-list-10 {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-justify-content: space-around;
  -ms-flex-pack: distribute;
  justify-content: space-around;
  -webkit-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
}

.collection-item-8 {
  position: relative;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 360px;
  height: 240px;
  margin-bottom: 24px;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  border-radius: 4px;
  background-color: #f1f1f1;
}

.collection-item-8:hover {
  background-color: #22c6ab;
  color: #fff;
}

.modal-robert {
  position: fixed;
  left: 0%;
  top: 0%;
  right: 0%;
  bottom: 0%;
  z-index: 10000;
  display: none;
  padding-top: 100px;
  background-color: rgba(39, 41, 51, 0.76);
  -o-object-fit: none;
  object-fit: none;
}

.modal-robert.modal-a {
  display: none;
}

.modal-robert.modal-b {
  display: none;
}

#leaderboard-game_modal .best-practice-body-2 {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  overflow: scroll;
  padding: 20px;
}

#leaderboard-game_modal .btn-primary {
  background-color: #21c7a9 !important;
  border-color: #21c7a9 !important;
}

.btn {
  cursor: pointer;
}

#leaderboard-game_modal .div-block-839 {
  overflow-y: auto;
  height: 80vh;
  margin-right: auto;
  margin-left: auto;
}

div#upcoming_table_paginate {
  display: none;
}

div#history_paginate {
  display: none;
}

#leaderboard-game_modal body {
  font-size: 14px !important;
}

#theme_modal>.modal-dialog {
  max-width: 95% !important;
}

.md_his>.modal-content {
  border-radius: 5px !important;
}

#leaderboard-game_modal .video-preview-card {
  position: relative;
  width: auto;
  height: auto;
  margin-right: 12px;
  margin-bottom: 24px;
  border-radius: 4px;
  background-color: #000;
  text-align: center;
}

#leaderboard-game_modal .div-block-840 {
  position: absolute;
  left: 0%;
  top: 0%;
  right: 0%;
  bottom: 0%;
  display: none;
  border-radius: 4px;
  background-image: -webkit-gradient(linear, left top, left bottom, from(rgba(34, 198, 171, 0.25)), to(rgba(34, 198, 171, 0.25)));
  background-image: linear-gradient(180deg, rgba(34, 198, 171, 0.25), rgba(34, 198, 171, 0.25));
  cursor: pointer;
}

#leaderboard-game_modal .div-block-845 {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
}

.collection-list-11 {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
}

.collection-item-7 {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 100px;
  height: 100px;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  cursor: pointer;
}

.collection-item-7:hover {
  background-color: #eee;
}

.component-body {
  display: block;
  overflow: scroll;
  padding: 20px;
}

.image-152 {
  height: 60px;
}

#leaderboard-game_modal .div-block-846 {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 100px;
  height: 100px;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
}

#leaderboard-game_modal .div-block-846:hover {
  background-color: #e9e9e9;
}

#leaderboard-game_modal .div-block-847 {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
}

#leaderboard-game_modal .div-block-848 {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
}

#leaderboard-game_modal .div-block-570 {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  overflow: hidden;
  width: 124px;
  height: 18px;
  margin-right: 8px;
  margin-left: 8px;
  -webkit-box-align: stretch;
  -webkit-align-items: stretch;
  -ms-flex-align: stretch;
  align-items: stretch;
  -webkit-box-flex: 1;
  -webkit-flex: 1;
  -ms-flex: 1;
  flex: 1;
  border-radius: 25px;
}

#leaderboard-game_modal .div-block-842 {
  margin-right: 48px;
}

#leaderboard-game_modal .div-block-571 {
  width: 5%;
  background-color: #373d4b;
}

#leaderboard-game_modal .div-block-571._5p {
  width: 10%;
  background-color: #fff;
}

.pd-0 {
  padding: 0;
}

#leaderboard-game_modal .game_chat {
  margin: 0;
  overflow: auto;
}

.gray_bg {
  background-color: #202020;
}

#leaderboard-game_modal .sender_alphabet {
  background-color: #ff8a65;
  width: 32px;
  height: 32px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 30px;
  color: #fff;
  position: absolute;
  z-index: 9;
  font-size: 12px;
  top: 10px;
  left: 0px;
}

.chat_type textarea {
  min-height: 40px;
  height: 50px !important;
  max-height: 100px;
  outline: 0px !important;
}

.chat_type textarea.form-control:focus {
  outline: none !important;
}

.smiley_box .form-control {
  outline: 0 !important;
}

.gray_bg .col-md-12 {
  padding: 0;
}

.sender_message {
  background-color: #2c2c2c;
  color: rgba(255, 255, 255, .8);
  padding: 25px 30px;
  border-radius: 5px;
  font-size: 13px;
  position: relative;
  left: 15px;
  padding: 20px 30px;
}

.chat_type {
  position: absolute;
  left: 35px;
  right: 30px;
  bottom: 15px;
  z-index: 9;
}

.chat_type>.text-right {
  text-align: right;
  padding: 5px 0px 0px 0px;
}

.chat_type img {
  width: 20px;
  cursor: pointer;
}

.chat_type .form-control {
  font-size: 12px;
  background: #2c2c2c;
  border: 1px solid #000;
  color: #e9ecef;
  padding: 15px;
  height: 50px;
}

#leaderboard-game_modal .chat_type input::-webkit-input-placeholder {
  color: #fff !important;
}

.chat_type input:-moz-placeholder {
  /* Firefox 18- */
  color: #fff !important;
}

.chat_type input::-moz-placeholder {
  /* Firefox 19+ */
  color: #fff !important;
}

.chat_type input:-ms-input-placeholder {
  color: #fff !important;
}

.smiley_box {
  background: #829297;
  padding: 15px 20px 20px 20px;
  border-radius: 3px;
  margin-bottom: 10px;
  border: 1px solid #9fa9b3;
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  align-items: center;
}

.smiley_box:after {
  content: '';
  position: absolute;
  right: 16px;
  bottom: 50px;
  width: 0;
  height: 0;
  border-left: 8px solid transparent;
  border-right: 8px solid transparent;
  border-top: 10px solid #829297;
  clear: both;
}

.smiley_box div {
  padding: 5px 5px 0px 0px;
}

.game_hd {
  color: #fff;
  margin: 0;
  padding: 20px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.game_hd img {
  width: 12px;
  cursor: pointer;
}

.slide-right {
  animation: 3s fadeInUp;
}

@keyframes fadeInUp {
  from {
    opacity: 0;
    -webkit-transform: translate3d(0, 100%, 0);
    transform: translate3d(0, 100%, 0);
  }

  to {
    opacity: 1;
    -webkit-transform: none;
    transform: none;
  }
}

.chat_msg {
  display: flex;
  justify-content: flex-start;
  align-items: start;
  position: relative;
  margin: 5px 30px 5px 20px;
}

.game_hd h3 {
  font-size: 16px;
  font-weight: 700;
  margin: 0;
}

div#lastupdateddatetime {
  padding: 5px 5px 5px 15px;
}

.div-block-568--new {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  overflow: hidden;
  /* max-height: 120px;*/
  max-width: 1300px;
  margin-right: auto;
  margin-bottom: 35px;
  margin-left: auto;
  padding: 24px;
  -webkit-box-align: start;
  -webkit-align-items: flex-start;
  -ms-flex-align: start;
  align-items: flex-start;
  border-bottom: 1px solid #000;
  background-color: rgba(0, 0, 0, 0.5);
  font-size: 12px;
  flex-wrap: wrap;
  justify-content: flex-start;
  position: relative;
}

.flx {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-wrap: wrap;
  width: calc(100% - 40px);
}

.menu_icon {
  cursor: pointer;
  width: 50px;
  text-align: center;
  padding-top: 25px;
  padding-right: 15px;
}

.menu_icon img {
  width: 35px;
}

#leaderboard-game_modal .game-section {
  position: absolute;
  left: 0%;
  top: 0%;
  right: 0%;
  bottom: 0%;
  z-index: 1;
  overflow: auto;
  height: 100vh;
  color: #fff;
}

.collection-list-wrapper-6 {
  -webkit-box-flex: 1;
  -webkit-flex: 1;
  -ms-flex: 1;
  flex: 1;
}

#leaderboard-game_modal .div-block-682 {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  border-style: none none solid solid;
  border-width: 1px;
  border-color: #000;
}

.bold-text-10 {
  padding: 4px 8px;
  border-radius: 4px;
  background-image: -webkit-gradient(linear, left top, left bottom, from(hsla(0, 0%, 100%, 0.9)), to(hsla(0, 0%, 100%, 0.9)));
  background-image: linear-gradient(180deg, hsla(0, 0%, 100%, 0.9), hsla(0, 0%, 100%, 0.9));
  color: #000;
  font-size: 14px;
  font-weight: 400;
}

#leaderboard-game_modal .div-block-837 {
  overflow: auto;
  max-height: 75vh;
  -webkit-box-flex: 1;
  -webkit-flex: 1;
  -ms-flex: 1;
  flex: 1;
}

#leaderboard-game_modal .div-block-841 {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  padding-right: 24px;
  padding-left: 24px;
}

#leaderboard-game_modal .game-tile-header {
  display: block;
  margin-right: 8px;
  margin-bottom: 8px;
  padding: 12px 16px;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  border: 1px solid #000;
  border-radius: 4px;
  background-color: hsla(0, 0%, 100%, 0.2);
  font-weight: 700;
}

#leaderboard-game_modal .game-tile-header.range {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-flex: 1;
  -webkit-flex: 1;
  -ms-flex: 1;
  flex: 1;
  background-color: hsla(0, 0%, 100%, 0.15);
}

#leaderboard-game_modal .game-tile-header.badge-space {
  -webkit-box-flex: 0;
  -webkit-flex: 0 0 auto;
  -ms-flex: 0 0 auto;
  flex: 0 0 auto;
}

#leaderboard-game_modal .select-game-link {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  margin-bottom: 12px;
  padding: 12px 18px;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  border: 1px solid #000;
  border-radius: 4px;
  background-color: rgba(0, 0, 0, 0.5);
  color: #fff;
  font-weight: 700;
}

#leaderboard-game_modal .select-game-link.w--current {
  border-color: #22c6ab;
  background-color: rgba(34, 198, 171, 0.8);
}

.text-block-239 {
  position: absolute;
  left: 24px;
  bottom: 24px;
}

.image-153 {
  height: 46px;
}

#leaderboard-game_modal .div-block-572 {
  -webkit-box-flex: 1;
  -webkit-flex: 1;
  -ms-flex: 1;
  flex: 1;
  background-color: hsla(0, 0%, 94.7%, 0.25);
}

#leaderboard-game_modal .video-background-section {
  position: relative;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  /*  overflow: hidden;*/
  width: 100%;
  height: 100vh;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  background-image: -webkit-gradient(linear, left top, left bottom, from(rgba(0, 0, 0, 0.5)), to(rgba(0, 0, 0, 0.5)));
  background-image: linear-gradient(180deg, rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5));
}

#leaderboard-game_modal .video-wrap {
  position: absolute;
  z-index: -999;
  width: 100%;
  height: 100%;
  /*  padding-bottom: 100%;*/
  background-color: #000;
}

#leaderboard-game_modal .video-embed {
  position: absolute;
  left: 0%;
  top: 0%;
  right: 0%;
  bottom: 0%;
  z-index: 0;
  width: 100%;
  height: 100%;
  opacity: 0.75;
}

.mgrprofile {
  overflow: hidden;
  width: 35px;
  height: 35px;
  border-radius: 50px;
  background-image: url('../../images/gamifyy/Sergio-Lopez-_1Sergio Lopez .png');
  background-position: 0px 0px;
  background-size: 35px;
}

.mgrprofile.mr-2 {
  -webkit-box-flex: 0;
  -webkit-flex: 0 0 auto;
  -ms-flex: 0 0 auto;
  flex: 0 0 auto;
}

.game-status-bar {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 124px;
  height: 18px;
  margin-right: 4px;
  margin-left: 4px;
  -webkit-box-align: stretch;
  -webkit-align-items: stretch;
  -ms-flex-align: stretch;
  align-items: stretch;
  -webkit-box-flex: 1;
  -webkit-flex: 1;
  -ms-flex: 1;
  flex: 1;
  border-radius: 25px;
}

.game-status-bar.leaderboard-collection {
  position: relative;
  overflow: hidden;
}

.game-status-bar-bg {
  position: relative;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  height: 18px;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-flex: 1;
  -webkit-flex: 1;
  -ms-flex: 1;
  flex: 1;
  border-top-right-radius: 25px;
  border-bottom-right-radius: 25px;
  background-color: hsla(0, 0%, 100%, 0.25);
}

.game-status-bar-bg.leader-collection {
  background-color: transparent;
}

#leaderboard-game_modal .div-block-849 {
  -webkit-box-flex: 1;
  -webkit-flex: 1;
  -ms-flex: 1;
  flex: 1;
}

.game-status-bar-active {
  position: relative;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 5%;
  height: 18px;
  -webkit-box-pack: end;
  -webkit-justify-content: flex-end;
  -ms-flex-pack: end;
  justify-content: flex-end;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  border-top-left-radius: 25px;
  border-bottom-left-radius: 25px;
  background-color: #00ff95;
}

.game-status-bar-active._95p {
  width: 95%;
  background-color: #22c6ab;
}

.game-status-bar-active._100p {
  width: 100%;
  background-color: #22c6ab;
}

.image-97 {
  position: relative;
  left: -16px;
  width: 32px;
  height: 32px;
  -webkit-box-flex: 0;
  -webkit-flex: 0 0 auto;
  -ms-flex: 0 0 auto;
  flex: 0 0 auto;
  border-radius: 100px;
  background-color: rgba(0, 0, 0, 0.5);
  box-shadow: 1px 1px 3px 0 #000;
}

#leaderboard-game_modal .div-block-850 {
  position: absolute;
  left: -30px;
  width: 32px;
  height: 32px;
  padding: 6px;
  border-radius: 100px;
  background-color: #fff;
  box-shadow: 1px 1px 3px 0 #000;
}

.heading-58 {
  margin-top: 96px;
  margin-bottom: 48px;
  color: #fff;
}

.image-154 {
  height: 24px;
  margin-right: 4px;
}

.bold-text-11 {
  margin-left: 8px;
  font-size: 16px;
  font-weight: 700;
}

.container-13 {
  overflow: auto;
}

.section-17 {
  position: fixed;
  left: 0%;
  top: 0%;
  right: 0%;
  bottom: 0%;
  z-index: 1;
}

#leaderboard-game_modal .div-block-851 {
  margin-right: 12px;
}

#leaderboard-game_modal .pill-label {
  padding: 4px 12px;
  border-radius: 100px;
  background-color: #e4e7e9;
  font-size: 12px;
  font-weight: 700;
}

#leaderboard-game_modal .div-block-852 {
  width: 35px;
  height: 35px;
  margin-right: 12px;
}

.mr-1 {
  margin-right: 2px;
}

#leaderboard-game_modal .div-block-853 {
  display: block;
  width: 100%;
}

#leaderboard-game_modal .div-block-854 {
  margin-bottom: 48px;
}

.html-embed-2 {
  margin-bottom: 8px;
}

@media screen and (min-width: 1280px) {
  .demologowrap.accountbased {
    display: none;
  }

  .demologowrap.generic {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
  }

  #leaderboard-game_modal .body {
    font-family: Lato, sans-serif;
  }

  .performnace-goal-break-slash {
    display: block;
  }

  .active-button.inactive-button:hover {
    background-color: #a8b6c5;
  }

  .commit-value-dot.current-spot.above-commitment {
    background-color: #6ccc76;
  }

  .commit-value-dot.current-spot.above-commitment:hover {
    box-shadow: 0 0 0 10px rgba(108, 204, 118, 0.24);
  }

  .commit-value-dot.end-coach {
    border-color: #cdced2;
    background-color: #cdced2;
  }

  .commit-value-dot.end-coach:hover {
    box-shadow: 0 0 0 10px rgba(205, 206, 210, 0.45);
  }

  .commit-value-dot.start-dot {
    width: 10px;
    height: 10px;
    border-color: #373d4b;
    background-color: #fff;
  }

  .scorecard-comaprison-title.user {
    color: #373d4b;
  }

  .component-tile-wrap.quadrant-1 {
    width: 50%;
    margin-bottom: 0px;
  }

  .component-tile-wrap.quadrant-2 {
    width: 20%;
    margin-left: 24px;
  }

  .metric-number-tile.no-right-margin {
    margin-right: 0px;
  }

  .scorecard-comparison-row.hide {
    display: none;
  }

  #leaderboard-game_modal .div-block-746.team-and-program {
    background-color: #ef5350;
  }

  #leaderboard-game_modal .div-block-746.team-and-program.green {
    background-color: #6ccc76;
  }

  #leaderboard-game_modal .div-block-746.no-goal-line {
    background-color: #5c6bc0;
  }

  .metric-details {
    display: none;
  }

  #leaderboard-game_modal .div-block-800 {
    width: 60%;
    -webkit-box-flex: 1;
    -webkit-flex: 1;
    -ms-flex: 1;
    flex: 1;
  }

  .no-metric-data-available {
    -webkit-box-pack: center;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    justify-content: center;
    background-image: -webkit-gradient(linear, left top, left bottom, from(#fff), to(#fff));
    background-image: linear-gradient(180deg, #fff, #fff);
  }

  #leaderboard-game_modal .commit-date {
    font-weight: 700;
  }

  .dashboard-row-wrap {
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -webkit-flex-direction: row;
    -ms-flex-direction: row;
    flex-direction: row;
  }

  #leaderboard-game_modal .div-block-806 {
    top: 16px;
    color: #979797;
    font-size: 12px;
    font-weight: 400;
  }

  #leaderboard-game_modal .div-block-806.date-wrap {
    top: -22px;
    font-weight: 700;
  }

  .text-block-222 {
    position: absolute;
    left: 16px;
    top: -12px;
    padding-right: 4px;
    padding-left: 4px;
    border-style: none;
    border-width: 1px;
    border-color: #dfe3eb;
    border-radius: 20px;
    background-color: #f5f8fa;
    color: #929292;
    font-size: 13px;
    font-weight: 400;
  }

  .performance-bar-positive {
    background-color: #6ccc76;
  }

  .user-dot.green {
    background-color: #6ccc76;
  }

  .user-dot.balance-scorecard-dot.green-value-example {
    background-color: #6ccc76;
    color: #fff;
  }

  .user-dot.balance-scorecard-dot.high-persona {
    background-color: #00b9ff;
  }

  .user-dot.balance-scorecard-dot.red-value-example {
    background-color: #ef5350;
    color: #fff;
  }

  .user-dot.no-goal-dot {
    background-color: #e0e2ee;
    color: #43519e;
  }

  .user-dot.agent-dot-no-goal {
    background-color: #5c6bc0;
  }

  .user-dot.team-and-program {
    background-color: #f0d6d6;
  }

  .user-dot.team-and-program.green {
    background-color: #d8f1e3;
  }

  .scorecard-comparison-tile {
    border-style: solid;
    border-width: 1px;
    border-color: #dfe3eb;
  }

  #leaderboard-game_modal .div-block-796 {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-flex: 0;
    -webkit-flex: 0 0 auto;
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
  }

  #leaderboard-game_modal .div-block-739-copy.tests {
    width: 185px;
    margin-left: 48px;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-ordinal-group: 2;
    -webkit-order: 1;
    -ms-flex-order: 1;
    order: 1;
  }

  #leaderboard-game_modal .div-block-802 {
    width: 240px;
    -webkit-box-flex: 0;
    -webkit-flex: 0 0 auto;
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
  }

  .new-cell.coach-rank-cell {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    border-bottom-color: #dfe3eb;
  }

  .new-cell.coach-rank-cell.rank-cell {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
  }

  .new-cell.header-robert.coach-rank-cell {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
  }

  .new-cell.header-robert.metric-cell {
    width: 160px;
  }

  .new-cell.header-robert.coaching-history-cell {
    font-size: 12px;
  }

  .new-cell.header-robert.coaching-history-cell.start-cell {
    -webkit-box-pack: start;
    -webkit-justify-content: flex-start;
    -ms-flex-pack: start;
    justify-content: flex-start;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
  }

  .new-cell.metric-cell {
    width: 160px;
  }

  .new-cell.intraday-cell {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    width: 72px;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-align-self: stretch;
    -ms-flex-item-align: stretch;
    align-self: stretch;
    border-bottom: 1px solid #dfe3eb;
    background-color: transparent;
  }

  .new-cell.coaching-history-cell {
    font-size: 13px;
  }

  .new-cell.coaching-history-cell.start-cell {
    -webkit-box-pack: start;
    -webkit-justify-content: flex-start;
    -ms-flex-pack: start;
    justify-content: flex-start;
  }

  #leaderboard-game_modal .commit-tooltip {
    z-index: 1;
    display: none;
    color: #272933;
    font-weight: 400;
  }

  #leaderboard-game_modal .div-block-803._w-140 {
    width: auto;
  }

  .tile-body-wrap.intraday {
    height: auto;
    min-height: 380px;
  }

  .chart-wrap {
    width: 800px;
  }

  .game-row.leader-collection {
    border-top-color: #000;
    background-color: rgba(0, 0, 0, 0.5);
  }

  .pop-up-game-pan-alt.game-details {
    display: none;
  }

  .pop-up-game-pan-alt.game-details-completed {
    display: none;
  }

  #leaderboard-game_modal .div-block-844.one-button {
    -webkit-box-pack: end;
    -webkit-justify-content: flex-end;
    -ms-flex-pack: end;
    justify-content: flex-end;
  }

  .collection-list-10 {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
  }

  .modal-robert {
    display: none;
  }

  .best-practice-body-2 {
    overflow: scroll;
  }

  .component-body {
    overflow: scroll;
  }

  #leaderboard-game_modal .div-block-842 {
    margin-right: 24px;
  }

  #leaderboard-game_modal .div-block-568--new {
    -webkit-box-pack: center;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-flex-wrap: nowrap;
    -ms-flex-wrap: nowrap;
    flex-wrap: nowrap;
    -webkit-box-align: start;
    -webkit-align-items: flex-start;
    -ms-flex-align: start;
    align-items: flex-start;
    border-right: 1px solid #000;
    border-left: 1px solid #000;
    border-bottom-left-radius: 4px;
    border-bottom-right-radius: 4px;
    flex-wrap: nowrap;
    justify-content: flex-start;
    overflow: auto;
  }

  #leaderboard-game_modal .div-block-568--new .bold-text-10 {}

  .container-12 {
    max-width: 1300px;
  }

  .game-section {
    left: 0px;
    padding-top: 0px;
  }

  #leaderboard-game_modal .div-block-682 {
    border-color: #000;
    border-top-style: none;
    border-right-style: none;
  }

  .bold-text-10 {
    display: inline-block;
    margin-bottom: 2px;
    padding: 2px 8px;
    border-radius: 4px;
    background-color: hsla(0, 0%, 100%, 0.9);
    color: #272933;
    font-weight: 700;
  }

  #leaderboard-game_modal .div-block-837 {
    -webkit-box-flex: 1;
    -webkit-flex: 1;
    -ms-flex: 1;
    flex: 1;
  }

  #leaderboard-game_modal .div-block-841 {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
  }

  .game-tile-header.range {
    -webkit-box-flex: 1;
    -webkit-flex: 1;
    -ms-flex: 1;
    flex: 1;
  }

  .game-tile-header.badge-space {
    -webkit-box-flex: 0;
    -webkit-flex: 0 0 auto;
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
  }

  .image-153 {
    height: 42px;
  }

  .mr-1 {
    margin-right: 4px;
  }

  #leaderboard-game_modal .div-block-853 {
    display: block;
    -webkit-box-flex: 1;
    -webkit-flex: 1;
    -ms-flex: 1;
    flex: 1;
  }

  #leaderboard-game_modal .div-block-854 {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-flex: 1;
    -webkit-flex: 1;
    -ms-flex: 1;
    flex: 1;
  }

  .html-embed-2 {
    margin-bottom: 8px;
  }
}

@media screen and (max-width: 991px) {
  .component-tile-wrap {
    overflow: scroll;
  }

  .component-tile-wrap.quadrant-1 {
    width: 100%;
    margin-bottom: 48px;
  }

  .component-tile-wrap.quadrant-2 {
    width: 100%;
    margin-bottom: 0px;
    margin-left: 0px;
  }

  .dashboard-row-wrap {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
  }

  .demonavbar2 {
    left: 0%;
    width: 100%;
  }

  .demoicon.nav {
    height: 20px;
  }

  .navbar-dropdown-copy {
    margin-top: 0px;
    margin-bottom: 0px;
    border-bottom: 1px solid hsla(0, 0%, 100%, 0.1);
    font-size: 14px;
    text-align: center;
  }

  .navbar-dropdown-copy:active {
    background-color: #3b3b3b;
  }

  .dashboard {
    left: 0%;
    right: 0px;
  }

  #leaderboard-game_modal .button {
    position: static;
    left: 0px;
    right: 0px;
    bottom: 0px;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    width: auto;
    margin-top: 20px;
    margin-bottom: 20px;
    float: none;
    border-color: #ff5722 #ff5722 #b83e17;
    background-color: #ff5722;
  }

  .video-embed {
    z-index: 1;
  }
}

@media screen and (max-width: 767px) {
  .demo-nav {
    left: 0%;
    width: 100%;
  }

  .demonavbar2 {
    left: 0%;
    width: 100%;
    padding-right: 0px;
  }

  .bold-text-10 {
    padding-bottom: 4px;
  }
}

@media screen and (max-width: 479px) {
  .new-cell.coach-rank-cell {
    -webkit-align-self: flex-start;
    -ms-flex-item-align: start;
    align-self: flex-start;
  }

  .new-cell.performance-bar-cell {
    -webkit-align-self: flex-start;
    -ms-flex-item-align: start;
    align-self: flex-start;
    -webkit-box-flex: 0;
    -webkit-flex: 0 0 auto;
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
  }

  .table-row-robert {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-align: start;
    -webkit-align-items: flex-start;
    -ms-flex-align: start;
    align-items: flex-start;
  }

  .dashboard-table-row {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-align: start;
    -webkit-align-items: flex-start;
    -ms-flex-align: start;
    align-items: flex-start;
  }
}


#cancelConfirmation>.modal-dialog {
  max-width: 35% !important;
}

#cancelConfirmation .modal-body {
  padding: 15px !important;
  background-color: #a9bfd6;
  border-radius: 5px;
}

#cancelConfirmation .flx {
  justify-content: center;
}

.flx {
  display: flex;
}

.cancelconfrm {
  text-align: center;
}

.cancelconfrm>div {
  font-weight: 600;
  padding: 20px 0px;
}

button.btn.btn-white {
  margin-right: 13px;
  background-color: #fff;
  padding: 5px 15px;
  font-weight: 600;
}

.avtrr {
  max-width: 45px;
}

.hid {
  display: none;
}

.alert-danger {
  margin-top: 10px !important;
  text-transform: none;
  font-size: 12px;
  font-weight: bold;
  font-family: "Lato", sans-serif !important;
}

.pr-10 {
  padding-right: 10px !important;
}

.pt-5 {
  padding-top: 5px !important;
}

.inputDnD .form-control-file {
  position: relative;
  width: 100%;
  height: 100%;
  height: 200px;
  outline: none;
  visibility: hidden;
  cursor: pointer;
  color: #323441 !important;
  background-color: #c61c23;
  box-shadow: 0 0 5px solid #d8d8d8;
  margin-bottom: 0px !important;
}

.border-none {
  border: none !important;
}

.inputDnD .form-control-file:before {
  content: attr(data-title);
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  min-height: 200px;
  height: 200px;
  opacity: 1;
  visibility: visible;
  text-align: center;
  border-style: dashed;
  border-width: 1px;
  border-color: #d8d8d8;
  border-radius: 4px;
  background-color: #f3f3f3;
  font-size: 16px;
  cursor: pointer;
  transition: all 0.3s cubic-bezier(0.25, 0.8, 0.25, 1);
  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: center;
}

.inputDnD .form-control-file:hover:before {
  border-style: dashed;
  border-width: 2px;
  border-color: #d8d8d8;
}

.file_upld {
  padding: 45px;
}

#leaderboard-game_modal ::-webkit-scrollbar {
  width: 15px;
  margin: 3px;
  border-left: 1px solid rgba(50, 52, 65, 0.1);
  border-right: 1px solid rgba(50, 52, 65, 0.1);
}

#leaderboard-game_modal ::-webkit-scrollbar-track {
  width: 15px;
}

#leaderboard-game_modal ::-webkit-scrollbar-thumb {
  border-radius: 10px;
  background-color: rgb(61 63 64);
  border: 4px solid transparent;
  background-clip: content-box;
}

.grinning {
  content: '😵'
}

.chat_type .input-group-text {
  background-color: #2c2c2c;
  border: 1px solid #000000;
  border-left: 0px;
}

input,
textarea:focus {
  border-style: none !important;
  border-color: Transparent !important;
  outline-width: 0;
}

img.happy {
  width: 18px;
}

.row {
  align-content: flex-start;
}

.fade-in {
  animation: fadeIn ease 5s;
  -webkit-animation: fadeIn ease 5s;
  -moz-animation: fadeIn ease 5s;
  -o-animation: fadeIn ease 5s;
  -ms-animation: fadeIn ease 5s;
}

@keyframes fadeIn {
  0% {
    opacity: 0.7;
  }

  100% {
    opacity: 1;
  }
}

@-moz-keyframes fadeIn {
  0% {
    opacity: 0.7;
  }

  100% {
    opacity: 1;
  }
}

@-webkit-keyframes fadeIn {
  0% {
    opacity: 0.7;
  }

  100% {
    opacity: 1;
  }
}

@-o-keyframes fadeIn {
  0% {
    opacity: 0.7;
  }

  100% {
    opacity: 1;
  }
}

@-ms-keyframes fadeIn {
  0% {
    opacity: 0.7;
  }

  100% {
    opacity: 1;
  }
}