 #Green {
     background-color: forestgreen !important;
 }

 .my_schedule .justify-content-center {
     justify-content: center !important;
 }

 .incentive-tile.premiercard {
     text-align: center;
     /*display: flex;*/
     justify-content: center;
     align-items: center;
     font-size: 16px;
     color: #54589d;
     min-height: 130px;
     display: flex;
     flex-wrap: wrap;
     justify-content: center;
     align-items: center;
     flex-direction: column;
     /*text-decoration: underline;*/
 }

 .premiercard .samp {
     color: #000;
     font-weight: 600 !important;
     text-align: center;
     padding-top: 5px;
 }

 .premiercard a {
     text-decoration: underline;
 }

 .premiercard .div-block-588.mt-2.justify-content-center {
     justify-content: center !important;
 }

 .btn.gate_met {
     background-color: #6baf88;
     border-color: #6baf88;
     box-shadow: 0 2px 2px 2px rgb(0 0 0 / 15%);
     color: #fff !important;
 }

 .btn.not_met {
     background-color: #ef5350;
     border-color: #ef5350;
     box-shadow: 0 2px 2px 2px rgb(0 0 0 / 15%);
     color: #fff !important;
 }

 .btn.none_met {
     background-color: #868686;
     border-color: #868686;
     box-shadow: 0 2px 2px 2px rgb(0 0 0 / 15%);
     color: #fff !important;
 }

 .gate_met {
     color: #6baf88;
 }

 .not_met {
     color: #ef5350;
 }

 #Green .metric-number-tile-header {
     color: white !important;
 }

 #Green .metric-numbers {
     color: white !important;
 }

 #Green .samp {
     color: white !important;
 }

 .samp.metric-numbers.text-center {
     white-space: nowrap;
     font-size: 16px;
     margin-top: 14px;
     color: #323441;
 }

 .pbc .samp {
     font-weight: normal;
     font-size: 14px;
 }

 /*.pbc .metric-numbers {
     font-weight: 700;
     font-size: 20px;
 }
     .pbc.incentive-tile{
         min-height:130px;
     }
 .pbc > .metric-number-tile-header {
     margin-bottom: 12px;
     font-size: 15px;
 }*/
 .inc_red {
     color: #e34819;
 }

 .redd_tile {
     background-color: #ef5350 !important;
     color: #fff;
     height: 120px;
 }

 .green_tile {
     background-color: #6baf88 !important;
     color: #fff;
     height: 120px;
 }

 .redd_tile .inc_val {
     letter-spacing: 0px;
     margin-bottom: 10px;
 }

 .green_tile .inc_val {
     letter-spacing: 0px;
     margin-bottom: 10px;
 }

 .redd_tile img {
     width: 23px;
     margin-right: 5px;
 }

 .green_tile img {
     width: 21px;
     margin-right: 5px;
 }

 .redd_tile .payout_val {
     color: #f7a9a8;
 }

 .green_tile .payout_val {
     color: #b5d7c3;
 }

 .redd_tile .headd,
 .redd_tile .inc_val {
     padding-left: 12px;
 }

 .green_tile .headd,
 .green_tile .inc_val {
     padding-left: 12px;
 }

 .total_months {
     width: 70px;
     margin-right: 15px;
     font-size: 12px;
     line-height: 1.3em;
     font-weight: 600;
 }

 .total_months label {
     font-weight: 600;
 }

 .inc_right_block1 .justify-content-center {
     justify-content: center !important;
 }

 .pt-8 {
     padding-top: 8px !important;
 }

 .potential_table .table-responsive .table tr th {
     background-color: #ffffff;
     border-left: 1px solid #ffffff;
     border-top: 1px solid #ffffff;
 }

 .pbc_cuurent_month_score {
     display: flex;
     justify-content: center;
     align-items: center;
 }

 .incentive-tile.premiercard.pbc.pbc_cuurent_month_score a {
     text-decoration: underline;
     color: #0e7e97 !important;
 }

 .pbc_cuurent_month_score a:hover {
     text-decoration: underline !important;
 }

 .samp.metric-numbers.text-center {
     white-space: nowrap;
     font-size: 16px;
     margin-top: 14px;
     color: #323441;
 }

 .ncc a:hover {
     text-decoration: underline !important;
 }

 .metric-number-tile.pbc.ncc a {
     color: #9400D3 !important;
     font-size: 14px !important;
     margin-bottom: 5px !important;
 }

 .ncc .metric-number-tile-header {
     margin-top: 5px;
     color: #323411;
     font-size: 12px;
 }

 .metric-number-new-tile-header {
     font-size: 16px;
     font-weight: 700;
 }

 .metric-number-pbc {
     margin-bottom: 5px;
     font-size: 24px;
     line-height: 1em;
     font-weight: 600;
     margin-top: 30px;
 }

 .setBg {
     background-color: #F4F8FB !important;
     font-size: 16px !important;
     font-weight: 600 !important;
 }

 ;