.file-size-note {
    display: flex;
    justify-content: end;
    color: #bcbcbc;
    font-weight: normal;
    letter-spacing: 0.2px;
}

#tblTableData {
    overflow: auto;
}

.card {
    margin: 0 !important;
}

.btn-danger:disabled {
    color: #fff;
    background-color: #22c7a9;
    border-color: #22c7a9;
}

.selected_optns {
    font-weight: bold;
}

.disabled_pointer {
    opacity: 0.2;
    pointer-events: none;
}

.errorClass {
    border-color: red !important;
}

.overall b {
    font-weight: 600 !important;
}

.commit-tooltip {
    position: absolute;
    top: 16px;
    width: max-content;
    z-index: 10;
    display: none;
    padding: 4px 11px;
    border: 1px solid #dfe3eb;
    border-radius: 4px;
    background-color: #fff;
    box-shadow: 1px 1px 12px 0 rgb(0 0 0 / 19%);
    color: #272933;
    font-size: 12px;
    line-height: 1.25em;
    font-weight: 400;
}

.metric_detail_formula {
    width: 15px;
    height: 15px;
    border-radius: 15px;
    background: #e4e7eb;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    margin-left: 5px;
}

.instruction_text {
    margin: 10px 0px 10px 0px;
}

.metric_detail_formula .commit-tooltip {
    /* bottom: 0; */
    min-width: 100px;
    width: max-content;
    max-width: 500px;
    background-color: rgba(39, 41, 51, 0.95);
    color: #fff;
    padding: 12px 15px;
    white-space: normal;
    border: none;
    cursor: pointer;
    left: 15px;
    top: -25px;
}

.tool .commit-tooltip {
    width: max-content;
    max-width: 500px;
    background-color: rgba(39, 41, 51, 0.95);
    color: #fff;
    padding: 12px 15px;
    white-space: normal;
    border: none;
    cursor: pointer;
    left: 15px;
    top: -40px;
    z-index: 9;
}

.pb-5 {
    padding-bottom: 5px !important;
}

.add_file_tool .commit-tooltip {
    width: max-content;
    max-width: 500px;
    background-color: rgba(39, 41, 51, 0.95);
    color: #fff;
    padding: 12px 15px;
    white-space: normal;
    border: none;
    cursor: pointer;
    left: 0px;
    top: 30px;
    z-index: 9;
}

.metric_detail_formula .commit-tooltip div {
    padding-top: 5px;
    text-transform: none;
}

.metric_detail_formula img {
    width: 10px;
    height: 10px;
}

#agent_coachingdetailspopup.show {
    background: rgb(52 58 64 / 65%);
}

button.btn.btn-bordered.btn__back {
    background-color: #ccd6e2;
}

a.upload_file:not([href]):not([tabindex]) {
    color: #63d3c0;
    border-bottom: 1px solid #63d3c0;
    padding: 2px;
    text-decoration: none;
}

.mandatory_popup {
    background-color: #f9d1d8;
    border-radius: 3px;
    padding: 10px;
    font-weight: bold;
    font-size: 12px;
}

.upload input {
    opacity: 0;
}

.btn__next.hid {
    display: none;
}

.btn__createtask.hid {
    display: none;
}

.btn__createtasknew.hid {
    display: none;
}

#rightSideViewTaskManager {
    overflow-y: auto;
}

.line-control-menu-bar {
    background-image: linear-gradient(to bottom, #ffffff, #f9fafb) !important;
}

a.upload_file {
    white-space: nowrap;
    cursor: pointer;
}

.threshold_instructions {
    background: #fdf5d1;
    padding: 8px 15px;
    margin-bottom: 10px;
    font-size: 0.8rem;
    font-weight: 500;
}

#createtaskmodalv2 label {
    font-weight: normal !important;
    text-transform: inherit;
    font-size: 0.9rem;
    margin-bottom: 10px;
    /* display:flex !important;
        align-items:center !important;*/
}

#createtaskmodalv2 .modal-header {
    align-items: center;
    display: flex;
    justify-content: space-between;
}

.form-control a {
    color: #4f5467 !important;
}

.form-control a:hover {
    color: #4f5467 !important;
}

#createtaskmodalv2 .btn-close:hover {
    background-color: #22c6ab !important;
}

.block {
    display: block;
}

#createtaskmodalv2 .btn-close {
    width: 35px;
    height: 35px;
    line-height: 35px;
    cursor: pointer;
    background-color: #dfe3eb;
    border-radius: 5px;
    border: none !important;
    display: flex;
    justify-content: center;
}

#createtaskmodalv2 .btn-close img {
    width: 20px;
}

#createtaskmodalv2 .chosen-container-multi .chosen-choices li.search-choice {
    margin: 10px 10px 10px 0;
    font-size: 0.9rem;
}

#createtaskmodalv2 .form-control {
    font-size: 0.9rem !important;
    font-weight: normal !important;
    text-transform: capitalize;
    background-color: #fff !important;
    height: 45px;
    border-width: 2px;
    border-radius: 5px !important;
    border-color: #eeeeee;
}

.btn__back.hid {
    display: none;
}

.chosen-container {
    width: 100% !important;
}

.chosen-container-multi .chosen-choices li.search-choice .search-choice-close {
    top: 9px !important;
}

.chosen-container-multi .chosen-choices {
    border: 1px solid #dadada !important;
    background-image: none !important;
    min-height: 35px;
    border-radius: 3px;
}

.chosen-container-multi .chosen-choices li.search-choice {
    position: relative;
    font-weight: normal;
    border: none;
    margin: 5px 10px 5px 0;
    padding: 8px 22px 8px 12px;
    max-width: 100%;
    color: #323441 !important;
    border-radius: 3px;
    background-color: #f0f0f0;
    font-size: 12px;
    box-shadow: 0 0 2px white inset, 0 1px 0 rgb(0 0 0 / 5%);
    color: #333;
    line-height: 13px;
    cursor: default;
}

#createtaskmodalv2 .line-control-menu-bar .dropdown-toggle {
    font-size: 0.9rem;
}

.Editor-container .fa {
    font-size: 0.9rem;
}

#createtaskmodalv2 .chosen-container-multi .chosen-choices li.search-field input[type="text"] {
    margin: 0px 0;
    padding: 0 0 0 10px;
    height: 50px;
    outline: 0;
    border: 0 !important;
    background: transparent !important;
    box-shadow: none;
    color: #6c757d;
    font-size: 0.9rem;
    font-family: sans-serif;
    line-height: normal;
    border-radius: 0;
}

.upload {
    border: 1px solid #dadada;
    height: 35px;
    padding-left: 12px;
    border-radius: 3px;
    display: flex;
    align-items: center;
}

#createtaskmodalv2 .red_star {
    color: #dc3545;
    padding-left: 5px;
}

#createtaskmodalv2 .red {
    color: red;
    padding-left: 5px;
}

#createtaskmodalv2 .form-group {
    margin: 50px 0px;
}

#createtaskmodalv2 .form-control {
    height: 45px;
}

.Editor-container .fa {
    font-size: 12px !important;
}

.line-control-menu-bar .dropdown-toggle {
    font-size: 12px !important;
    font-weight: 600;
}

.line-control-menu-bar {
    border: none;
    padding: 0px !important;
}

#createtaskmodal .chosen-container-multi .chosen-choices {
    background-image: none !important;
    border: 1px solid #dadada !important;
}

.selected_optns {
    background-color: #f0f0f0;
    font-size: 12px;
    padding: 3px 10px;
    margin: 10px 5px 5px 0px;
    border-radius: 3px;
    font-weight: normal;
}

.p-r-0 {
    padding-right: 0px;
}

.prpfilesubtitle {
    padding-left: 0px !important;
}

.current {
    font-size: 12px;
}

#view_all_Createdtasks_wrapper {
    padding: 0 !important;
}

#view_all_Createdtasks_wrapper {
    overflow-y: auto;
}

.newStatus {
    border-right: 5px solid #156dab !important;
}

.acthd {
    font-size: 13px;
    color: #000;
    font-weight: normal;
}

#fileLoader {
    display: none;
}

.display-text {
    color: #686c86;
    font-size: 18px;
}

.create_task_modalbody_content>.row>.col-md-12>.form-group>label {
    margin-bottom: 5px;
    color: #5c5c5c;
    font-size: 12px;
    text-transform: uppercase;
}

.form-check-label {
    margin-bottom: 0;
    text-transform: capitalize;
    font-size: 14px;
    font-weight: bold !important;
}

#createtask-body>.row>.col-md-12>.form-group>label {
    margin-bottom: 5px;
    color: #5c5c5c;
    font-size: 12px;
    text-transform: uppercase;
}

.hidn {
    display: none !important;
}

.view {
    display: block;
}

.borderred.form-control {
    border: 1px solid red !important;
    box-shadow: 0px 0px 5px #ccc;
    /* border-color: red !important; */
}

.btn {
    font-size: 14px !important;
}

.highlight {
    border: 2px solid rgb(108 117 125 / 55%);
}

.form-control {
    font-size: 14px !important;
    border-color: #dadada !important;
    border-radius: 3px !important;
    font-weight: bold;
    background-color: #f2f2f2 !important;
}

.create_task_modalbody_content>.row>.col-md-12>.form-group {
    margin-bottom: 1rem !important;
}

.create_task_modalbody_content>.row>.col-md-3>.form-group {
    margin-bottom: 1rem !important;
}

.create_task_modalbody_content>.row>.col-md-12>.form-group>select {
    border-color: #dadada;
    border-radius: 3px;
    background-color: #f2f2f2;
}

.create_task_modalbody_content>.row>.col-md-3>.form-group>select {
    border-color: #dadada;
    border-radius: 3px;
    background-color: #f2f2f2;
}

.create_task_modalbody_content>.row>.col-md-3>.form-group>button {
    border: 1px solid #dadada;
    border-radius: 3px;
    background-color: #f2f2f2;
    font-size: 14px;
}

#createtask-body>.row>.col-md-12>.form-group {
    margin-bottom: 1rem !important;
}

#createtask-body>.row>.col-md-3>.form-group {
    margin-bottom: 1rem !important;
}

#createtask-body>.row>.col-md-12>.form-group>select {
    border-color: #dadada;
    border-radius: 3px;
    background-color: #f2f2f2;
}

#view_all_tasks_wrapper {
    padding: 0;
    overflow-y: auto;
}

#createtask-body>.row>.col-md-3>.form-group>select {
    border-color: #dadada;
    border-radius: 3px;
    background-color: #f2f2f2;
}

table.dataTable {
    overflow-x: hidden;
}

.star {
    padding-left: 3px;
}

.hov:hover {
    cursor: pointer;
}

.msg-txt {
    font-weight: 400;
    color: #323441;
    font-size: 14px;
}

.grey {
    padding-left: 10px;
    color: #686c86;
    font-weight: 700;
}

#view_all_Createdtasks_wrapper {
    overflow-y: auto;
}

#view_all_Createdtasks1_wrapper {
    overflow-y: auto;
}

#created {
    overflow-y: auto;
}

.txt-right {
    text-align: right;
}

.chatbox {
    padding: 12px 0px;
}

.createdtask>.card>.card-header {
    height: 50px;
}

.createdtask>.card>.card-body {}

.createdtask>.card>.card-footer {
    height: 60px;
    background-color: #fff;
    border-top: 1px solid #cbd6e2;
    position: absolute;
    bottom: 0px;
    right: 0;
    left: 0;
    padding: 10px;
}

.chat-img {
    width: 25px;
    width: 25px;
    border-radius: 50%;
    position: absolute;
    left: 0;
}

.taskdetails h5 {
    font-size: 14px;
    font-weight: 700;
}

.comments h5 {
    font-size: 14px;
    font-weight: 700;
}

.comments>.col-md-12>.form-group>input {
    border-color: #dadada;
    border-radius: 3px;
    background-color: #f2f2f2;
    font-size: 14px;
}

.taskdetails>.col-md-6>.form-group {
    margin-bottom: 18px !important;
    font-size: 14px;
}

.taskdetails>.col-md-6>.form-group>.btn {
    border: 1px solid #dadada;
    border-radius: 3px;
    background-color: #f2f2f2;
    font-size: 14px;
}

.btn-outline-dark {
    background-color: transparent;
    color: #22c6ab;
    border-color: #22c6ab;
    border-radius: 3px;
}

text {
    font-weight: normal;
    font-size: 14px;
    color: #212529;
}

.taskdetails label {
    width: 100%;
    display: block;
    font-weight: 400 !important;
    margin-bottom: 5px;
    color: #5c5c5c;
    font-size: 12px;
    text-transform: uppercase;
}

.flx {
    display: flex !important;
}

.p-t-10 {
    padding-top: 10px;
    font-size: 14px;
}

.p-t-10 strong {
    padding-right: 3px;
}

.exporticn {
    width: 20px;
}

ul.status {
    list-style: none;
    display: flex;
    margin-bottom: 24px;
    padding-left: 0px !important;
}

ul.status li {
    list-style: none;
    padding-right: 20px;
}

.createdtask {
    margin-top: 8px;
}

.createdtask>.card-header {
    background-color: #fff !important;
}

.createdtask h3 {
    font-size: 14px !important;
    font-weight: 700;
    margin-bottom: 0px !important;
    margin-right: 5px;
    text-transform: uppercase;
}

.txt-right span {
    margin-right: 8px;
    margin-left: 8px;
}

.txt-right img {
    height: 20px;
}

.rec {
    border-radius: 5px !important;
    background-color: #ba68c8 !important;
    border-color: #ba68c8 !important;
    width: 100% !important;
    font-size: 12px !important;
}

.followup {
    border-radius: 5px !important;
    background-color: #64b6f6 !important;
    border-color: #64b6f6 !important;
    width: 100% !important;
    font-size: 12px;
}

.profilename {
    white-space: normal !important;
}

.outpt-val {
    background-color: rgba(50, 52, 65, 0.07);
    border-radius: 2px;
    min-height: 25px;
    margin-bottom: 1em;
    padding: 5px 5px 5px 5px;
    color: #000;
    font-weight: 700;
}

.fed-ans {
    background-color: rgba(50, 52, 65, 0.07);
    border-radius: 2px;
    /*margin-bottom: 1em;*/
    padding: 5px;
    color: #000;
    font-weight: 700;
}

.modal-content {
    background-color: #fefefe;
    margin: auto;
    border: 1px solid #888;
    /* width: 50%; */
    border-radius: 10px;
}

.modal-dialog {}

.close {
    color: #aaaaaa;
    font-size: 28px;
    font-weight: bold;
    text-align: right;
}

.close:hover,
.close:focus {
    color: #000;
    text-decoration: none;
    cursor: pointer;
}

.fed-opt {
    font-weight: 800;
    font-size: 14px;
    padding: 0px 0px 5px 0px;
}

.lbl {
    font-size: 14px;
    font-weight: 800 !important;
}

.lbls {
    padding-bottom: 15px !important;
}

th.sorting::after,
th.sorting::before {
    color: #6c757d;
}

.notvisible {
    display: none;
}

.cus-imgs {
    width: 25px;
}

table.dataTable thead>tr>th.sorting:hover {
    background-color: #e5eff8 !important;
}

th.sticky-cell.trig.sorting:hover {
    background-color: #e5eff8 !important;
}

th.sticky-cell.trig.sorting_asc {
    background-color: #e5eff8 !important;
}

.profilename {
    width: 100% !important;
}

.profile {
    width: 100% !important;
}

th.sticky-cell.trig.sorting_desc {
    background-color: #e5eff8 !important;
}

table.dataTable thead>tr>th.sorting_asc {
    background-color: #e5eff8 !important;
}

table.dataTable thead>tr>th.sorting_desc {
    background-color: #e5eff8 !important;
}

th.sorting_asc::after {
    color: #22c6ab;
    content: "\f0de";
    font-family: FontAwesome;
    font-size: 18px;
    font-weight: normal;
}

th.sorting_asc::before {
    color: #6c757d;
    content: "\f0dd";
    font-family: FontAwesome;
    font-size: 18px;
    font-weight: normal;
}

th.sorting_desc::after {
    color: #6c757d;
    content: "\f0dd";
    font-family: FontAwesome;
    font-size: 18px;
    font-weight: normal;
}

.cellbg {
    white-space: break-spaces;
}

th.sorting_desc::before {
    color: #22c6ab;
    content: "\f0de";
    font-family: FontAwesome;
    font-size: 18px;
    font-weight: normal;
}

table.dataTable thead .sorting:after,
table.dataTable thead .sorting_asc:after,
table.dataTable thead .sorting_desc:after,
table.dataTable thead .sorting_asc_disabled:after,
table.dataTable thead .sorting_desc_disabled:after {
    right: 1em !important;
    font-size: 14px;
    font-weight: normal;
}

table.dataTable thead .sorting:before,
table.dataTable thead .sorting_asc:before,
table.dataTable thead .sorting_desc:before,
table.dataTable thead .sorting_asc_disabled:before,
table.dataTable thead .sorting_desc_disabled:before {
    content: "\f0dd";
    font-family: FontAwesome;
    font-size: 14px;
    font-weight: normal;
}

table.dataTable thead .sorting:after,
table.dataTable thead .sorting_asc:after,
table.dataTable thead .sorting_desc:after,
table.dataTable thead .sorting_asc_disabled:after,
table.dataTable thead .sorting_desc_disabled:after {
    content: "\f0de";
    font-family: FontAwesome;
    font-size: 18px;
    font-weight: normal;
}

.slider-danger .noUi-handle {
    border-color: #dc3545 !important;
}

.noUi-horizontal.slider-xs {
    margin-top: 1em !important;
}

.see {
    display: block;
}

.tooltip11 {
    outline: none;
    color: darkgray;
}

/*.sticky-cell{
    z-index: !important;
        }
        .xyz{
            z-index:0 !important;
        }*/
.slider-danger.circle-filled .noUi-handle,
.slider-danger.square .noUi-handle {
    background: #dc3545 !important;
}

.fa {
    font-size: 16px !important;
}

.tooltip11:hover {
    text-decoration: none;
    cursor: pointer;
    /*border: 1px solid rgb(151,194,236) !important;*/
}

/*.week{
            font-weight:bold !important;
        }*/
/*.month{
            font-weight:bold !important;
        }*/
.tooltip11 {}

.tooltip11 span {
    display: none;
    padding: 5px 5px;
    margin-top: 10px;
    margin-left: -100px;
    word-spacing: 3px;
    font-weight: 600;
    width: 250px !important;
    font-family: "Lato", sans-serif;
}

.tooltip11:hover span {
    display: inline;
    position: absolute;
    color: #000;
    font-weight: 500;
    background: rgb(255, 255, 255) !important;
}

.noUi-horizontal.slider-xs .noUi-handle {
    cursor: pointer;
}

/*.callout {
            z-index: 20;
            position: absolute;
            top: 30px;
            border: 0;
            left: -12px;
        }*/
/*CSS3 extras*/
.tooltip11 span {
    /*border-radius: 4px;*/
    box-shadow: 5px 5px 8px #ccc;
}

.actval {
    color: #000;
    font-size: 14px;
    font-weight: 800;
}

.icns {
    list-style: none;
    display: flex;
}

ul.icns li {
    padding: 0px 10px;
}

ul.icns li font-face {
    font-size: 16px;
}

.View_all_records {
    text-align: center;
    /*background-color: #627486;
        background-image: linear-gradient(180deg, rgba(50, 52, 65, .09), rgba(74, 78, 104, .07));*/
    box-shadow: 5px 5px 5px #e9ecef;
    background-color: #fff;
    padding: 5px 0px;
    margin: 5px 0px;
}

.coachnow {
    padding: 10px;
    float: right;
    width: 100%;
    text-align: right;
}

label:not(.swal2-checkbox) {
    font-weight: 600;
    display: block !important;
    font-size: 14px;
    text-transform: capitalize;
    margin-bottom: 5px;
}

thead tr th {
    white-space: nowrap;
}

.coch-hd {
    padding: 2px 0px 10px 8px;
    border-bottom: 1px solid #ddd;
    font-size: 18px !important;
    margin-bottom: 0.5em !important;
}

.coaching {
    box-shadow: 0px 0px 5px #ccc;
    background-color: #fff;
    padding: 10px 15px 10px 15px;
    margin-top: 9px;
}

.cellbg {
    background-color: rgb(255, 255, 255) !important;
}

table.dataTable thead .sorting:before,
table.dataTable thead .sorting:after,
table.dataTable thead .sorting_asc:before,
table.dataTable thead .sorting_asc:after,
table.dataTable thead .sorting_desc:before,
table.dataTable thead .sorting_desc:after,
table.dataTable thead .sorting_asc_disabled:before,
table.dataTable thead .sorting_asc_disabled:after,
table.dataTable thead .sorting_desc_disabled:before,
table.dataTable thead .sorting_desc_disabled:after {
    opacity: 9 !important;
}

/*.table-striped tbody tr:nth-of-type(odd) {
        background-color: #fff !important;
    }*/

.table-striped tbody tr:nth-of-type(even) {
    background-color: #fff !important;
}

.table-bordered,
.table-bordered td,
.table-bordered th {
    border: none !important;
}

.btn-primary {
    background-color: #21c7a9 !important;
    border-color: #21c7a9 !important;
    font-weight: normal;
    border-radius: 5px;
}

.btn-gray {
    background-color: gray !important;
    border-color: gray !important;
    color: #fff;
}

.btn-gray-bordered {
    border-color: gray !important;
}

.mytasks {
    width: 26px;
    padding: 0px;
}

.mytsk_head {
    padding: 10px 0px 0px 0px;
    font-size: 14px;
    color: #000;
    background-color: #fff;
}

.tsk_nm {
    font-size: 13px;
    font-weight: normal;
    font-weight: 500;
}

.mgr-profile-small {
    width: 25px;
}

#view_all_tasks_length {
    display: none !important;
}

div.dataTables_wrapper div.dataTables_paginate ul.pagination {}

.page-link {
    color: #21c7a9 !important;
}

a#view_all_Createdtasks.previous.disabled {
    color: #21c7a9 !important;
    background-color: #fff;
    border-color: #dee2e6 !important;
}

a#view_all_Createdtasks.paginate_button {
    color: #21c7a9 !important;
}

a#view_all_Createdtasks.paginate_button.current {
    color: #21c7a9 !important;
}

a#view_all_Createdtasks_previous.paginate_button.previous.disabled:hover {
    color: #fff !important;
}

a#view_all_Createdtasks_previous.paginate_button.previous.disabled {
    color: #21c7a9 !important;
}

a#view_all_Createdtasks1.previous.disabled {
    color: #21c7a9 !important;
    background-color: #fff;
    border-color: #dee2e6 !important;
}

a#view_all_Createdtasks1.paginate_button {
    color: #21c7a9 !important;
}

a#view_all_Createdtasks1.paginate_button.current {
    color: #21c7a9 !important;
}

a#view_all_Createdtasks1_previous.paginate_button.previous.disabled:hover {
    color: #fff !important;
}

a#view_all_Createdtasks1_previous.paginate_button.previous.disabled {
    color: #21c7a9 !important;
}

.dataTables_wrapper .dataTables_paginate .paginate_button:hover {
    color: #fff !important;
    background-color: #21c7a9 !important;
    background-image: none !important;
    margin-left: 0px !important;
    border-color: #dee2e6 !important;
}

.dataTables_wrapper .dataTables_paginate .paginate_button {
    color: #21c7a9 !important;
    background-color: #fff;
    margin-left: 0px !important;
    border-color: #dee2e6 !important;
}

.dataTables_paginate .paginate_button.disabled,
.dataTables_wrapper .dataTables_paginate .paginate_button.disabled:hover,
.dataTables_wrapper .dataTables_paginate .paginate_button.disabled:active {
    color: #21c7a9 !important;
    background-color: #fff;
    border-color: #dee2e6;
}

.dataTables_paginate .paginate_button.current,
.dataTables_wrapper .dataTables_paginate .paginate_button.current:hover {
    background-color: #21c7a9 !important;
    border-color: #21c7a9 !important;
    background-image: none !important;
    color: #fff !important;
}

.page-item.active .page-link {
    background-color: #21c7a9 !important;
    border-color: #21c7a9 !important;
    color: #fff !important;
}

.View_all_history {
    text-align: center;
    box-shadow: 5px 5px 5px #e9ecef;
    background-color: #fff;
    /*background-color: #627486;
        background-image: linear-gradient(180deg, rgba(50, 52, 65, .09), rgba(74, 78, 104, .07));*/
    padding: 5px 0px;
    margin: 5px 0px;
}

.created_tasks {
    text-align: center;
    box-shadow: 5px 5px 5px #e9ecef;
    background-color: #fff;
    /*background-color: #627486;
        background-image: linear-gradient(180deg, rgba(50, 52, 65, .09), rgba(74, 78, 104, .07));*/
    padding: 5px 0px;
    margin: 5px 0px;
}

.newtask {
    text-align: center;
    box-shadow: 5px 5px 5px #e9ecef;
    background-color: #fff;
    /*background-color: #627486;
        background-image: linear-gradient(180deg, rgba(50, 52, 65, .09), rgba(74, 78, 104, .07));*/
    padding: 5px 0px;
    margin: 5px 0px;
}

.myhistory {
    width: 35px;
    padding: 0px;
}

.crtstask {
    width: 35px;
    padding: 0px;
}

.newtaskimg {
    width: 35px;
    border: 1px solid #4a4b4d;
    padding: 5px;
    border-radius: 50%;
}

.mytsk_history {
    padding: 10px 0px 0px 0px;
    text-align: center;
    background-color: #fff;
    font-size: 14px !important;
    color: #000;
}

.createtsk_history {
    padding: 10px 0px 0px 0px;
    text-align: center;
    background-color: #fff;
    font-size: 14px !important;
    color: #000;
}

.newtsk {
    padding: 10px 0px 0px 0px;
    text-align: center;
    background-color: #fff;
    font-size: 14px !important;
    color: #000;
}

#view_all_Createdtasks_wrapper {
    padding: 0px !important;
}

#view_all_Createdtasks1_wrapper {
    padding: 0px !important;
}

.tsk_sub {
    font-size: 12px !important;
    color: #00b9ff !important;
    /*font-weight:500;*/
}

.table.dataTable {
    border-collapse: collapse !important;
    border: 2px solid rgba(209, 209, 211, 0.39) !important;
    box-shadow: 0 0px 6px 0px rgba(0, 0, 0, 0.11);
    font-family: Lato, sans-serif !important;
}

table.table-bordered.dataTable th,
table.table-bordered.dataTable td {}

table.table-bordered.dataTable tbody td {
    padding: 7px !important;
}

table.dataTable thead .sorting:before,
table.dataTable thead .sorting:after,
table.dataTable thead .sorting_asc:before,
table.dataTable thead .sorting_asc:after,
table.dataTable thead .sorting_desc:before,
table.dataTable thead .sorting_desc:after,
table.dataTable thead .sorting_asc_disabled:before,
table.dataTable thead .sorting_asc_disabled:after,
table.dataTable thead .sorting_desc_disabled:before,
table.dataTable thead .sorting_desc_disabled:after {
    bottom: 10px !important;
}

tbody tr td {
    font-size: 13px !important;
    font-weight: normal;
}

table.dataTable thead>tr>th.sorting_asc,
table.dataTable thead>tr>th.sorting_desc,
table.dataTable thead>tr>th.sorting,
table.dataTable thead>tr>td.sorting_asc,
table.dataTable thead>tr>td.sorting_desc,
table.dataTable thead>tr>td.sorting {
    padding-right: 28px !important;
}

table.dataTable thead .sorting:before,
table.dataTable thead .sorting_asc:before,
table.dataTable thead .sorting_desc:before,
table.dataTable thead .sorting_asc_disabled:before,
table.dataTable thead .sorting_desc_disabled:before {
    font-size: 14px !important;
}

table.dataTable thead .sorting:after,
table.dataTable thead .sorting_asc:after,
table.dataTable thead .sorting_desc:after,
table.dataTable thead .sorting_asc_disabled:after,
table.dataTable thead .sorting_desc_disabled:after {
    font-size: 14px !important;
}

.create_task_second_level .form-control {
    text-transform: inherit !important;
}

tr:hover {
    background-color: #f5f8fa;
}

tbody>tr.active {
    background-color: #e5eff8;
}

tr {
    border: 1px solid #ccc;
}

.newStatus {
    border-right: 5px solid #156dab !important;
    border: 1px solid #ccc;
}


.initials_tm {
    float: left;
    background-image: linear-gradient(45deg, #212529, #495057);
    background-color: #ccc;
    border-radius: 100%;
    color: #fff;
    padding: 10px;
    width: 30px;
    height: 30px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 12px;
    margin-left: 3px;
    margin-right: 6px;
}

#createtaskmodalv2 .btn-primary {
    background-color: #21c7a9 !important;
    border-color: #21c7a9 !important;
    font-weight: normal;
    padding: 5px 25px;
}

.staus_block {
    border: 1px solid #dadada !important;
    padding: 10px 15px !important;
    border-radius: 5px !important;
    margin-bottom: 15px !important;
}