.time_frame_block .dataTables_scrollBody {
    background-color: transparent !important;
}

.time_frame_block {
    padding: 10px 15px 0px 15px;
}

.time_frame_block .dropdown-menu.show {
    border-color: transparent;
    box-shadow: 0px 0px 5px #ccc;
    height: 55vh;
    overflow-y: auto;
}

.save_footer {
    border-top: 1px solid #ebebeb;
    padding: 15px;
}

.save_footer .btn-cancel {
    font-size: 12px;
    border-radius: 5px;
    text-align: center;
    border: 1px solid #e6e9ed;
    margin-right: 10px;
}

.time_frame_block .btn-primary:hover {
    background-color: #0c6857 !important;
}

.time_frame_block .btn-primary {
    background-color: #21c7a9;
    border: 1px solid #21c7a9 !important;
    padding: 12px 12px !important;
    border-radius: 5px;
    font-weight: 600;
    color: #fff;
    font-size: 12px;
    text-align: center;
    /* letter-spacing: 0.5px; */
}

.timeFrame,
.org_selection,
.prog_selection {
    margin-right: 10px;
}

.w-65 {
    width: 65%;
}

span.k-autocomplete.k-input.form-control {
    height: 35px !important;
}

.show_scorecards li.btn {
    display: block !important;
    padding: 10px 15px !important;
    margin-top: 5px;
    margin: 5px 5px 5px 5px;
}

.show_scorecards li.btn:hover {
    background-color: #22c7a9 !important;
    border-color: #22c7a9 !important;
    color: #fff !important;
}

.show_scorecards {
    padding: 5px 10px;
}

.datepicker {
    color: #344054 !important;
    font-size: 12px;
    width: 285px;
    border-top: none;
    margin-top: -35px;
    padding: 0;
    border-right: none;
    border-bottom: none;
    border-radius: 0;
}

.datepicker .datepicker-days {
    padding: 18px 0px;
}

.datepicker th,
td {
    box-shadow: none;
    border-color: transparent;
}

.datepicker tbody tr {
    height: auto !important;
    border-bottom-color: transparent !important;
}

.datepicker td,
.datepicker th {
    width: 20px;
    height: 10px;
    border-radius: 20px;
}

.datepicker thead {
    box-shadow: none !important;
}

.datepicker-dropdown:before {
    display: none !important;
}

div#datepicker .form-control {
    height: 0px !important;
}

div#datepickertimeframemodal .form-control {
    height: 0px !important;
}

.datepicker .date_selection_save_footer {
    display: flex !important;
}

.datepicker .table-condensed>tbody>tr>td,
.table-condensed>thead>tr>th {
    padding: 10px 12px;
}

.datepicker .table {
    margin-bottom: 10px;
}

.visibility_hidden {
    visibility: hidden;
}

.datepicker table tr td.active.today.day {
    background-color: #556ee6 !important;
}

.swal2-container .swal2-title {
    font-size: 18px;
    line-height: 21px;
    font-weight: 500;
    padding-top: 30px !important;
    padding: 15px;
    font-weight: 600;
}

.save_footer .btn-primary:hover {
    background-color: #6941c6 !important;
    border-color: #6941c6 !important;
    pointer-events: auto;
    cursor: pointer !important;
}

.save_footer .btn-cancel:hover {
    background-color: #e2e1e1 !important;
}

.datepicker table tr td.today {
    background-color: #22c7a9 !important;
}


.datepicker table tr td.today:hover:hover {
    background-color: #6941c6 !important;
}



.datepicker table tr td span.focused,
.datepicker table tr td span:hover,
.datepicker table tr td.day.focused,
.datepicker table tr td.day:hover {
    background: #f9fafb !important;
}

.datepicker table tr td.today {
    background-color: #22c7a9 !important;
    background-image: none !important;
    color: #fff;
}

.datepicker table tr td.active:active,
.datepicker table tr td.active:hover:active,
.datepicker table tr td.active.disabled:active,
.datepicker table tr td.active.disabled:hover:active,
.datepicker table tr td.active.active,
.datepicker table tr td.active:hover.active,
.datepicker table tr td.active.disabled.active,
.datepicker table tr td.active.disabled:hover.active {
    background-color: #6941c6 !important;
    background-image: none !important;
}

.time_frame_block .dataTables_scrollBody {
    background-color: transparent !important;
}

.time_frame_block {
    padding: 10px 15px 15px 15px;
}

.time_frame_block .dropdown-menu.show {
    border-color: transparent;
    box-shadow: 0px 0px 5px #ccc;
    height: 55vh;
    overflow-y: auto;
}

.save_footer {
    border-top: 1px solid #ebebeb;
    padding: 15px;
}

.save_footer .btn-cancel {
    font-size: 12px;
    border-radius: 5px;
    text-align: center;
    border: 1px solid #e6e9ed;
    margin-right: 10px;
}

.org_block td:first-child {
    position: sticky !important;
    z-index: 99 !important;
}



.time_frame_block .btn-primary:hover {
    background-color: #21c7a9 !important;
}

.time_frame_block .btn-primary {
    background-color: #6941c6;
    border: 1px solid #6941c6 !important;
    padding: 13px 12px !important;
    border-radius: 5px;
    font-weight: 600;
    color: #fff;
    font-size: 12px;
    text-align: center;
    /* letter-spacing: 0.5px; */
}

.timeFrame,
.org_selection,
.prog_selection {
    margin-right: 10px;
}


.datepicker {
    color: #344054 !important;
    font-size: 12px;
    width: 285px;
    border-top: none;
    margin-top: -35px;
    padding: 0;
    border-right: none;
    border-bottom: none;
    border-radius: 0;
}

.datepicker .datepicker-days {
    padding: 18px 0px;
}

.datepicker th,
td {
    box-shadow: none;
    border-color: transparent;
}

.datepicker tbody tr {
    height: auto !important;
    border-bottom-color: transparent !important;
}

.datepicker td,
.datepicker th {
    width: 20px;
    height: 10px;
    border-radius: 20px;
}

.datepicker thead {
    box-shadow: none !important;
}

.datepicker-dropdown:before {
    display: none !important;
}

div#datepicker .form-control {
    height: 0px !important;
}

.datepicker .date_selection_save_footer {
    display: flex !important;
}

.datepicker .table-condensed>tbody>tr>td,
.table-condensed>thead>tr>th {
    padding: 10px 12px;
}

.datepicker .table {
    margin-bottom: 10px;
}

.visibility_hidden {
    visibility: hidden;
}

.datepicker table tr td.active.today.day {
    background-color: #556ee6 !important;
}


.save_footer .btn-primary:hover {
    background-color: #6941c6 !important;
    border-color: #6941c6 !important;
    pointer-events: auto;
    cursor: pointer !important;
}

.save_footer .btn-cancel:hover {
    background-color: #f9fafb !important;
}

.time_frame_block .form-group label {
    color: #344054;
    display: inline-block;
    font-size: 13px;
    margin-bottom: 7px;
    font-weight: 500;
}

.time_frame_block .form-select {
    color: #656e7e !important;
    border-radius: 5px;
    height: 35px;
    font-size: 13px;
}

.time_frame_block .nav-pills .nav-link.active,
.nav-pills .show>.nav-link {
    color: #323441;
    background-color: #f9fafb;
    font-size: 12px;
}

.time_frame_block .dropdown-menu.show {
    font-size: 12px;
    padding: 0;
    height: 55vh;
}

.visibility-hidden {
    visibility: hidden;
}

/*.time_frame_block .month_selector {
        height: 65vh;
        overflow-y: auto;
        overflow-x: hidden;
    }*/
.time_frame_block .border-right {
    padding: 15px;
    min-width: 150px;
}

.time_frame_block .border-left {
    border-left: 1px solid #ebebeb;
    /*  height:55vh;*/
    position: relative;
    min-width: 285px;
}

.datepicker table tr td.today {
    background-color: #22c7a9 !important;
}

.datepicker table tr td.today:hover:hover {
    background-color: #6941c6 !important;
}

.datepicker-months td {
    /* padding: 41px 15px !important; */
    width: 285px;
}

.datepicker-months {
    padding: 50px;
}

.datepicker-years {
    padding: 50px;
}

.datepicker table tr td span.focused,
.datepicker table tr td span:hover,
.datepicker table tr td.day.focused,
.datepicker table tr td.day:hover {
    background: rgba(35, 199, 169, .18) !important;
}

.time_frame_block .tab-content>.tab-pane {
    min-width: 250px;
}

.time_frame_block .month_selector {
    padding: 15px;
}

.time_frame_block .nav-link {
    color: #323441;
}

.time_frame_block ul {
    padding: 0;
    margin: 0;
    list-style: none;
}

.time_frame_block li {
    padding: 5px 10px;
    border-radius: 3px;
    margin: 2px 0px;
}

.time_frame_block li:hover {
    background-color: #f9fafb;
}

.time_frame_block li.active {
    background-color: #f9fafb;
}

.save_footer {
    display: flex;
    justify-content: end;
    align-items: center;
    padding: 15px;
}

.save_footer {
    border-top: 1px solid #ebebeb;
    padding: 15px;
    position: sticky;
    bottom: -1px;
    width: 100%;
    right: 0;
    background: #fff;
}

.save_footer .btn-primary {
    background-color: #21c7a9 !important;
    border-color: #21c7a9 !important;
    box-shadow: 0px 0px 5px #ccc;
    color: #fff !important;
}

.save_footer .btn {
    font-size: 12px;
    border-radius: 5px;
    text-align: center;
    padding: .375rem .75rem !important;
}

.month_selector img {
    width: 28px;
    height: 28px;
}