.time_frame_block {
  font-size: 12px;
  font-weight: 600;
  padding: 0px;
}

.navbar-light .navbar-nav .nav-link {
  color: #fff !important;
}

.w-40 {
  width: 40%;
}

.w-60 {
  width: 60%;
}

.datepicker .datepicker-days {
  padding: 18px 10px;
}

.org_block {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px 15px 10px 20px;
  border-right: 1px solid #dfe3eb;
  border-bottom: 1px solid #dfe3eb;
}

.border-bottom {
  border-bottom: 1px solid #dfe3eb;
}

.border-bottom.metric_block {
  pointer-events: none !important;
  cursor: none !important;
}

.border-bottom.metric_block .metric_name {
  font-weight: normal !important;
}

.border-bottom.metric_block:hover {
  background-color: transparent !important;
}

.month_selector img {
  width: 20px;
  height: 20px;
}

.time_frame_block li {
  padding: 10px;
  cursor: pointer;
}

.time_frame_block .month_selector {
  padding: 10px 0px 0px 5px;
}

.w-32 {
  width: 32%;
}

.time_frame_block .form-select {
  color: #667085 !important;
  border-radius: 8px;
  height: 42px;
  line-height: 28px;
  border: 1px solid #d0d5dd !important;
  font-size: 14px;
  align-items: center;
  background-color: #fff;
}

body {
  background-color: #f5f8fa;
}

.block {
  display: block;
}

.ticker_block {
  display: flex;
  justify-content: flex-start;
  align-items: start;
  height: 88px;
}

.js-marquee {
  margin-right: 0px !important;
}

.ticker_block {
  height: 60px;
  /* border-right: 1px solid #dfe3eb; */
  display: flex;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
}

@-webkit-keyframes ticker {
  0% {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
    visibility: visible;
  }

  100% {
    -webkit-transform: translate3d(-100%, 0, 0);
    transform: translate3d(-100%, 0, 0);
  }
}

@keyframes ticker {
  0% {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
    visibility: visible;
  }

  100% {
    -webkit-transform: translate3d(-100%, 0, 0);
    transform: translate3d(-100%, 0, 0);
  }
}

.ticker-wrap {
  position: static;
  left: 0;
  top: 0;
  right: 0;
  display: block;
  overflow: hidden;
  height: 60px;
  padding: 0;
  -webkit-box-pack: start;
  -webkit-justify-content: flex-start;
  -ms-flex-pack: start;
  justify-content: flex-start;
  border-bottom: 1px solid #dfe3eb;
  background-color: hsla(0, 0%, 100%, 0.03);
}

.ticker-wrap .ticker {
  display: flex;
  box-sizing: content-box;
}

div#lotie {
  /*background-color: #7460ee;*/
  border-radius: 4px;
  background-image: linear-gradient(180deg,
      rgba(92, 107, 192, 0.71),
      rgba(92, 107, 192, 0.71));
  width: 200px;
  height: 200px;
}

.ticker-wrap .ticker {
  display: flex;
  box-sizing: content-box;
}

.coaching_history_image:hover {
  cursor: pointer;
}

.ticker:hover {
  cursor: pointer;
}

.icPlay:hover {
  cursor: pointer;
}

.ticker-wrap .ticker__item {
  height: 60px;
  max-width: 220px;
  min-width: 220px;
  font-weight: 700;
  text-transform: uppercase;
  padding: 8px 24px;
  border-right: 1px solid #dfe3eb;
}

.last-update-time {
  border-right: 1px solid #dfe3eb;
  width: 120px;
  height: 89px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.metric_block {
  border-right: 1px solid #dfe3eb;
  /* border-left: 1px solid #dfe3eb; */
  height: 60px;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0px 25px;
  cursor: pointer;
}

.metric_block {
  height: 89px;
  justify-content: start;
  /* min-width:55%;*/
}

.last-update-time .metric_name,
.metric_block .metric_name {
  text-transform: capitalize !important;
  font-size: 12px !important;
  font-weight: 600 !important;
}

.last-update-time .bold,
.metric_block .bold {
  font-size: 14px;
  font-weight: 600 !important;
}

.ticker_block .last-update-time {
  border-bottom: 1px solid #dfe3eb;
}

.commit-tooltip {
  position: absolute;
  top: 16px;
  width: max-content;
  z-index: 1;
  display: none;
  padding: 4px 11px;
  border: 1px solid #dfe3eb;
  border-radius: 4px;
  background-color: #fff;
  box-shadow: 1px 1px 12px 0 rgb(0 0 0 / 19%);
  color: #272933;
  font-size: 12px;
  line-height: 1.25em;
  font-weight: 400;
}

.modaltitle {
  display: flex;
  justify-content: start;
  align-items: center;
  font-size: 16px;
  font-weight: 600;
}

.metric_detail_formula {
  width: 16px;
  height: 16px;
  border-radius: 16px;
  background: #e4e7eb;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  margin-left: 10px;
  font-size: 12px;
}

.metric_detail_formula:hover {
  background-color: #ff5722;
  color: #fff;
}

.metric_detail_formula img {
  width: 11px;
  height: 11px;
}

.btn.btnclose {
  width: 44px;
  height: 44px;
  cursor: pointer;
  background-color: #dfe3eb;
  border-radius: 5px;
  justify-content: center;
  align-items: center;
  padding: 0;
  display: flex;
}

.btnclose:hover {
  background-color: #22c6ab !important;
}

.btnclose img {
  width: 18px;
}

.modal-header {
  padding: 10px 24px;
}

.table tr {
  border-color: transparent;
}

.table tbody tr td {
  background-color: #fff;
  padding: 15px 20px !important;
}

.table thead tr th {
  padding: 15px 20px !important;
  border-right: 1px solid #dfe3eb;
  background-color: #f5f8fa;
  border-bottom-color: #dfe3eb !important;
  border-top: 1px solid #dfe3eb !important;
  text-transform: uppercase;
  font-size: 12px;
  text-align: center;
  white-space: nowrap;
}

.table tbody tr td {
  border-right-color: transparent;
  border-bottom: 1px solid #dfe3eb !important;
  font-size: 12px;
  font-weight: 600;
  text-align: center;
  vertical-align: middle;
  border-left-color: transparent;
}

.balance_scorecard_table .card-body {
  padding: 0;
}

.balance_scorecard_table tbody tr td {
  font-size: 12px;
  text-align: center;
}

.intrday_table {
  height: 78vh;
  overflow: auto;
}

.marquee {
  border-bottom: 1px solid #dfe3eb;
}

.org_block .form-group {
  margin-bottom: 0px;
}

.agent_profile {
  padding: 20px 20px 20px 40px;
  height: 200px;
  color: #fff;
  /* background-image: linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)),
    url("../images/agent_bg.png"); */
  background-position: 0 0, 50%;
  background-size: auto, cover;
  border-radius: 4px;
  /* background-image: url('../images/agent_bg.png')*/
}

.agent_image img {
  width: 120px;
  height: 120px;
  border-radius: 120px;
  margin-right: 24px;
}

.agent_name {
  font-weight: 600;
  font-size: 13px;
  display: flex;
  justify-content: flex-start;
  align-items: start;
}

.left_dashboard {
  width: calc(100% - 300px);
  border-right: 1px solid #dfe3eb;
}

.agent_name {
  font-size: 18px;
}

.agent_title {
  font-size: 14px;
}

.glidepath_title {
  font-size: 13px;
  background-color: #fff !important;
  color: #323441 !important;
  margin-left: 10px;
  font-weight: normal !important;
  padding: 2px 6px;
  border-radius: 6px;
}

.agent_profile .badge {
  display: flex;
  justify-content: start;
  flex: 2;
  margin-top: 1em;
}

.badge_count {
  position: absolute;
  top: -1px;
  right: -4px;
  background-color: #22c7a9 !important;
  width: 20px;
  height: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 20px;
  font-size: 10px;
}

.badge_icon {
  width: 35px;
  height: 38px;
  cursor: pointer;
}

.ml-20 {
  margin-left: 20px;
}

.medal {
  width: 37px;
}

.badge_icon .tool .commit-tooltip {
  position: absolute;
  top: 30px;
  /* min-width: 20px !important; */
  /* width: auto; */
  /* max-width: 1000px; */
  background-color: rgba(39, 41, 51, 0.95);
  color: #fff;
  padding: 12px 15px;
  white-space: nowrap;
  border: none;
  cursor: pointer;
  left: 0px;
  /*display: block;*/
  font-weight: 600;
  z-index: 1;
  border-radius: 3px;
  width: 230px;
  word-break: break-word;
  white-space: normal;
}

.agent_scorecard {
  background-color: rgba(0, 0, 0, 0.34);
  border-radius: 150px 4px 4px 150px;
  /* align-items: center; */
  padding-left: 8px;
  padding-right: 0px;
  height: 160px;
  width: 35%;
}

.coached_fields .form-control {
  background-color: #f8f8f8;
  opacity: 1;
  border-color: #cdcdcd;
  font-size: 14px;
  min-height: 40px;
  display: flex;
  justify-content: start;
  align-items: center;
  padding: 10px 15px;
  line-height: 21px;
}

@media screen and (max-width: 1489px) {
  .agent_scorecard {
    width: 45%;
  }
}

.ticker_block {
  height: 89px;
  /* border-right: 1px solid #dfe3eb; */
  display: flex;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
}

.metric_block {
  border-right: 1px solid #dfe3eb;
  /* border-left: 1px solid #dfe3eb; */
  height: 89px;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0px 25px;
  cursor: pointer;
}

#intraday_metrics .modal-content {
  border-radius: 5px !important;
}

#intraday_metrics .intraday_heading {
  padding: 15px 10px !important;
}

#table_scroll {
  overflow: auto;
}

#table_scroll .table {
  margin-top: 5px;
}

#intraday_metrics .modal-dialog {
  max-width: 90% !important;
}

.metric_block:hover {
  background-color: #dbe9e1;
}

.metric_block .metric_name {
  text-transform: uppercase !important;
  font-size: 12px !important;
  padding-bottom: 0px;
  font-weight: normal;
}

.metric_block .bold {
  font-weight: 900;
  color: #272933;
}

.metric_block .slash {
  padding: 0px 5px;
}

.cu-iframe {
  width: 100%;
  height: 100%;
}

.ml_content {
  height: 75vh;
}

.review_learning h6 {
  font-weight: 700;
  font-size: 16px;
}

.review_learning ul {
  list-style: none;
  padding: 0;
  font-weight: 600;
  display: flex;
  justify-content: flex-start;
  white-space: nowrap;
  flex-wrap: wrap;
}

.review_learning ul li {
  padding: 0px 15px 0px 0px;
}

.review_learning ul li a {
  font-size: 14px;
  font-weight: 700;
  color: #0b7d97 !important;
}

.review_learning ul li a:hover {
  color: #0b7d97 !important;
}

html {
  font-family: "Lato", sans-serif !important;
  -ms-text-size-adjust: 100%;
  -webkit-text-size-adjust: 100%;
}

.pointer {
  display: none;
}

.dis {
  font-size: 12px;
  padding: 10px 0px 5px 0px;
  font-weight: 600;
}

.disclaim {
  font-weight: bold;
  text-transform: uppercase;
  padding-right: 5px;
  color: #000;
}

.last_updated {
  position: absolute;
  left: 0;
  top: 0;
  padding: 8px 45px 8px 45px;
  border-right: 1px solid #dfe3eb;
  border-bottom: 1px solid #dfe3eb;
  font-weight: 400;
  text-transform: uppercase;
  font-size: 12px;
  margin-bottom: 0px;
}

.block {
  display: block !important;
}

/*.dataTables_scrollHeadInner {
    width: 100% !important;
}*/

.incentive-tile {
  background-color: #90b433;
  text-align: left;
  font-size: 14px;
}

.justify-content-center {
  justify-content: center;
}

div#nps-incentive.dropdown-menu.show {
  transform: none !important;
  top: 8em !important;
  z-index: 1 !important;
  left: 0 !important;
  right: 0 !important;
}

.m-0 {
  margin: 0px !important;
}

.above_goal:hover {
  background-color: #dbe9e1;
}

.below_goal:hover {
  background-color: #f3e0e0;
}

.last_updated_date {
  font-size: 14px;
}

strong {
  font-weight: 700;
}

@-webkit-keyframes ticker {
  0% {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
    visibility: visible;
  }

  100% {
    -webkit-transform: translate3d(-100%, 0, 0);
    transform: translate3d(-100%, 0, 0);
  }
}

@keyframes ticker {
  0% {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
    visibility: visible;
  }

  100% {
    -webkit-transform: translate3d(-100%, 0, 0);
    transform: translate3d(-100%, 0, 0);
  }
}

.ticker-wrap {
  position: static;
  left: 0;
  top: 0;
  right: 0;
  display: block;
  overflow: hidden;
  height: 60px;
  padding: 0;
  -webkit-box-pack: start;
  -webkit-justify-content: flex-start;
  -ms-flex-pack: start;
  justify-content: flex-start;
  border-bottom: 1px solid #dfe3eb;
  background-color: hsla(0, 0%, 100%, 0.03);
}

#agent_recognition_modal .modal-dialog {
  max-width: 45%;
}

#agent_recognition_modal .modal-content {
  padding: 50px 30px;
  border: 1px solid #000;
  border-radius: 4px !important;
  background-color: rgba(0, 0, 0, 0.5);
}

#agent_recognition_modal.show {
  background-color: rgba(0, 0, 0, 0.7);
}

/*#agent_recognition_modal .position-relative{
    margin:1em;
}*/
#agent_recognition_modal .w-60 {
  width: 60% !important;
}

#agent_recognition_modal .w-40 {
  width: 40% !important;
  display: flex;
  justify-content: center;
}

#agent_recognition_modal .anim-close {
  z-index: 999;
}

#agent_recognition_modal .position-relative {
  padding: 0px 15px;
}

.like_bg {
  background-color: hsla(0, 0%, 100%, 0.1);
  margin: 0 5px;
  width: 36px;
  height: 36px;
  border-radius: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.like_unlike_thumb {
  display: flex;
  justify-content: flex-start;
  align-items: center;
}

.like_unlike_thumb img {
  width: 18px;
  cursor: pointer;
}

.anim-close img {
  width: 23px;
}

.thumb_up:hover {
  background-color: rgba(108, 204, 118, 0.5);
}

.thumb_up {
  position: relative;
  cursor: pointer;
}

.thumb_down:hover {
  background-color: rgba(239, 83, 80, 0.5);
}

.thumb_down {
  cursor: pointer;
}

.anim-close:hover {
  background-color: rgba(255, 255, 255, 0.46);
}

.like_unlike_thumb i {
  color: #fff;
  cursor: pointer;
}

.appreciation {
  color: #fff;
  padding: 0px 0px 0px 0px;
}

.appreciation .datee {
  font-size: 14px;
  margin-bottom: 10px;
}

.appreciation p {
  font-weight: 600;
  padding: 10px 0px;
  line-height: 1.5em;
  font-size: 18px;
}

.text-center {
  text-align: center;
}

.bg-darkk {
  background-color: rgb(30 30 30 / 90%);
}

.ticker-wrap .ticker {
  display: flex;
  box-sizing: content-box;
}

div#lotie {
  /*background-color: #7460ee;*/
  border-radius: 4px;
  background-image: linear-gradient(180deg,
      rgba(92, 107, 192, 0.71),
      rgba(92, 107, 192, 0.71));
  width: 200px;
  height: 200px;
}

.ticker-wrap .ticker {
  display: flex;
  box-sizing: content-box;
}

.coaching_history_image:hover {
  cursor: pointer;
}

.ticker:hover {
  cursor: pointer;
}

.icPlay:hover {
  cursor: pointer;
}

.ticker-wrap .ticker__item {
  height: 60px;
  max-width: 220px;
  min-width: 220px;
  font-weight: 700;
  text-transform: uppercase;
  padding: 8px 24px;
  border-right: 1px solid #dfe3eb;
}

.marquee {
  width: 100%;
  overflow: hidden;
}

.marquee-container {
  width: 100%;
  overflow: hidden;
  white-space: nowrap;
  box-sizing: border-box;
  position: relative;
}


.scorecard_circle {
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
}

.scorecard_circle_center {
  width: 10px;
  height: 10px;
  border-radius: 10px;
  /* stroke-width: 9px; */
  background-color: #fff;
  position: absolute;
}

.activity-tracker {
  position: relative;
  width: 150px;
  height: 157px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.ring {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.ring-bg {
  fill: none;
  stroke: rgba(255, 255, 255, 0.25);
  stroke-width: 3;
}

.ring-progress {
  fill: none;
  stroke-width: 3;
  stroke-linecap: round;
  transform: rotate(-90deg);
  transform-origin: 50% 50%;
  transition: stroke-dasharray 0.6s ease-out, stroke-dashoffset 0.6s ease-out;
}

.score_val {
  font-size: 34px;
  font-weight: 700;
  /* line-height: 2; */
}

.score_range {
  font-size: 18px;
  font-weight: 700;
  /* line-height: 1; */
}

.score_progress {
  opacity: 0.6;
  white-space: nowrap;
  /* line-height: 1; */
}

.scorecard_data {
  padding-left: 10px;
}

.show_more_options {
  position: absolute;
  bottom: 0;
  right: 0;
}

.show_more_options {
  position: absolute;
  bottom: 10px;
  right: 10px;
  opacity: 0.6;
}

.show_more_options:hover {
  opacity: 1;
}

.show_more_options .commit-tooltip {
  padding: 0;
  font-size: 14px;
}

.show_more_options .commit-tooltip li:hover {
  background-color: rgba(0, 0, 0, 0.08);
}

.edit_profile {
  opacity: 0.6;
  cursor: pointer;
  top: 0px;
  position: absolute;
  right: 0px;
}

.edit_profile:hover {
  background-color: #000;
  opacity: 1;
  border-radius: 20px;
}

#edit_profile_modal .modal-dialog {
  max-width: 90%;
}

.question_icon.tool .commit-tooltip {
  position: absolute;
  top: 0px;
  /* min-width: 20px !important; */
  /* width: auto; */
  /* max-width: 1000px; */
  background-color: rgba(39, 41, 51, 0.95);
  color: #fff;
  padding: 12px 15px;
  white-space: nowrap;
  border: none;
  cursor: pointer;
  left: 0px;
  /*display: block;*/
  font-weight: 600;
  z-index: 1;
  border-radius: 3px;
  min-width: 200px;
  max-width: 200px;
  word-break: break-word;
  white-space: normal;
}

.question_icon.tool {
  background-color: rgba(55, 61, 75, 0.13);
  width: 16px;
  height: 16px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 12px;
  border-radius: 16px;
  margin-left: 10px;
  position: relative;
  font-weight: 600;
}

.question_icon:hover {
  color: #fff;
  background-color: #ff5722 !important;
}

#edit_profile_modal .modal-header>div {
  font-size: 18px;
  font-weight: 600;
}

.question_icon .commit-tooltip>div {
  /* opacity: 0.6;*/
  margin-bottom: 5px;
}

#background-pills-tab {
  background-color: #f9fafb;
  border: 1px solid #eaecf0;
  padding: 4px;
  font-size: 12px;
  margin-left: 15px;
  max-width: 335px;
  border-radius: 10px;
  flex-wrap: nowrap;
}

#v-pills-tab {
  background-color: #f9fafb;
  border: 1px solid #eaecf0;
  padding: 4px;
  font-size: 12px;
  margin-left: 15px;
  max-width: 335px;
  border-radius: 10px;
  flex-wrap: nowrap;
}

#v-pills-badge-tab {
  background-color: #f9fafb;
  border: 1px solid #eaecf0;
  padding: 4px;
  font-size: 12px;
  margin-left: 15px;
  max-width: 335px;
  border-radius: 10px;
  flex-wrap: nowrap;
}

#background-pills-tab .nav-link.active {
  box-shadow: 0 0px 5px #ccc;
  padding: 8px 15px;
  background-color: #fff;
  color: #323441;
  font-weight: 600;
  font-size: 14px;
  width: 120px;
  opacity: 1;
}

#v-pills-tab .nav-link.active {
  box-shadow: 0 0px 5px #ccc;
  padding: 8px 15px;
  background-color: #fff;
  color: #323441;
  font-weight: 600;
  font-size: 14px;
  width: 150px;
  opacity: 1;
}

#v-pills-badge-tab .nav-link.active {
  box-shadow: 0 0px 5px #ccc;
  padding: 8px 15px;
  background-color: #fff;
  color: #323441;
  font-weight: 600;
  font-size: 14px;
  width: 150px;
  opacity: 1;
}

#background-pills-tab .nav-link {
  padding: 8px 15px;
  opacity: 0.6;
  color: #323441;
  font-weight: 600;
  font-size: 14px;
  width: 150px;
  margin: 0px;
  white-space: nowrap !important;
}

#background-pills-tab li {
  padding: 0;
  margin: 0;
}

#background-pills-tab .nav-link:hover {
  background-color: rgba(0, 0, 0, 0.07);
}

#background-pills-tab .nav-link.active:hover {
  background-color: #fff;
}

#v-pills-tab .nav-link {
  padding: 8px 15px;
  opacity: 0.6;
  color: #323441;
  font-weight: 600;
  font-size: 14px;
  width: 150px;
  text-align: left;
  margin: 0px 5px 5px 5px;
}

#v-pills-tab .nav-link:hover {
  background-color: rgba(0, 0, 0, 0.07);
}

#v-pills-tab .nav-link.active:hover {
  background-color: #fff;
}

#v-pills-badge-tab .nav-link {
  padding: 8px 15px;
  opacity: 0.6;
  color: #323441;
  font-weight: 600;
  font-size: 14px;
  width: 150px;
  text-align: left;
  margin: 0px 5px 5px 5px;
}

#v-pills-badge-tab .nav-link:hover {
  background-color: rgba(0, 0, 0, 0.07);
}

#v-pills-badge-tab .nav-link.active:hover {
  background-color: #fff;
}

.modal_close {
  background-color: #dfe3eb;
  border-radius: 5px;
  padding: 13px 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  pointer-events: auto;
  border: 0px;
}

.modal_close:hover {
  background-color: #22c6ab;
}

.modal_close img {
  width: 18px;
  height: 18px;
}

.modal-footer button .btn.btn-secondary {
  background-color: #fff;
  border: 1px solid #d0d5dd;
  border-radius: 10px;
  padding: 10px 15px;
  color: #323441;
}

.modal-footer button.btn.btn-primary {
  background-color: #22c6ab;
  border-color: #22c6ab;
  padding: 10px 15px;
  border-radius: 10px;
}

.modal-footer .btn {
  font-weight: 600;
}

.agent-bg img {
  width: 240px;
  height: 150px;
  border-radius: 5px;
  background-color: #f9fafb;
  object-fit: cover;
  aspect-ratio: 16/9;
}

.agent-bg {
  margin: 0px 15px 15px 0px;
  padding: 5px;
  border: 3px solid transparent;
  cursor: pointer;
  pointer-events: auto;
}

.agent-bg:hover {
  padding: 5px 5px 5px 5px;
  border: 3px solid #22c6ab;
  margin: 0px 15px 15px 0px;
  border-radius: 5px;
}

.agent-bg.bgselected {
  padding: 5px 5px 5px 5px;
  border: 3px solid #22c6ab;
  margin: 0px 15px 15px 0px;
  border-radius: 5px;
}

.modal-footer .btn {
  font-size: 14px !important;
  padding: 10px 14px !important;
  border-radius: 8px;
  box-shadow: 0 1px 2px rgba(16, 24, 40, 0.05);
}

.modal-footer .btn-secondary {
  background-color: #fff !important;
  border: 1px solid #d0d5dd !important;
  color: #323441;
}

.modal-footer .btn-primary {
  background-color: #22c7a9 !important;
  border-color: #e2e1e1 !important;
}

.modal-footer .btn-primary:hover {
  background-color: #0c6857 !important;
}

.modal-footer .btn-secondary:hover {
  background-color: #e2e1e1 !important;
}

div#pills-tabContent {
  height: 68vh;
  overflow-y: auto;
  overflow-x: hidden;
}

.pill-badge img {
  width: 20px;
  height: 20px;
  margin-right: 5px;
}

#v-pills-badge-tab .nav-link {
  text-align: left;
  color: #667085 !important;
  font-size: 14px;
  font-weight: 600;
  margin: 2px;
}

.pill-badge-icon .pill-badge img {
  width: 50px;
  height: 50px;
  border: 3px solid transparent;
  border-radius: 8px;
  margin: 0px 5px 5px 0px;
  padding: 5px;
}

.pill-badge-icon .pill-badge img:hover {
  cursor: pointer;
  border: 3px solid #22c7a9;
  border-radius: 8px;
}

.pill-badge-icon .selected img {
  cursor: pointer;
  border: 3px solid #22c7a9;
  border-radius: 8px;
}

.show_more_options .commit-tooltip {
  top: auto;
  right: 0;
  bottom: 0;
  opacity: 1;
  z-index: 9;
}

#balancescorecard_modal .modal-dialog {
  max-width: 90% !important;
}

.modal-content .btn-bordered {
  color: #272933;
  background-color: rgba(255, 255, 255, 0);
  border: 1px solid rgba(0, 0, 0, 0.11);
  border-radius: 2px;
  margin-right: 1em;
  padding: 10px;
  font-weight: 600;
}

.modal-content .btn-bordered:hover {
  background-color: rgba(0, 0, 0, 0.11);
}

#balancescorecard_modal .card-header {
  background-color: #fff;
  font-weight: 600;
  border-bottom: none !important;
}

#balancescorecarddetails .card-header {
  height: 50px;
  display: flex;
  justify-content: start;
  align-items: center;
  border-bottom: 1px solid #dfe3eb !important;
}

.highcharts-button.highcharts-contextbutton.highcharts-button-normal {
  display: none;
}

.trend_chart .card-header {
  height: 50px;
  display: flex;
  justify-content: start;
  align-items: center;
}

#balancescorecard_modal .card-body {
  padding: 0;
}

#balancescorecard_modal .table {
  margin: 0;
}

#balancescorecard_modal .display-block {
  width: 65%;
  padding-right: 15px;
}

.scorecard_ranking {
  width: 35%;
}

.score_profile {
  margin-right: 5px;
}

.score_profile img {
  width: 35px;
  height: 35px;
  border-radius: 35px;
}

.score_rank {
  background-color: #dfe3eb;
  border: 1px solid #cbd6e2;
  padding: 0px 5px;
  margin-right: 5px;
  font-size: 12px;
  position: absolute;
  top: 0;
  left: 0;
  border-radius: 0 0 3px;
}

.table thead tr th:first-child {
  text-align: left;
}

.table tbody tr td:first-child {
  text-align: left;
}

.table tbody tr td {
  text-align: center;
}

.scorecard_ranking .table tbody tr td:first-child {
  border-left: 5px solid;
  border-right: 0px;
}

.scorecard_ranking .table td {
  padding: 8px 20px !important;
  position: relative;
}

.scorecard_ranking .dataTables_wrapper tbody tr td {
  font-weight: 600 !important;
}

/* multi month*/
.scorecard_type i.fa.fa-circle {
  color: #dee2e6;
  margin-right: 3px;
  font-size: 5px;
}

.scorecard_type>.d-flex {
  line-height: 12px;
}

.show_scorecards {
  position: absolute;
  right: 0;
  white-space: nowrap;
  background: #fff;
  font-size: 12px;
  border-radius: 3px;
  box-shadow: 0px 0px 5px #ccc;
  padding: 10px;
  /* top:23px;*/
}

.show_scorecards ul {
  padding: 0;
  margin: 0;
  list-style: none;
}

.show_scorecards {
  display: none;
}

.show_scorecards ul li {
  padding: 3px 5px;
}

.show_scorecards ul li:hover {
  background-color: #e9e8f3;
  border-radius: 3px;
}

.scorecard_type:hover .show_scorecards {
  display: block !important;
}

#multimonth_scorecardmodal h3 {
  text-align: center;
  font-size: 13px;
  padding: 15px 0px;
  font-weight: normal;
  margin: 0;
}

#multimonth_scorecardmodal .monthly_scorecard .table thead th {
  text-align: center;
}

#multimonth_scorecardmodal .modal-content {
  border: none;
  border-radius: 5px !important;
}

#multimonth_scorecardmodal .modal-dialog {
  max-width: 38%;
}

#multimonth_scorecardmodal .modal-footer {
  border-top: 1px solid rgb(248 248 248) !important;
}

.start_end_time .table th {
  font-size: 13px;
  text-transform: capitalize;
  padding: 15px 20px 20px 20px !important;
  text-align: left;
  border-bottom: 1px solid rgb(240 240 240) !important;
}

.start_end_time .table td {
  padding: 8px 20px !important;
  border-right: 1px solid #dfe3eb !important;
  border-bottom: 0px solid transparent !important;
}

.start_end_time .table {
  margin: 0;
  box-shadow: none;
  border-radius: 0;
  border-top: 3px solid #e8e8e8 !important;
  border-bottom: none !important;
}

.start_end_time select.form-control {
  font-size: 14px;
  height: 36px !important;
  border-radius: 5px;
  color: #323441;
  text-align: center;
  padding: 5px;
  font-weight: normal;
  border-color: #dee2e6;
}

#multimonth_scorecardmodal button.btn.btn-bordered {
  font-size: 13px;
  margin-right: 1em;
  font-weight: 600;
  background: #fff;
  border: 1px solid #c7cdd5;
  padding: 5px 20px;
  border-radius: 10px !important;
}

#multimonth_scorecardmodal button.btn.btn-primary {
  padding: 5px 20px;
  font-size: 13px;
  border-color: #c7cdd5;
  background: #c7cdd5;
  font-weight: 600;
  color: #323441;
}

#multimonth_scorecardmodal button.btn.btn-primary:hover {
  color: #fff !important;
}

select {
  appearance: none;
}

.monthly_scorecard img {
  width: 20px;
  height: 20px;
}

.modal_top {
  background: #262933;
  color: #fff;
  font-weight: normal;
  padding: 12px 15px;
  text-transform: uppercase;
}

.module_2 {
  background: #f6f8fa;
  padding: 20px 20px;
}

.agent_scorecard_details h3 {
  font-size: 14px;
  white-space: nowrap;
  font-weight: 600;
}

.agent_details_block {
  padding: 20px 15px 15px 15px;
  border: 1px solid #e4e7eb;
  border-radius: 5px;
  margin: 15px 0px 0px 0px;
  white-space: nowrap;
}

.agent_name {
  font-weight: 600;
}

.agent_detail {
  font-size: 13px;
  font-weight: normal;
  padding: 2px 0px 0px 0px;
}

.agent_details_block .mb-1 {
  margin-bottom: 15px !important;
}

.module_2 .agent_scorecard_details {
  max-width: 100%;
}

.right_block_top_module button.btn.btn-primary {
  background: #30c5ab;
  border-color: #30c5ab;
  font-size: 13px;
  border-radius: 5px;
}

#monthly_scorecard_weights .modal-content {
  height: 100vh;
  overflow-y: auto;
  overflow-x: hidden;
}

#monthly_scorecard_weights .modal-footer {
  width: 100%;
  height: 67px;
  background: rgb(238, 238, 238);
  position: sticky;
  bottom: 0;
  z-index: 9;
}

.monthly_scorecard th:first-child {
  width: 50%;
}

.monthly_scorecard th {
  text-transform: uppercase;
  font-weight: 600;
}

.monthly_scorecard tbody {
  background-color: transparent;
}

#monthly_scorecard_weights .modal-footer button.btn.btn-primary {
  background: #ccd6e2;
  border-color: #ccd6e2;
  color: #323441;
  font-weight: 600;
}

#monthly_scorecard_weights .modal-content {
  background: #f6f8fa;
  border-radius: 0 !important;
  border: 0;
}

#monthly_scorecard_weights .modal-dialog {
  margin: 0 auto;
  max-width: 95%;
}

.monthly_scorecard .table {
  border-radius: 5px;
}

.monthly_scorecard .table tbody {
  background: #fff;
}

.monthly_scorecard .table tbody td {
  border-bottom: 1px solid #f6f6f6 !important;
  text-align: left;
  font-size: 14px;
  font-weight: normal;
}

.monthly_scorecard .table thead th {
  border-right-width: 0px !important;
  text-transform: uppercase;
  text-align: left;
  font-weight: 600;
  white-space: nowrap;
  font-size: 13px;
}

.monthly_scorecard .btn-primary {
  font-size: 12px !important;
  padding: 3px 12px;
  border-radius: 3px !important;
}

.mr-5 {
  margin-right: 5px !important;
}

.top_module {
  height: 45px;
}

.multiple_score_red {
  background: #fff6f8;
}

.multiple_score_green {
  background: #f4faf6;
}

#edit_monthly_weights_modal {
  background: rgb(0 0 0 / 74%);
}

#edit_monthly_weights_modal .dataTables_scrollBody .table {
  margin-top: -32px !important;
}

#edit_monthly_weights_modal h3 {
  font-size: 0.8rem;
  text-align: center;
  margin-bottom: 15px;
}

#edit_monthly_weights_modal .modal-content {
  padding: 15px 0px 0px 0px;
  border-radius: 5px !important;
  box-shadow: 0px 0px 5px #000;
}

#edit_monthly_weights_modal .monthly_scorecard .table {
  box-shadow: none;
  margin: 0;
  border-top: 2px solid #c7cdd5 !important;
}

#edit_monthly_weights_modal .monthly_scorecard .table th {
  white-space: nowrap;
  font-weight: bold;
  border-right: 1px solid #dfe3eb !important;
  border-top: 1px solid #dfe3eb !important;
  border-bottom: 1px solid #f6f6f6 !important;
}

#edit_monthly_weights_modal .monthly_scorecard .table td {
  border-right: 1px solid #dfe3eb !important;
  font-size: 0.9rem;
  padding: 5px 20px !important;
  letter-spacing: 0.3px;
  white-space: nowrap;
}

#edit_monthly_weights_modal .col-md-12 {
  padding: 0;
}

#edit_monthly_weights_modal .modal-dialog {
  max-width: 70%;
  display: block;
}

#edit_monthly_weights_modal .modal-footer {
  border-top: 0 !important;
}

#edit_monthly_weights_modal .btn-primary {
  font-size: 12px !important;
  padding: 3px 12px;
  border-radius: 3px !important;
}

button.btn.btn-primary.btn-edit-weightage {
  background-color: #ffbcbb;
  border-color: #ffbcbb;
  color: #323441;
  font-weight: 600;
}

#edit_monthly_weights_modal .table .form-control {
  height: 28px;
  font-size: 0.9rem;
}

#edit_monthly_weights_modal input::-webkit-input-placeholder {
  color: #323441;
  text-align: center;
}

#edit_monthly_weights_modal input::-moz-placeholder {
  color: #323441;
  text-align: center;
}

#edit_monthly_weights_modal input::-ms-input-placeholder {
  color: #323441;
  text-align: center;
}

#edit_monthly_weights_modal button.btn.btn-primary {
  padding: 5px 15px;
  font-size: 13px !important;
  border-color: #ccd6e2;
  background: #ccd6e2;
  font-weight: 600;
  color: #323441;
  margin-left: 0px;
}

#edit_monthly_weights_modal button.btn.btn-bordered {
  padding: 5px 15px;
  font-size: 13px !important;
  border: 1px solid #ccc;
  background: none;
  font-weight: 600;
  color: #323441;
  margin-left: 15px;
  width: auto;
  height: auto;
}

/* #edit_monthly_weights_modal .table tr:nth-child(even) {
        border-bottom: 2px solid #c7cdd5 !important;
    }
*/
#edit_profile_modal .table td {
  white-space: nowrap;
}

.monthly_weight_status_bg {
  background: #fadbd8;
  border-radius: 20px;
  text-align: center;
  padding: 1px 5px;
  width: 60%;
  font-size: 0.9rem;
  font-weight: 600;
  box-shadow: 0px 0px 1px #cf445c;
}

.monthly_weight_status_bg_green {
  background: #ceeed9;
  border-radius: 20px;
  text-align: center;
  padding: 1px 5px;
  width: 60%;
  font-size: 0.9rem;
  font-weight: 600;
  box-shadow: 0px 0px 1px #ceeed9;
}

.alert-warning {
  color: #323441;
  background-color: #fadbd8;
  border-color: #88a38e;
  border-radius: 3px;
  text-align: center;
  margin: 8% 30%;
  font-size: 13px;
  padding: 12px 5px;
}

button.btn-primary.btn-edit-weightage:hover {
  background-color: #ffbcbb !important;
  border-color: #ffbcbb !important;
}

.btn-export {
  padding: 5px 15px;
  font-size: 13px !important;
  border-color: #22c7a9;
  background: #22c7a9;
  font-weight: 600;
  color: #fff;
  margin-left: 15px;
}

#monthly_scorecard_weights .btn-close {
  background: #ccd6e2;
  border-color: #ccd6e2;
  color: #323441;
  font-weight: 600;
  font-size: 14px !important;
  width: auto;
  height: auto;
  opacity: 1;
}

.mulitple_scorecard .mr-5 {
  display: flex;
  justify-content: center;
  align-items: center;
}

table.dataTable thead .sorting:before,
table.dataTable thead .sorting_asc:before,
table.dataTable thead .sorting_desc:before,
table.dataTable thead .sorting_asc_disabled:before,
table.dataTable thead .sorting_desc_disabled:before {
  font-size: 10px !important;
}

table.dataTable thead .sorting:after,
table.dataTable thead .sorting_asc:after,
table.dataTable thead .sorting_desc:after,
table.dataTable thead .sorting_asc_disabled:after,
table.dataTable thead .sorting_desc_disabled:after {
  font-size: 10px !important;
}

th.sorting::after,
th.sorting::before {
  color: #6c757d;
}

table.dataTable thead>tr>th.sorting:hover {
  background-color: #e5eff8;
}

table.dataTable thead>tr>th.sorting_asc {
  background-color: #e5eff8 !important;
}

table.dataTable thead>tr>th.sorting_desc {
  background-color: #e5eff8;
}

table.dataTable thead>tr>th.sorting_disabled {
  background-color: #f5f8fa;
}

table.dataTable thead>tr>th.sorting_disabled::before,
table.dataTable thead>tr>th.sorting_disabled::after {
  display: none;
}

th.sorting_asc::after {
  color: #22c6ab;
  content: "\f0de" !important;
  font-family: FontAwesome;
  font-size: 10px;
  font-weight: normal;
}

th.sorting_asc::before {
  color: #6c757d;
  content: "\f0dd" !important;
  font-family: FontAwesome;
  font-size: 10px;
  font-weight: normal;
}

table.dataTable.nowrap td {
  white-space: nowrap !important;
  text-transform: capitalize !important;
  vertical-align: middle;
  font-weight: 600;
  padding: 5px !important;
}

th.sorting_desc::after {
  color: #6c757d;
  content: "\f0dd" !important;
  font-family: FontAwesome;
  font-size: 10px;
  font-weight: normal;
}

th.sorting_desc::before {
  color: #22c6ab;
  content: "\f0de" !important;
  font-family: FontAwesome;
  font-size: 10px;
  font-weight: normal;
}

table.dataTable thead .sorting:after,
table.dataTable thead .sorting_asc:after,
table.dataTable thead .sorting_desc:after,
table.dataTable thead .sorting_asc_disabled:after,
table.dataTable thead .sorting_desc_disabled:after {
  right: 10px !important;
  font-size: 10px;
  font-weight: normal;
}

table.dataTable thead .sorting:before,
table.dataTable thead .sorting_asc:before,
table.dataTable thead .sorting_desc:before,
table.dataTable thead .sorting_asc_disabled:before,
table.dataTable thead .sorting_desc_disabled:before {
  content: "\f0dd" !important;
  font-family: FontAwesome;
  font-size: 10px;
  font-weight: normal;
}

table.dataTable thead .sorting:after,
table.dataTable thead .sorting_asc:after,
table.dataTable thead .sorting_desc:after,
table.dataTable thead .sorting_asc_disabled:after,
table.dataTable thead .sorting_desc_disabled:after {
  content: "\f0de" !important;
  font-family: "FontAwesome" !important;
  font-size: 10px;
  font-weight: normal;
}

table.dataTable thead .sorting:before,
table.dataTable thead .sorting:after,
table.dataTable thead .sorting_asc:before,
table.dataTable thead .sorting_asc:after,
table.dataTable thead .sorting_desc:before,
table.dataTable thead .sorting_desc:after,
table.dataTable thead .sorting_asc_disabled:before,
table.dataTable thead .sorting_asc_disabled:after,
table.dataTable thead .sorting_desc_disabled:before,
table.dataTable thead .sorting_desc_disabled:after {
  opacity: 1.3 !important;
  bottom: 15px;
}

/* metric commitment block*/
.metric_commitment_block {
  display: flex;
  justify-content: space-between;
  align-items: start;
  padding: 30px 30px 50px 30px;
}

.metric_commitment_block .card-header {
  background-color: #fff;
  min-height: 40px;
}

.metric_commitment_block .card-body {
  padding: 0;
}

.min-hei-40 {
  min-height: 40px;
}

.metric_block,
.commitment_learning_block {
  width: 100%;
}

.metric_block {
  padding-right: 15px;
}

.metric_commitment_block .card {
  min-width: 100%;
  margin: 0;
}

.metric_commitment_block .card-body .table {
  margin: 0 !important;
}

.metric_table {
  min-height: 70vh;
  overflow: auto;
}

.metrics_block {
  width: 100%;
  background-color: #fff;
}

@media screen and (max-width: 1279px) {
  .metrics_block {
    width: 100% !important;
  }

  .metric_commitment_block {
    display: block;
  }

  .agent_scorecard {
    width: 50%;
  }

  .commitment_learning_block {
    width: 100% !important;
    margin-left: 0px;
    margin-top: 1em;
  }
}

@media screen and (min-width: 1280px) {
  .metrics_block {
    width: 50% !important;
  }

  .metric_commitment_block {
    display: flex;
  }

  .commitment_learning_block {
    width: 50% !important;
  }
}

.metrics_block .gray-text {
  color: #667085;
  white-space: nowrap;
  font-size: 13px;
  font-weight: 400;
  padding-left: 10px;
}

.metric_title b {
  font-size: 14px;
  font-weight: 600;
}

.transparent_bg {
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  background-color: rgba(255, 255, 255, 0.75);
}

.performance-bar {
  height: 4px;
  /* width: 100%; */
  /* background-color: #000; */
  border-radius: 20px;
}

.performance-bar .regular {
  height: 4px;
}

.performance-bar .inverse {
  height: 4px;
}

.team.position-absolute {
  z-index: 1;
  width: 8px;
  height: 20px;
  border: 3px solid #fff;
  border-radius: 4px;
  background: #6ccc76;
  top: -7px;
}

.tean_icon {
  position: absolute;
  top: 16px;
  color: #fff;
  background-color: #6ccc76;
  border-radius: 20px;
  width: 20px;
  height: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  left: -7px;
  font-size: 12px;
  font-weight: bold;
}

.program.position-absolute {
  width: 8px;
  height: 38px;
  border: 3px solid #fff;
  border-radius: 4px;
  background: #6ccc76;
  top: -7px;
  z-index: 0;
}

.program_icon {
  position: absolute;
  top: 35px;
  color: #fff;
  background-color: #6ccc76;
  border-radius: 20px;
  width: 20px;
  height: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  left: -7px;
  font-size: 12px;
  font-weight: bold;
}

.agent.position-absolute {
  width: 8px;
  height: 20px;
  border: 3px solid #fff;
  border-radius: 4px;
  background: #6ccc76;
  top: -7px;
}

.agent_icon {
  position: absolute;
  bottom: 2px;
  color: #fff;
  background-color: #6ccc76;
  border-radius: 20px;
  width: 30px;
  height: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  left: -13px;
  font-size: 12px;
  font-weight: bold;
  letter-spacing: 1px;
}

.persona img {
  width: 12px;
  height: 12px;
}

.persona.position-absolute {
  background-image: linear-gradient(rgba(100, 181, 246, 0.45),
      rgba(100, 181, 246, 0.45));
  border-radius: 20px;
  outline: 2px solid #fff;
  left: calc(100% - 12px);
  /* right: -41px; */
  top: -8px;

  padding: 4px 4px;
  display: flex;
  justify-content: center;
  align-items: center;
  line-height: 5px;
  background-color: #fff;
}

.actual_goal .actual {
  font-size: 14px;
  font-weight: normal;
}

.actual_goal .goal {
  font-size: 12px;
}

.updated_through_dropdown {
  position: absolute;
  top: 18px;
  right: 0;
  background-color: #fff;
  z-index: 2;
  box-shadow: 1px 1px 12px rgba(0, 0, 0, 0.19);
  border-radius: 5px;
  display: none;
  min-width: 200px;
}

.updated_through_dropdown ul {
  margin: 0;
  padding: 0;
}

.updated_through_dropdown ul li {
  border-bottom: 1px solid rgba(0, 0, 0, 0.04);
  text-align: left;
}

.updated_through_dropdown ul li b {
  font-weight: 600;
  color: #323441 !important;
  font-size: 16px;
}

.updated_through_dropdown ul li .btn {
  background-color: #22c7a9 !important;
  border-color: #22c7a9 !important;
  padding: 7px 15px !important;
  width: 100%;
}

.ledaer .btn {
  font-size: 14px;
}

.updated_through:hover .updated_through_dropdown {
  display: block;
}

.updated_through {
  cursor: pointer;
}

.metric_table>.table tbody tr {
  height: 100px;
}

#leaderboard_modal .modal-dialog {
  max-width: 90%;
}

.hover_description b {
  color: #fff;
  font-weight: 600;
}

.hover_description a {
  color: #fff !important;
  padding-top: 5px;
}

.timeframe_lable {
  top: -9px;
  left: 15px;
  color: #344054;
  font-weight: normal;
  position: absolute;
  font-size: 11px;
  background-color: #fff;
  z-index: 1;
}

#leaderboard_modal #background-pills-tab {
  padding-top: 0;
  padding-bottom: 0;
}

#leaderboard_modal .time_frame_block {
  padding-bottom: 0px !important;
  margin-left: 15px;
}

.links_right {
  display: flex;
  justify-content: flex-end;
  align-items: center;
}

button.btn.btn-coach-modal {
  background-color: #ffe79d;
  font-weight: 600;
  padding: 10px 15px;
  margin-right: 1em;
  font-size: 14px;
}

#leaderboard_modal .btn-bordered {
  font-size: 14px;
  padding: 10px 15px !important;
}

.leader_board_details {
  display: flex;
  justify-content: space-between;
  align-items: start;
}

#pills-leaderboard .team_program_rank {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: start;
  margin-bottom: 20px;
}

#pills-leaderboard .leader_board_progress {
  width: 60%;
  padding: 30px;
}

#pills-leaderboard .team_program_rank .team_rank_tile {
  width: 50%;
  margin-right: 15px;
  background-color: #fff;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.05);
  padding: 15px;
  line-height: 27px;
  font-size: 14px;
  border-radius: 4px;
  border: 1px solid #dfe3eb;
}

#pills-leaderboard b {
  font-weight: 600;
}

#pills-leaderboard .team_program_rank .program_rank_tile {
  width: 50%;
  background-color: #fff;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.05);
  padding: 15px;
  border: 1px solid #dfe3eb;
  line-height: 27px;
  font-size: 14px;
  border-radius: 4px;
}

#pills-leaderboard .games_won .card-header {
  background-color: #fff;
  font-size: 14px;
  height: 65px;
}

.games_won_table .game_img {
  width: 30px;
  height: 30px;
  background-color: rgba(213, 217, 224, 0.64);
  border-radius: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.games_won_table .game_img img {
  width: 20px;
  height: 20px;
}

.games_won_table .game_name {
  white-space: nowrap;
  margin-left: 10px;
}

.games_won_table .table td,
th {
  font-size: 14px;
}

.games_won_table .table td:last-child {
  text-transform: uppercase;
  font-weight: normal;
}

.games_won_table .game_name a {
  color: #0e7e97;
  text-decoration: none !important;
}

.games_won_table a:hover {
  text-decoration: underline;
}


#games_won_table_wrapper .dataTables_scrollHeadInner {
  padding-right: 0px !important;
}

#games_won_table_wrapper .table th:first-child {
  padding-left: 60px !important;
}

#games_won_table_wrapper th:last-child {
  text-align: left !important;
}

/*#leaderboard_modal .games_won #games_won_table_wrapper .dataTables_scrollHeadInner table.dataTable thead .sorting:before, table.dataTable thead .sorting:after, table.dataTable thead .sorting_asc:before, table.dataTable thead .sorting_asc:after, table.dataTable thead .sorting_desc:before, table.dataTable thead .sorting_desc:after, table.dataTable thead .sorting_asc_disabled:before, table.dataTable thead .sorting_asc_disabled:after, table.dataTable thead .sorting_desc_disabled:before, table.dataTable thead .sorting_desc_disabled:after {
    bottom: 10px !important;
}*/
a.previous {
  color: #949494;
  padding: 4px 8px;
  border: 1px solid transparent;
  border-radius: 3px;
  font-size: 13px;
}

a.paginate_button.current {
  border: 1px solid #0e7e97 !important;
  background-color: rgba(34, 198, 171, 0.17) !important;
  color: #0e7e97 !important;
  padding: 4px 8px !important;
  font-weight: 700 !important;
  border-radius: 3px !important;
}

a.text-secondary {
  text-decoration: underline;
  cursor: pointer;
  color: #5c6bc0 !important;
}

a.paginate_button {
  padding: 4px 8px !important;
  color: #0e7e97 !important;
}

a.paginate_button.disabled {
  color: #949494 !important;
  padding: 4px 8px !important;
  border: 1px solid transparent !important;
  border-radius: 3px !important;
  font-size: 13px !important;
}

div.dataTables_wrapper div.dataTables_paginate {
  text-align: center;
}

div.dataTables_wrapper div.dataTables_paginate ul.pagination {
  margin: 2px 0;
  font-size: 13px !important;
  display: flex;
  justify-content: center !important;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
}

.page-item.disabled .page-link {
  color: #949494 !important;
  border: 0;
}

.page-link:not(:disabled):not(.disabled),
summary {
  cursor: pointer;
  color: cadetblue;
}

.page-link {
  border: 0px !important;
  padding: 4px 8px !important;
  margin-left: 0px !important;
}

.page-item.active .page-link {
  border: 1px solid #0e7e97 !important;
  background-color: rgba(34, 198, 171, 0.17);
  color: #0e7e97;
  font-weight: 700;
  border-radius: 3px;
  font-size: 13px;
  padding: 4px 8px;
}

.page-link:hover {
  border: 1px solid #0e7e97 !important;
  background-color: rgba(34, 198, 171, 0.17);
  color: #0e7e97;
  font-weight: 700;
  border-radius: 3px;
  font-size: 13px;
  padding: 4px 8px;
}

.m-l-0 {
  margin-left: 0px !important;
}

div.dataTables_wrapper div.dataTables_paginate {
  padding: 16px 0px;
}

li.paginate_button.page-item a {
  border: 1px solid transparent !important;
}

li.paginate_button.page-item a:hover {
  border: 1px solid cadetblue !important;
}

.page-item a {
  border: 1px solid transparent !important;
}

.page-item a:hover {
  border: 1px solid cadetblue !important;
}

.leader_board_progress {
  background-color: #f9fafb;
  border: 1px solid #e9e9e9;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.05);
  border-radius: 4px;
}

.team_rank_description {
  font-size: 14px;
  line-height: 2em;
  margin-bottom: 1em;
}

.team_rank_description h3 {
  font-size: 14px;
  margin: 0;
}

.top_rank {
  background-color: #fff;
  border-radius: 4px;
  padding: 10px 20px;
  box-shadow: 0 2px 15px rgba(0, 0, 0, 0.1);
  margin-bottom: 1em;
}

.top_rank-in-tbl {
  background-color: #fff;
  border-radius: 4px;
  box-shadow: 0 2px 15px rgba(0, 0, 0, .1);
}

.ranked_person {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.top_rank .ranked_person {
  width: 40%;
  padding: 0px 0px;
}

.top_rank .rank_progress {
  width: 30%;
}

.current_progress {
  position: absolute;
  left: 0;
}

.rank_progress .position-relative {
  background-color: #e5e7eb;
  width: 100%;
  height: 10px;
  border-radius: 4px;
}

.rank_progress .position-absolute {
  background-color: red;
}

.rank_number>div {
  font-size: 12px;
  background-color: rgba(0, 0, 0, 0.05);
  border: 1px solid rgba(0, 0, 0, 0.16);
  border-radius: 4px;
  padding: 4px 8px;
  font-weight: bold;
  text-align: center;
  width: fit-content;
  min-width: 39px;
}

.top_rank .rank_number {
  width: 15%;
}

.top_rank .rank_value {
  width: 15%;
  text-align: center;
}

.modal_close:hover {
  cursor: pointer;
}

.first_last_name {
  background-color: rgba(213, 217, 224, 0.64);
  font-size: 12px;
  width: 30px;
  height: 30px;
  border-radius: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: 600;
  margin-right: 1em;
  letter-spacing: 1px;
}

.ranked_person_name {
  font-size: 14px;
  font-weight: 600;
  text-align: left;
}

.ranked_person_title {
  font-size: 12px;
  color: gray;
  text-align: left;
}

.rank_progress {
  width: 100%;
  height: 10px;
  border-radius: 4px;
  background-color: transparent;
}

.current_progress {
  border-radius: 10px;
  height: 10px;
}

#leaderboard_table_wrapper div.dataTables_scrollBody table {
  margin-top: -30px !important;
}

div#leaderboard_table_wrapper {
  padding: 0;
}

#leaderboard_table_wrapper .table thead tr th {
  background-color: transparent !important;
  border-right-color: transparent !important;
  border-bottom-width: 1px !important;
  text-align: left;
}

#leaderboard_table_wrapper .table tbody tr:hover {
  background-color: rgba(0, 0, 0, 0.02);
}

#leaderboard_table_wrapper .dataTables_scrollHeadInner .table thead tr th {
  background-color: transparent !important;
  border-right-color: transparent !important;
  border-top-color: transparent !important;
  border-bottom-color: transparent !important;
  padding: 15px 20px !important;
}

#leaderboard_table_wrapper .table tbody tr td {
  background-color: transparent;
  border-right-color: transparent !important;
}

#leaderboard_table_wrapper .table {
  border-color: transparent !important;
}

/*#leaderboard_table_wrapper .dataTables_scrollHeadInner table.dataTable thead .sorting:before, table.dataTable thead .sorting:after, table.dataTable thead .sorting_asc:before, table.dataTable thead .sorting_asc:after, table.dataTable thead .sorting_desc:before, table.dataTable thead .sorting_desc:after, table.dataTable thead .sorting_asc_disabled:before, table.dataTable thead .sorting_asc_disabled:after, table.dataTable thead .sorting_desc_disabled:before, table.dataTable thead .sorting_desc_disabled:after{
    bottom:10px !important;
}*/

.rank_value b {
  font-size: 1rem;
  font-weight: 700 !important;
}

tr.least {
  background-color: #fff !important;
  box-shadow: 0 2px 13px rgba(0, 0, 0, 0.09);
}

/* metric detail*/
#metric_detailmodal .btn {
  font-size: 14px !important;
  font-weight: 700;
  border: 1px solid rgba(0, 0, 0, 0.11);
  padding: 11px 16px;
  border-radius: 3px;
  margin-left: 16px;
  background: #fff;
  color: #272933;
}

#metric_detailmodal .disabled-link {
  font-size: 14px !important;
  font-weight: 700;
  border: 1px solid rgba(0, 0, 0, 0.11);
  padding: 11px 16px;
  border-radius: 3px;
  margin-left: 16px;
  color: #cccccc;
  background-color: #fff;
  cursor: default;
}

#metric_detailmodal .adn-link-btn:hover {
  outline-offset: 0px;
  outline: rgba(14, 126, 151, 0.33) solid 4px;
}

#metric_detailmodal .btn-coach {
  color: #272933;
  background-color: #ffe79d;
  border: none !important;
  height: 48px;
}

#metric_detailmodal .non-coachable-btn {
  color: #cccccc;
  background-color: #fff;
  border: none;
  height: 48px;
  border: 1px solid rgba(0, 0, 0, 0.11);
}

#metric_detailmodal .btn-close {
  width: 48px;
  height: 48px;
  cursor: pointer;
  background-color: #dfe3eb;
  border-radius: 5px;
  border: none !important;
}

#metric_detailmodal .btn-close:hover {
  background-color: #22c6ab;
}

#metric_detailmodal .btn-coach:hover {
  background-color: #e4d095;
  outline-offset: 0px;
  outline: rgba(14, 126, 151, 0.33) solid 4px;
}

.metricname {
  font-size: 18px;
  margin: 10px 0px;
  font-weight: 600;
}

#metric_detailmodal .modal-header {
  padding: 15px 20px;
  display: flex;
}

#metric_detailmodal .modal-body {
  padding: 15px 20px;
  min-height: 75vh;
}

#metric_detailmodal .form-control {
  border-radius: 3px;
  border: 1px solid #dfe3eb;
  height: 42px;
  font-size: 14px;
}

#metric_detailmodal .modal-dialog {
  max-width: 100% !important;
  margin: 23px 0px 0px 70px !important;
}

input.typeahead:focus-visible {
  outline: 2px solid black;
  border-radius: 3px;
}

/*input.typeahead::placeholder{
    color:#ccc;
}*/
.metric_details {
  display: flex;
  justify-content: flex-start;
  align-content: flex-start;
  align-items: center;
  flex-wrap: wrap;
  overflow: auto;
}

.metric_detail_card {
  min-width: 240px;
  width: 240px;
  cursor: pointer;
  pointer-events: auto;
  background-color: #fff;
  border: 1px solid #dfe3eb;
  border-radius: 4px;
  padding: 16px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.05);
  margin-right: 15px;
  margin-bottom: 15px;
  border: 1px solid #dfe3eb;
}

.metric_detail_card span {
  font-weight: 600;
  font-size: 15px;
}

.metric_detail_card.active {
  background-color: #f5f8fa;
  outline: 3px solid #22c6ab;
}

.metric_detail_card:hover {
  background-color: #f5f8fa;
  outline: 3px solid #22c6ab;
  /*outline:0;*/
}

.metric_details {
  padding: 15px 0px 0px 3px;
}

.metric_detail_card>div {
  font-weight: 600;
  margin-bottom: 10px;
  white-space: nowrap;
  color: #15161d;
  font-size: 14px;
}

.metric_detail_formula {
  width: 20px;
  height: 20px;
  border-radius: 20px;
  background: #e4e7eb;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
}

.metric_detail_formula .commit-tooltip {
  top: 15px;
  min-width: 20px !important;
  width: auto;
  max-width: 1000px;
  background-color: rgba(39, 41, 51, 0.95);
  color: #fff;
  padding: 12px 15px;
  white-space: nowrap;
  border: none;
  cursor: pointer;
  left: 20px;
}

.metric_detail_formula .commit-tooltip div {
  padding-top: 5px;
  text-transform: none;
}

.metric_detail_formula img {
  width: 12px;
  height: 12px;
}

.metric_trend_block h3 {
  font-size: 14px;
  font-weight: 600;
}

.metric_trend_block {
  background-color: #fff;
  border: 1px solid #e9e9e9;
  border-radius: 4px;
  padding: 15px;
  margin-bottom: 20px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.05);
}

.metric_detail_trend_block h3 {
  font-size: 14px;
  font-weight: 600;
}

.metric_detail_trend_block {
  background-color: #fff;
  border: 1px solid #e9e9e9;
  border-radius: 4px;
  padding: 15px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.05);
  border: 3px solid #22c6ab;
}

.pd-5 {
  padding-right: 5px;
}

.pr-5 {
  padding-right: 5px !important;
}

.metric_detail_formula heading {
  opacity: 0.7;
}

#metric_detailmodal .nav-pills .nav-link.active,
.nav-pills .show>.nav-link {
  border-bottom-color: transparent;
  background-color: transparent;
  color: #fff;
}

a#pills-list-tab img {
  opacity: 0.2;
}

a#pills-list-tab img:hover {
  opacity: 1;
}

a#pills-list-tab.active img {
  opacity: 1;
}

a#pills-grid-tab img {
  opacity: 0.2;
}

a#pills-grid-tab img:hover {
  opacity: 1;
}

a#pills-grid-tab.active img {
  opacity: 1;
}

#metric_detailmodal .table thead tr th {
  min-height: 48px;
  background-color: #f5f8fa;
  border-right: 1px solid #dfe3eb !important;
  border-top: 1px solid #dfe3eb !important;
  text-align: left;
}

#metric_detailmodal .table tbody tr td {
  font-size: 14px;
  text-align: left;
}

#metric_detailmodal .table {
  box-shadow: none;
}

#metric_detailmodal .table td:last-child {
  background-color: rgba(182, 197, 213, 0.24);
}

#metric_detailmodal .table tbody tr:hover {
  background-color: #f5f8fa;
}

#metric_detailmodal .table tbody tr:nth-child(even) {
  background-color: #f5f8fa;
}

#metric_detailmodal table.dataTable thead>tr>th.sorting_asc {
  background-color: inherit !important;
}

#list_view_wrapper {
  overflow-y: auto;
  overflow-x: hidden;
}

a#pills-list-tab img {
  opacity: 0.2;
}

a#pills-list-tab img:hover {
  opacity: 1;
}

a#pills-list-tab.active img {
  opacity: 1;
}

a#pills-grid-tab img {
  opacity: 0.2;
}

a#pills-grid-tab img:hover {
  opacity: 1;
}

a#pills-grid-tab.active img {
  opacity: 1;
}

#metric_detailmodal .table thead tr th {
  min-height: 48px;
  background-color: #f5f8fa;
  border-right: 1px solid #dfe3eb !important;
  border-top: 1px solid #dfe3eb !important;
}

#metric_detailmodal .table tbody tr td {
  font-size: 14px;
}

#metric_detailmodal .table {
  box-shadow: none;
}

#metric_detailmodal .table td:last-child {
  background-color: rgba(182, 197, 213, 0.24);
}

#metric_detailmodal .table tbody tr:hover {
  background-color: #f5f8fa;
}

#metric_detailmodal .table tbody tr:nth-child(even) {
  background-color: #f5f8fa;
}

#metric_detailmodal table.dataTable thead>tr>th.sorting_asc {
  background-color: inherit !important;
}

#list_view_wrapper {
  overflow-y: auto;
  overflow-x: hidden;
}

div#pills-grid.hid {
  display: none !important;
}

.metric_detail_graph {
  width: 50%;
  padding-left: 15px;
}

.metric_detail_search {
  width: 50%;
  padding-right: 15px;
}

.list_tile_view {
  padding-right: 15px;
}

.list_tile_view .nav-pills .nav-link.active,
.nav-pills .show>.nav-link {
  color: #fff;
  background-color: #eaecf0;
  cursor: pointer;
  pointer-events: auto;
}

.commitment_flow {
  height: 4px;
  background-color: rgba(55, 61, 75, 0.25);
}

.start-date-bubble {
  position: absolute;
  top: -3px;
  width: 10px;
  height: 10px;
  border-radius: 10px;
  background-color: #fff;
  border: 2px solid #373d4b;
  z-index: 1;
  margin-left: -10px;
}

.start-date-bubble:hover {
  box-shadow: 0 0 0 10px rgba(55, 61, 75, 0.24);
}

.end_date_bubble {
  position: absolute;
  right: -10px;
  top: -3px;
  width: 10px;
  height: 10px;
  border-radius: 12px;
  background-color: #cdced2;
  border: 2px solid #cdced2;
}

.end_date_bubble:hover {
  box-shadow: 0 0 0 10px rgba(205, 206, 210, 0.45);
}

.current_bubble {
  position: absolute;
  width: 10px;
  height: 10px;
  top: -3px;
  border-radius: 12px;
  outline: 2px solid #fff;
  /* border: 0px solid #fff; */
}

.below_commitment .current_bubble:hover {
  box-shadow: 0 0 0 10px rgba(239, 83, 80, 0.24);
}

.new_row {
  border-bottom: 1px solid rgb(223 227 235 / 100%);
}

.new_row:hover {
  background-color: #f5f8fa;
}

.metric_table tbody tr:hover {
  background-color: #f5f8fa;
}

.active_period_line {
  height: 4px;
  position: absolute;
  background-color: #373d4b;
}

.commit_start_date {
  color: #373d4b;
}

.commit_end_date {
  color: #373d4b;
}

.commit_dates b {
  font-weight: 600;
}

.commit_dates {
  padding-top: 7px;
}

.commit_actual {
  font-size: 12px;
  color: #9e9e9e;
  font-weight: 600;
}

.pt-5 {
  padding-top: 5px !important;
}

.commitment_table .actual {
  font-weight: normal;
  text-transform: capitalize;
}

.commitment_block {
  box-shadow: 0 0 4px rgba(0, 0, 0, 0.25);
  background-color: #fff;
  margin-bottom: 25px;
  min-height: 30vh;
  overflow: auto;
}

.metrics_block {
  margin-right: 7px;
  box-shadow: 0 0 4px rgba(0, 0, 0, 0.25);
  /* border-radius: 0.25rem; */
}

.learning_block {
  box-shadow: 0 0 4px rgba(0, 0, 0, 0.25);
  /* border-radius: 0.25rem; */
}

.commitment_learning_block {
  margin-left: 7px;
}

.coach_metricname a {
  color: #0e7e97 !important;
  text-decoration: none !important;
}

.coach_metricname a:hover {
  text-decoration: underline !important;
}

.start-date-bubble .commit-tooltip {
  background-color: #fff;
}

.commitment_flow .tool {
  z-index: 2;
  width: 20px;
  height: 20px;
}

.commitment_flow .commit-tooltip {
  top: -35px;
  min-width: 200px;
  width: auto;
}

.start-date-bubble .commit-tooltip {
  left: 10px;
}

.end_date_bubble .commit-tooltip {
  right: 0;
}

.commitment_flow .commit-tooltip li:first-child {
  margin: 0;
  border-top: 1px solid #dfe3eb;
  border-bottom: 1px solid #dfe3eb;
}

.commitment_flow .commit-tooltip li {
  margin: 0;
  border-bottom: 1px solid #dfe3eb;
}

.commitment_flow .commit-tooltip li:hover {
  background-color: transparent;
}

.commitment_flow .commit-tooltip .val {
  padding-left: 15px;
  white-space: nowrap;
}

.commitment_flow .commit-tooltip .val b {
  font-weight: bold;
}

.commitment_table .col {
  background-color: #f5f8fa;
  border-right: 1px solid #dfe3eb !important;
  border-bottom: 1px solid #dfe3eb !important;
  padding: 15px;
  text-transform: uppercase;
}

.w-25 {
  min-width: 25%;
  max-width: 25%;
}

.w-60 {
  min-width: 60%;
  max-width: 60%;
}

.w-15 {
  min-width: 15%;
  max-width: 15%;
}

.pd-15 {
  padding: 15px;
}

.trending_below_commitment {
  background-color: rgba(239, 83, 80, 0.1);
  padding: 5px 15px;
  border-radius: 4px;
  margin: 0px 15px 15px 15px;
  font-size: 12px;
}

.trending_below_commitment .red {
  font-weight: 600;
}

.trending img {
  width: 30px;
  margin-right: 5px;
}

.trending_below_commitment:hover {
  background-color: rgba(239, 83, 80, 0.2);
}

.trending_below_commitment .red {
  color: #d92d21;
  padding-right: 5px;
}

.coaching_audio {
  width: 100%;
  padding-right: 15px;
}

audio {
  width: 100%;
  font-size: 12px;
  padding: 6px;
}

.coaching_file_doc {
  padding-right: 15px;
  padding-bottom: 6px;
}

.w-45 {
  width: 45%;
}

#coaching_history_modal .modal-body {
  height: 80vh;
  overflow: auto;
}

#coaching_history_modal .modal-header .title {
  font-size: 18px;
  font-weight: 600;
}

#coaching_history_modal .modal-dialog {
  max-width: 85% !important;
}

.coached_fields .d-flex {
  flex-wrap: wrap;
}

.coached_fields {
  padding: 15px;
}

.coached_fields .form-group {
  margin-right: 30px;
  min-width: 220px;
  margin-bottom: 30dpx;
}

.coached_fields .form-group label {
  text-transform: capitalize;
  font-size: 14px;
}

.coached_fields .form-group input {
  font-size: 14px;
  border-radius: 2px;
}

.coached_fields input[readonly] {
  background-color: rgba(239, 239, 239, 0.3);
}

.reviews_learning a {
  color: #0e7e97 !important;
  text-decoration: none;
  font-weight: 600 !important;
}

.reviews_learning a:hover {
  text-decoration: underline;
}

.reviews_learning {
  padding: 0px 15px 15px 15px;
}

.reviews_learning a {
  font-weight: 600 !important;
  font-size: 14px;
}

.reviews_learning b {
  font-weight: 600 !important;
  font-size: 14px;
}

.notes h3 {
  border-bottom: 1px solid #000;
  font-size: 18px;
  padding-bottom: 15px;
}

.notes_fields textarea {
  font-size: 14px;
}

.notes {
  padding: 0px 15px 15px;
}

.commit-tooltip {
  font-size: 13px;
}

/*.commit-tooltip b{
    color:#fff;
}*/
.color-white {
  color: #fff;
}

table.dataTable {
  margin-top: -1px !important;
}

.notes_fields label {
  margin-bottom: 2px;
}

.time_frame_block .btn-bordered:hover {
  background-color: #d3d3d3;
}

.notes_fields .form-control {
  font-size: 12px;
  min-height: 40px;
  background-color: #f8f8f8;
  border-color: #cdcdcd;
  display: flex;
  justify-content: start;
  align-items: center;
}

.notes_fields {
  padding: 0px 15px 15px;
}

.notes_fields textarea {
  margin-bottom: 2em;
  min-height: 60px;
}

.above_commitment .current_bubble:hover {
  box-shadow: 0 0 0 10px rgba(108, 204, 118, 0.24);
}

.viewed_learning {
  display: flex;
  justify-content: start;
  align-items: start;
}

.new_learning {
  background-color: #6ccc76;
  background-image: linear-gradient(rgba(255, 255, 255, 0.9),
      rgba(255, 255, 255, 0.9));
  padding: 12px 25px;
  font-size: 14px;
  line-height: 34px;
  margin-right: 2em;
  font-weight: normal;
  cursor: pointer;
  pointer-events: auto;
  border-radius: 5px;
}

.new_learning:hover {
  background-color: #6ccc76;
  background-image: none;
}

.viewed {
  padding: 12px 25px;
  font-size: 14px;
  line-height: 34px;
  margin-right: 2em;
  font-weight: normal;
  color: #667085;
  cursor: pointer;
  pointer-events: auto;
  border-radius: 5px;
}

.viewed:hover {
  background-image: linear-gradient(to bottom, #f9fafb, #f9fafb);
}

.new_learning strong {
  font-size: 30px;
}

.viewed strong {
  font-size: 30px;
}

.pd-30 {
  padding: 30px;
}

.learning_block .btn-bordered {
  border-radius: 8px;
  border: 1px solid #d0d5dd;
  padding: 10px 14px;
  background-color: #fff;
  font-weight: 600;
}

.learning_block .btn-bordered:hover {
  background-color: #e2e1e1;
}

.learning_resouce img {
  border-radius: 4px;
  margin: 0px 0px 10px 0px;
  width: 240px;
  padding-right: 15px;
  object-fit: cover;
  aspect-ratio: 16/9;
  cursor: pointer;
}

.learning_resouce a {
  color: #0e7e97 !important;
  font-weight: 600;
  text-decoration: none;
}

.learning_resouce a:hover {
  text-decoration: underline !important;
  cursor: pointer;
}

.learning_resouce:hover {
  text-decoration: none;
  border: 3px solid #22c6ab;
  border-radius: 10px;
}

#learning_resource_modal .modal-dialog {
  max-width: 85%;
}

.learning_resouce {
  font-size: 14px;
  white-space: normal;
  text-align: left;
  margin-bottom: 30px;
  margin-right: 30px;
  border: 3px solid transparent;
  padding: 5px;
  width: 240px;
}

#learning_resource_modal div#pills-tabContent {
  height: auto;
}

#learning_resource_modal .modal-body {
  height: 80vh;
  overflow: auto;
}

#learning_resource_modal #background-pills-tab .nav-link.active {
  width: 80px;
}

#learning_resource_modal #background-pills-tab .nav-link {
  width: 80px;
}

.expand_bg {
  background-color: #dfe3eb;
  padding: 5px 5px;
  border-radius: 3px;
}

a.view_all.cei {
  font-weight: 700;
  padding-left: 15px;
  cursor: pointer;
  pointer-events: auto;
  font-size: 14px;
  color: #0e7e97 !important;
}

.activity_history,
.coaching_history {
  padding: 35px 30px 35px 30px;
  border-bottom: 1px solid #dfe3eb;
  border-top: 1px solid #dfe3eb;
}

.agent_coaching_history {
  margin-bottom: 35px;
}

.agent_activity_history {
  padding-bottom: 35px;
}

.coach_comparision1 {
  background-color: #fff;
  border-radius: 5px;
  margin-top: 15px;
  box-shadow: 0 0 4px 0 rgb(0 0 0 / 25%);
}

.coach_comparision1 h4 {
  padding: 15px;
  margin-bottom: 0px;
  font-size: 14px;
  font-weight: 600;
  padding-right: 0px;
}

.expand_bg img {
  width: 18px;
}

/* #coachingHistory_wrapper div.dataTables_scrollBody table {
  margin-top: -30px !important;
} */

#coachingHistory_wrapper .table th {
  white-space: nowrap !important;
}

#coachingHistory_wrapper .table td {
  background-color: #fff;
  white-space: nowrap !important;
  font-weight: normal;
}

#CxPBRankingDetails_modal .table td {
  white-space: nowrap !important;
  font-weight: 600 !important;
  font-size: 12px !important;
}

.custom-data-table .border-left,
tr.border-left {
  border-left: 5px solid;
}

.time_frame_blockk .card {
  margin-bottom: 0;
}

.time_frame_blockk .card .table {
  margin-bottom: 0px;
}

.ack_thumb img {
  opacity: 0.4;
}

.ack_thumb:hover img {
  opacity: 1;
  cursor: pointer;
}

.custom-data-table .row-click,
#coachingHistory_wrapper .table td a {
  color: #0e7e97 !important;
  font-weight: 600;
  cursor: pointer;
  pointer-events: auto;
  pointer-events: auto;
}

.audio_img {
  width: 18px;
}

.ack_thumb img {
  width: 18px;
}

td a {
  text-decoration: none;
}

td a:hover {
  text-decoration: underline;
}

li.paginate_button {
  padding: 0;
  z-index: 1;
}

.ack_symbols {
  display: flex;
  justify-content: start;
  align-items: center;
  width: 20%;
  padding-left: 30px;
}

.ack_dialog_box {
  background-color: rgb(39, 41, 51);
  box-shadow: rgba(0, 0, 0, 0.1) 1px 1px 10px;
  padding: 25px 20px;
  margin: 0px 15px 0px 15px;
  border-radius: 4px;
  position: fixed;
  top: 0px;
  left: 0px;
  right: 0px;
  z-index: 999;
}

.ack_comment {
  width: 50%;
}

.ack_comment textarea {
  background-color: rgba(0, 0, 0, 0.25);
  border: 1px solid rgba(0, 0, 0, 0.45);
  border-radius: 4px;
  padding: 8px 16px 0;
  font-size: 14px;
  color: #fff !important;
}

.ack_comment textarea.form-control:focus {
  background-color: rgba(0, 0, 0, 0.25);
  border: 1px solid #fff;
  border-radius: 4px;
  padding: 8px 16px 0;
  font-size: 14px;
  color: #fff !important;
  box-shadow: none !important;
}

.ack_comment textarea::placeholder {
  color: #fff !important;
}

.ack_symbols .thumb {
  background-color: #fff;
  padding: 5px 15px !important;
  border-radius: 4px;
  border: 1px solid #fff;
  color: #fff;
  margin: 0px 10px;
}

button.btn.btn-ack-cancel {
  color: #fff;
  border: 1px solid #fff;
  font-size: 14px;
  font-weight: 600;
}

.ack_symbols .btn-ack-cancel {
  margin: 0px 15px;
}

#coachingHistory_wrapper .table thead tr th {
  padding: 15px 30px !important;
}

#recognition_table_wrapper div.dataTables_scrollBody table {
  margin-top: -30px !important;
}

.coaching_history_image {
  display: block;
  width: 18px;
  height: 18px;
}

#activityHistory_wrapper div.dataTables_scrollBody table {
  margin-top: -30px !important;
}

#activityHistory_wrapper .table td a {
  color: #0e7e97 !important;
  font-weight: 600;
  cursor: pointer;
  pointer-events: auto;
  pointer-events: auto;
}

.badge_card_header {
  display: flex;
  justify-content: start;
  align-items: center;
}

.collapse .card-body {
  padding: 0;
  margin: 0;
}

#badge_acheivement_table td img {
  width: 30px;
  height: 30px;
}

#badge_acheivement_table_wrapper {
  padding: 0;
}

#recognition_table_wrapper {
  padding: 0;
}

#recognition_table_wrapper .table td a {
  color: #0e7e97 !important;
  font-weight: 600;
  cursor: pointer;
  pointer-events: auto;
  pointer-events: auto;
}

#recognition_table_wrapper div.dataTables_scrollBody table {
  margin-top: -30px !important;
}

/* #badge_acheivement_table_wrapper div.dataTables_scrollBody table {
  margin-top: -30px !important;
} */

.agent_profile_block {
  padding: 30px;
}

.card-header {
  font-size: 14px;
  background-color: #fff;
  border-bottom: 1px solid rgba(0, 0, 0, 0.125);
  font-weight: 600;
}

#badges_collapse {
  margin-top: 50px;
}

#recognition_badges_collapse {
  margin-top: 50px;
}

#recognition_table_wrapper .table td {
  text-transform: capitalize;
}

.collapse .btn-bordered {
  border: 1px solid #dfe3eb;
  border-radius: 4px;
  font-weight: 600;
  color: #272933;
  cursor: pointer;
  font-size: 14px;
}

.scorecard_range_tooltip {
  position: absolute;
  left: -305px;
  background-color: #000;
  padding: 15px;
  border-radius: 4px;
  color: #fff;
  display: none;
  min-width: 300px;
  z-index: 9;
}

.scorecard_circle:hover .scorecard_range_tooltip {
  display: block;
}

.scorecard_range_tooltip li {
  white-space: nowrap;
  padding: 8px 10px;
  text-transform: capitalize;
}

.scorecard_range_tooltip li:hover {
  background-color: transparent;
}

.high-range {
  background-color: rgba(255, 255, 255, 0.18);
  border-radius: 4px;
}

.scorerange_color {
  width: 12px;
  height: 12px;
  border-radius: 12px;
}

.scorerangetext {
  margin-left: 5px;
}

.org_prog_header .metric_block {
  width: 15%;
}

.ticker_block .metric_block {
  /* width: 100%; */
  width: 240px;
  padding: 5px 50px 5px 25px;
  text-align: left;
  border-bottom: 1px solid #dfe3eb;
}

.table tbody tr:hover {
  background-color: #f5f8fa !important;
  /* cursor: pointer !important; */
}

.table tbody tr:hover td {
  background-color: #f5f8fa !important;
  /* cursor: pointer !important; */
}

.ticker_block .metrics_block:hover {
  background-color: #dbe9e1;
}

.hover_description {
  min-width: 215px;
}

/* right dashboard */
.right_dashboard {
  padding: 30px 10px 0px 30px;
  width: 300px;
}

.tile_bottom {
  padding: 10px 15px;
  color: rgba(39, 41, 51, 0.7);
  background-color: rgba(227, 231, 235, 0.52);
}

.right_dashboard li {
  padding: 0;
}

.tile_value {
  margin-top: 15px;
  font-size: 24px;
}

.tile_content {
  padding: 15px;
  font-size: 14px;
  height: 120px;
}

.tile_content .calendar {
  margin-top: 15px;
}

.right_tile {
  background-color: #fff;
  box-shadow: 0 0 4px rgba(0, 0, 0, 0.25);
  margin-bottom: 20px;
  border-radius: 4px;
  min-height: 160px;
}

.agent_incentive {
  display: flex;
  justify-content: start;
  align-items: center;
}

.agent_alpha {
  width: 50px;
  height: 50px;
  border-radius: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 18px;
  font-weight: 600;
  letter-spacing: 1px;
  background-color: #9e9e9eba;
  margin-right: 1em;
  letter-spacing: 0px;
  box-shadow: 0px 0px 5px #616161;
}

.agent_incentive_name {
  text-transform: capitalize;
}

.agent_incentive_name strong {
  font-weight: 600;
  font-size: 14px;
}

.agent_incentive_name {
  font-size: 13px;
  line-height: 21px;
}

.current_payout {
  border-radius: 4px;
  background-color: rgba(39, 41, 51, 0.55);
  background-image: linear-gradient(rgba(255, 255, 255, 0.8),
      rgba(255, 255, 255, 0.8));
  border-radius: 4px;
  flex: none;
  margin-top: 0px;
  margin-right: 48px;
  padding: 24px;
  box-shadow: 1px 1px 12px transparent;
}

#incentive_modal {
  background-color: rgba(0, 0, 0, 0.85);
}

#incentive_modal .modal-dialog {
  max-width: 93%;
  margin-top: 0px;
  margin-bottom: 0px;
}

#incentive_modal .left_incentive {
  width: 50%;
}

.current_payout strong {
  font-weight: 600;
}

.payout_val {
  font-size: 18px;
  margin-top: 10px;
}

#incentive_modal .modal-body {
  background-color: #fafbfd;
  padding: 0;
  height: 85vh;
  overflow: auto;
}

.custom-padding {
  padding: 15px 0px 0px 15px;
}

.incentive_table {
  margin-top: 132px;
}

.incentive_table .table thead tr th {
  padding: 10px 20px !important;
  border-right: 1px solid transparent;
  background-color: transparent;
  border-bottom-color: #dfe3eb !important;
  border-top: 1px solid #dfe3eb !important;
  text-transform: capitalize;
  font-size: 14px;
  text-align: left;
}

.incentive_table .table-bordered,
.incentive_table .table-bordered td,
.incentive_table .table-bordered th {
  border: 1px solid transparent;
  font-size: 14px;
  text-align: left;
}

.incentive_table .table-bordered td {
  font-weight: normal;
  text-align: left;
  padding: 10px 20px !important;
  background-color: transparent !important;
}

.incentive_table .table-bordered td.green {
  color: #047a11;
  font-weight: 700;
}

.incentive_table .table th:first-child {
  text-align: left;
}

.right_incentive_header {
  border-bottom: 1px solid #dfe3eb;
  padding: 10px 15px;
  font-size: 14px;
}

.poteantial_payout {
  margin: 15px 15px 0px 15px;
  height: 185px;
}

.transform {
  transform: translate3d(0px, 0px, 0px) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg) rotateZ(0deg) skew(0deg, 0deg);
  transform-style: preserve-3d;
}

.incentive_close {
  width: 100%;
  display: flex;
  justify-content: end;
  align-items: end;
  padding: 15px 20px 0px 0px;
}

.incentive_close .close_icon:hover {
  background-color: #22c6ab;
  cursor: pointer;
  pointer-events: auto;
}

.incentive_close .close_icon {
  width: 30px;
  height: 30px;
  border-radius: 30px;
  background-color: rgba(255, 255, 255, 0.16);
  display: flex;
  justify-content: center;
  align-items: center;
}

.payout_bg {
  padding: 15px;
  background-color: #6ccc76;
  background-image: linear-gradient(rgba(255, 255, 255, 0.8),
      rgba(255, 255, 255, 0.8));
  border-radius: 4px;
  font-size: 14px;
  font-weight: 600;
  transform: translate3d(0px, 0px, 0px) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg) rotateZ(0deg) skew(0deg, 0deg);
  transform-style: preserve-3d;
}

.right_incentive {
  background-color: #fff;
  border-radius: 4px;
  box-shadow: 1px 1px 6px rgba(0, 0, 0, 0.36);
  width: 50%;
}

.right_incentive_header strong {
  font-weight: 600;
}

.sub_layout {
  height: 200px;
  position: relative;
}

.poteantial_payout {
  padding: 15px;
}

.incentive_loader img {
  position: absolute;
  left: 25%;
  right: 0;
  top: 0;
  bottom: 0;
  display: flex;
  justify-content: center;
  align-items: center;
}

.show_money {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 20px;
}

.show_money .btn {
  color: #fff;
  font-size: 16px;
  font-weight: 600;
}

.show_money:hover {
  background-color: #f5f8fa;
}

.show_money .btn:hover {
  background-color: #ff8a65;
}

.show_money .btn:hover .show_money {
  background-color: #f5f8fa;
}

.incentive_input {
  border: 1px solid rgba(0, 0, 0, 0.23);
  border-radius: 4px;
  height: 20px;
}

.incentive_righttable {
  margin-top: 14px;
}

.btn-show-money {
  background-color: #ff5722;
  border: 2px #fff;
  border-bottom: 2px solid #c3421a;
  border-radius: 5px;
}

.border-bottom {
  border-bottom: 1px solid #dfe3eb !important;
}

.money_after_payout {
  padding: 8px 0px;
  font-weight: normal;
}

#incentive_modal .modal-dialog {
  border-radius: 4px;
}

#incentive_modal .modal-content {
  /* min-height:90vh;*/
  height: 90vh;
  background-color: transparent;
}

.incentive_loader {
  background-color: #2d3e52;
  position: relative;
  height: 200px;
  width: 100%;
}

.incentive_loader img {
  height: 200px;
}

.incentive_bottom {
  /*width: 100%;
    display: flex;
    justify-content: end;
    align-items: end;
    margin-top: -81px;
    padding-right: 10px;*/
  position: absolute;
  z-index: 9999999;
  right: -10px;
  bottom: -10px;
}

.incentive_bottom_popup {
  background-color: #ff5722;
  width: 80px;
  height: 80px;
  border-radius: 100%;
  border-bottom-right-radius: 0px;
  display: flex;
  justify-content: center;
  align-items: center;
  box-shadow: 1px 1px 3px rgba(0, 0, 0, 0.31);
}

.incentive_bottom_popup img {
  width: 35px;
  height: 35px;
}

.incentive_count {
  position: absolute;
  bottom: -6px;
  border: 1px solid #fff;
  width: 24px;
  height: 24px;
  border-radius: 24px;
  background-color: #ff5722;
  left: 1px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.incentive_count span {
  color: #fff;
  font-weight: 600;
  font-size: 12px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.incentive-bottom-tooltip {
  background-color: #fff;
  padding: 0;
  box-shadow: 1px 1px 20px rgba(0, 0, 0, 0.22);
  border: 1px solid #e4e4e4;
}

.incentive_bottom_tooltip_close {
  display: flex;
  justify-content: end;
  align-items: end;
  cursor: pointer;
  pointer-events: auto;
}

.incentive_bottom_tooltip_close img {
  width: 30px;
  height: 30px;
  border: 1px solid #ccc;
  padding: 5px;
  border-right: 0;
  border-top: none;
  border-radius: 5px;
}

.incentive-bottom-tooltip {
  background-color: #fff;
  padding: 0px;
  bottom: 80px;
  right: 10px;
  width: 215px;
  height: auto;
  color: #101828;
}

.space-box {
  padding: 15px 20px;
}

.incentive_highlight {
  font-size: 14px;
  font-weight: 600;
}

.incentive_text {
  font-size: 14px;
  padding: 15px 0px;
}

.incentive_text b {
  background-color: #c5f1ca;
  font-weight: 600;
  padding: 2px 5px;
  border: 2px;
}

.incentive_gain ul {
  font-size: 14px;
  padding-bottom: 20px;
}

.game_start .btn {
  background-color: #22c7a9 !important;
  border-color: #22c7a9 !important;
  font-size: 14px;
  font-weight: 600;
}

.game_start {
  display: flex;
  justify-content: center;
}

.btn-primary:hover {
  background-color: #1e6d60 !important;
  border-color: #1e6d60 !important;
}

.incentive_bottom_popup:hover {
  background-color: #ffa386;
  cursor: pointer;
  pointer-events: auto;
}

.incentive-bottom-tooltip:hover .incentive_bottom_popup {
  background-color: #ffa386;
}

.new_game_tile {
  box-shadow: 0 0 4px 0 rgb(0 0 0 / 25%);
  border-radius: 3px;
  background: #fff;
  margin: 0px;
  white-space: nowrap;
  text-decoration: none;
}

.tile_block .yellow {
  background: #f6be00;
  width: 18px;
  height: 18px;
  display: flex;
  padding: 5px;
  border-radius: 100%;
  justify-content: center;
  align-items: center;
}

.game_status_indication .position_absolute .yellow img {
  width: 8px !important;
  height: 8px !important;
}

.tile_bottom b {
  font-weight: 700;
}

.game_status_indication .position_absolute {
  /* padding: 10px; */
  position: absolute;
  top: -5px;
  left: 32px;
}

.tile_block {
  padding: 20px 20px 16px;
  height: 120px;
  white-space: normal;
}

.game_metric_name {
  width: calc(100% - 45px);
  padding-right: 5px;
}

.new_game_tile .game_name {
  font-size: 14px !important;
  color: #323441 !important;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  width: 100%;
}

.new_game_tile .metric_name {
  color: #7e7e7e;
  font-size: 12px;
  text-transform: uppercase;
  margin-top: 1px;
  overflow: hidden;
  text-overflow: ellipsis;
  width: 100%;
  font-weight: normal;
  white-space: nowrap;
}

.game_status_indication .med {
  border: 1px solid #ddd;
  padding: 8px;
  border-radius: 100%;
}

.game_status_indication img {
  width: 24px;
  height: 24px;
}

.game_status_indication .position_absolute img {
  width: 18px !important;
  height: 18px !important;
}

.chat_count {
  display: flex;
  font-size: 14px;
  padding: 20px 0px 10px 0px;
  color: rgba(39, 41, 51, 0.7);
  /* font-weight: bold; */
  opacity: 0.7;
}

.chat_count>div {
  color: #323441;
  font-weight: bold;
  padding-right: 10px;
}

.chat_count div {
  padding-right: 5px;
}

.chat_count img {
  height: 14px;
  width: 100%;
  opacity: 0.5;
}

.pr-2 {
  padding-right: 2px !important;
}

.absolute-s {
  font-size: 10px;
  margin-top: -5px;
}

.chat_count img {
  height: 14px;
  width: 100%;
  opacity: 0.5;
}

.tile_bottom {
  color: rgba(39, 41, 51, 0.7);
  background-color: rgba(227, 231, 235, 0.52);
  font-size: 13px;
  padding: 8px 0px 8px 20px;
  height: 40px;
  /* opacity: 0.5; */
}

.right_tile:hover {
  outline: 5px solid rgba(14, 126, 151, 0.33);
}

#btnUpload {
  color: #fff;
  background-color: #22c7a9;
  border-color: #22c7a9;
  font-size: 14px;
  margin-bottom: 10px;
}

#v-pills-upload {
  font-size: 12px;
}

.input_fields_wrap {
  padding-top: 10px;
}

a.remove_field {
  color: #fff;
  text-decoration: none;
  background-color: #22c7a9;
  border-radius: 3px;
  padding: 3px 10px;
}

a.remove_field:hover {
  background-color: #1e6d60 !important;
  border-color: #1e6d60 !important;
}

#btnUpload:hover {
  background-color: #1e6d60 !important;
  border-color: #1e6d60 !important;
}

.ledaer .btn-primary:hover {
  background-color: #1e6d60 !important;
  border-color: #1e6d60 !important;
}

#agent_scheduling_modal h3 {
  font-size: 14px;
}

#agent_scheduling_modal .agent_schedule_date {
  font-size: 14px;
  margin-bottom: 10px;
  font-weight: 600;
}

.metric-number-tile-header {
  font-size: 14px;
}

.disclaim {
  font-size: 12px;
}

#opendoor_incentivemodal .modal-dialog {
  max-width: 80%;
}

.preselect a {
  text-decoration: none !important;
}

.samp.metric-numbers.text-center {
  white-space: nowrap;
  font-size: 16px;
  margin-top: 14px;
  color: #323441;
}

#agent_scheduling_modal .modal-dialog {
  max-width: 55%;
}

.org_prog_header {
  position: sticky;
  top: 0px;
  /* top: 65px; */
  z-index: 2;
  background-color: #f5f8fa;
}

#rank_table {
  overflow-y: auto;
  overflow-x: hidden;
}

#foldermodal {
  background-color: rgb(0 0 0 / 78%);
}

#balancescorecard_modal .modal-body {
  height: 80vh;
  overflow: auto;
}

.dropdown_block ul li a {
  text-decoration: none;
}

#activity_history_table td {
  font-weight: normal;
}

.highcharts-button-symbol {
  display: none;
}

.font-light {
  font-weight: normal;
}

.leader_board_details .left_block {
  width: 38%;
}

#activityHistory_wrapper table.dataTable thead .sorting:before,
table.dataTable thead .sorting:after,
table.dataTable thead .sorting_asc:before,
table.dataTable thead .sorting_asc:after,
table.dataTable thead .sorting_desc:before,
table.dataTable thead .sorting_desc:after,
table.dataTable thead .sorting_asc_disabled:before,
table.dataTable thead .sorting_asc_disabled:after,
table.dataTable thead .sorting_desc_disabled:before,
table.dataTable thead .sorting_desc_disabled:after {
  bottom: 15px;
}

#coachingHistory_wrapper table.dataTable thead .sorting:before,
table.dataTable thead .sorting:after,
table.dataTable thead .sorting_asc:before,
table.dataTable thead .sorting_asc:after,
table.dataTable thead .sorting_desc:before,
table.dataTable thead .sorting_desc:after,
table.dataTable thead .sorting_asc_disabled:before,
table.dataTable thead .sorting_asc_disabled:after,
table.dataTable thead .sorting_desc_disabled:before,
table.dataTable thead .sorting_desc_disabled:after {
  bottom: 15px;
}

#activityHistory_wrapper .dataTables_scrollHeadInner table.dataTable thead .sorting:before,
table.dataTable thead .sorting:after,
table.dataTable thead .sorting_asc:before,
table.dataTable thead .sorting_asc:after,
table.dataTable thead .sorting_desc:before,
table.dataTable thead .sorting_desc:after,
table.dataTable thead .sorting_asc_disabled:before,
table.dataTable thead .sorting_asc_disabled:after,
table.dataTable thead .sorting_desc_disabled:before,
table.dataTable thead .sorting_desc_disabled:after {
  bottom: 15px !important;
}

#coachingHistory_wrapper .dataTables_scrollHeadInner table.dataTable thead .sorting:before,
table.dataTable thead .sorting:after,
table.dataTable thead .sorting_asc:before,
table.dataTable thead .sorting_asc:after,
table.dataTable thead .sorting_desc:before,
table.dataTable thead .sorting_desc:after,
table.dataTable thead .sorting_asc_disabled:before,
table.dataTable thead .sorting_asc_disabled:after,
table.dataTable thead .sorting_desc_disabled:before,
table.dataTable thead .sorting_desc_disabled:after {
  bottom: 17px !important;
}

ul.cu-nav li:hover>ul,
ul.cu-nav li ul:hover {
  z-index: 9 !important;
}

.cursor-pointer {
  /*pointer-events:auto;*/
  cursor: pointer !important;
}

.cursor-pointer:hover {
  /*pointer-events:auto;*/
  cursor: pointer !important;
}

.agent_scorecard li a {
  color: inherit !important;
  text-decoration: none;
}

.agent_scorecard li a:hover {
  color: inherit !important;
  text-decoration: none;
}

.games_won_table .table td {
  font-size: 13px;
}

.save_footer .btn-primary:hover {
  background-color: #1e6d60 !important;
  border-color: #1e6d60 !important;
  pointer-events: auto;
  cursor: pointer !important;
}

.datepicker table tr td.active:active,
.datepicker table tr td.active:hover:active,
.datepicker table tr td.active.disabled:active,
.datepicker table tr td.active.disabled:hover:active,
.datepicker table tr td.active.active,
.datepicker table tr td.active:hover.active,
.datepicker table tr td.active.disabled.active,
.datepicker table tr td.active.disabled:hover.active {
  background-color: #23c7a9 !important;
  background-image: none !important;
}

.pb-7 {
  padding-bottom: 7px;
}

.time_frame_block .dropdown-menu.show {
  height: auto !important;
  overflow: inherit !important;
}

/*.datepicker .table-condensed > tbody > tr > td, .table-condensed > thead > tr > th {
    padding: 8px 8px;
}*/
.time_frame_block .timeframe-scrollable {
  height: 55vh;
  /* overflow-y: auto !important;*/
}

.time_frame_block .dropdown-menu .border-left {
  height: 55vh;
  overflow-y: auto;
}

.table tr:hover {
  background-color: #f5f8fa !important;
}

.table tbody tr td a {
  color: #0e7e97 !important;
  text-decoration: none !important;
  cursor: pointer !important;
  font-weight: 600;
}

.table tbody tr td a:hover {
  color: #0e7e97 !important;
  text-decoration: none !important;
  cursor: pointer !important;
}

#time_frame_dropdown .dropdown-menu {
  min-width: 29rem;
}

#time_frame_dropdown .datepicker .datepicker-days {
  padding: 18px 20px !important;
}

#modal_time_frame_dropdown .dropdown-menu.show {
  min-width: 29rem;
}

#modal_time_frame_dropdown .datepicker .datepicker-days {
  padding: 18px 18px !important;
}

.border-transaprent {
  border-color: transparent !important;
}

#modal_time_frame_dropdown .scroll {
  max-height: 55vh;
  overflow-y: auto !important;
}

.space_name {
  letter-spacing: 0px;
}

.modal_date {
  font-size: 13px;
  margin-bottom: 20px;
}

.game_won_block.hid {
  display: none !important;
}

.game_notwon_block.hid {
  display: none !important;
}

#gamesnot_won_table tr {
  height: 50px;
}

#gamesnot_won_table tbody tr td {
  /*border-color:transparent !important;*/
}

#gamesnot_won_table thead tr th {
  border-top-color: transparent !important;
  border-right-color: transparent !important;
  border-left-color: transparent !important;
  text-transform: capitalize !important;
  text-align: center !important;
  background-color: transparent !important;
  border-bottom-width: 1px;
}

#gamesnot_won_table thead tr th:hover {
  background-color: transparent !important;
}

.game_notwon_block .card-body {
  padding-top: 0px;
}

.game_notwon_block .card-header {
  border-bottom-color: transparent !important;
}

.game_notwon_block {
  border-color: transparent;
}

.line-control-menu-bar a.btn {
  position: relative;
  margin-bottom: 3px;
  background-color: #22c7a9;
  color: #fff;
}

#ackbtn {
  background-color: #22c7a9;
  border-color: #22c7a9;
}

.initials_bs {
  float: left;
  background-image: linear-gradient(45deg, #212529, #495057);
  background-color: #ccc;
  border-radius: 100%;
  color: #fff;
  padding: 10px;
  width: 30px;
  height: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 11px;
  margin-left: -9px;
  margin-right: 6px;
}

.rank_badge {
  z-index: 9;
}

#leaderboard_table tbody tr {
  cursor: default !important;
}

#leaderboard_table tbody tr:hover {
  cursor: default !important;
}

#leaderboard_table tbody tr:hover td {
  cursor: default !important;
}

#leaderboard_table thead tr th {
  border: 0px !important;
  border-top: 0px !important;
  border-bottom: 0px !important;
}


#leaderboard_table tbody tr td:nth-child(3) {
  padding-left: 0px !important;
  padding-right: 0px !important;
}

.bg-white:hover {
  background-color: #fff !important;
}

#activity_history_table tbody tr:hover {
  cursor: text !important;
}

#activity_history_table tbody tr {
  cursor: text !important;
}

#activity_history_table tbody tr:hover td {
  cursor: text !important;
}

#activity_history_table tbody td:hover {
  cursor: text !important;
}

tbody tr a {
  cursor: auto !important;
  /*pointer-events:auto !important;*/
}

.table tbody tr.no-cursor {
  cursor: default !important;
}

.table tbody tr.no-cursor:hover td {
  cursor: default !important;
}

.table th:hover {
  cursor: default !important;
}

.table th:before {
  cursor: pointer !important;
}

.table th:after {
  cursor: pointer !important;
}

.dataTables_scrollHeadInner table.dataTable thead .sorting::before,
table.dataTable thead .sorting::after,
table.dataTable thead .sorting_asc::before,
table.dataTable thead .sorting_asc::after,
table.dataTable thead .sorting_desc::before,
table.dataTable thead .sorting_desc::after,
table.dataTable thead .sorting_asc_disabled::before,
table.dataTable thead .sorting_asc_disabled::after,
table.dataTable thead .sorting_desc_disabled::before,
table.dataTable thead .sorting_desc_disabled::after {
  cursor: pointer !important;
}

.show_more_options .commit-tooltip li a {
  display: block;
}

#scorecard_details_table td {
  white-space: nowrap;
}

.highcharts-credits {
  display: none;
}

.scorecard_ranking .card {
  margin-bottom: 0;
}

.trend_chart .card {
  margin-bottom: 0;
}

#modal_time_frame_dropdown .form-select {
  white-space: nowrap;
}

#pills-tab {
  flex-wrap: nowrap;
}

#datepicker {
  color: #344054 !important;
  font-size: 12px;
}

#datepicker .react-datepicker {
  border: none;
  font-family: unset;
  width: 100%;
}

#datepicker .react-datepicker__month-container {
  float: unset;
}

#datepicker .react-datepicker__header {
  background-color: #fff;
  border-bottom: 0px;
  border-top-left-radius: 0px;
}

#datepicker .react-datepicker__month {
  padding: 10px 12px;
}

#datepicker .react-datepicker__day {
  width: 2.4rem;
  line-height: 2.4rem;
  border-radius: 1.4rem;
}

#datepicker .react-datepicker__day--selected {
  background-color: #22c7a9 !important;
}

#datepicker .react-datepicker__day--keyboard-selected {
  background-color: #22c7a9 !important;
  color: #fff;
}

#datepicker .react-datepicker__day:hover {
  background: rgba(35, 199, 169, 0.18) !important;
}

#monthly_scorecard_weights .agent_name {
  font-size: 14px;
}

#monthly_scorecard_weights .modal-title {
  width: 100% !important;
  text-align: left;
}

.monthly_scorecard .scorecard_text_note_table {
  padding: 2px 10px;
  font-size: 12px;
  background-color: #ed4734;
  display: flex;
  font-weight: 600;
  justify-content: end;
  align-items: center;
  color: #fff;
  border-radius: 4px;
  max-width: 350px;
}

.scorecard_text_note {
  font-size: 12px;
}

.view-details-FAQ a {
  color: rgb(39, 41, 51);
}

#AIMLPopup .modal-header {
  font-size: 14px;
  background-color: black;
  color: #fff;
  grid-column-gap: 12px;
  grid-row-gap: 12px;
  align-items: center;
  display: flex;
  border: none;
  height: 90px;
}

#AIMLPopup .modal-content {
  border-radius: 10px !important;
  width: 523px;
}

#AIMLPopup .modal-image {
  width: 530px;
}

#AIMLPopup .modal-title {
  grid-column-gap: 12px;
  grid-row-gap: 12px;
  align-items: center;
  display: flex;
  text-align: left;
}

#AIMLPopup .title_name {
  background-color: rgba(213, 217, 224, .29);
  border-radius: 100px;
  flex: none;
  justify-content: center;
  align-items: center;
  width: 32px;
  height: 32px;
  font-size: 12px;
  display: flex;
  font-weight: 600;
}

span#dashboard-date {
  font-weight: 600;
}

#AIMLPopup .modal-body {
  justify-content: center;
  align-items: center;
  height: 240px;
  display: flex;
  overflow: hidden;
}

#AIMLPopup .message-item {
  display: none;
}

#AIMLPopup .modal-footer {
  background-color: black;
  border: none;
  color: #fff;
  padding: 24px;
}

#AIMLPopup .recognition-text {
  margin-bottom: 20px;
  font-size: 18px;
  font-weight: 600;
  line-height: 1.5em;
  white-space: normal;
}

.dash-button {
  cursor: pointer;
  background-color: #1ead93;
  border-radius: 8px;
  padding: 10px 12px;
  font-weight: 700;
  color: #fff;
  border: none;
  font-size: .875rem;
  line-height: 1.25rem;
}

.dash-button:hover {
  background-color: #026855;
  outline: none;
}



#AIMLPopup .image-174:hover {
  outline: none;
  background-color: black;
  text-decoration: none;
  color: #fff;
}


#AIMLPopup .image-174 {
  z-index: 100;
  cursor: pointer;
  background-color: #424242;
  border-radius: 4px;
  width: 48px;
  height: 48px;
  padding: 14px;
  position: fixed;
  top: 24px;
  right: 50px;
}

#AIMLPopup .modal-close-background-2023 {
  z-index: -1;
  position: absolute;
  top: 0%;
  bottom: 0%;
  left: 0%;
  right: 0%;
  overflow: scroll;
}

#AIMLPopup .title_name img {
  width: 50px;
  height: 50px;
  border-radius: 50px;
}

#AIMLPopup div#execagentfullname {
  font-size: 14px;
  font-weight: 600;
}

#AIMLPopup div#execagenttitle {
  text-transform: capitalize;
  font-size: 12px;
}

.tiles-table tbody tr td {
  font-weight: 400 !important;
  text-align: center !important;
  white-space: nowrap !important;
  font-size: 12px !important;
}

#leaderboard_table .table>thead>tr>th {
  border: 0;
}

.leaderboard_table .table.dataTable>thead>tr>th {
  border-right: 1px solid transparent;
}

.leaderboard_table .table-bordered,
.leaderboard_table .table-bordered th {
  border: 1px solid transparent;
}

.flname {
  color: #343a40;
  background-color: #dee3e5;
  width: 30px;
  height: 30px;
  border-radius: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 5px;
}

.manager {
  display: flex;
}

.managername {
  text-transform: capitalize;
}