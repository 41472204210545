@charset "utf-8";

/* CSS Document */
body {
  background-color: hsla(0, 0%, 96%, 0.61);
}

body {
  /* font-family: Lato,sans-serif; */
  color: #323441;
  /* line-height: 150%; */
  font-size: 12px;
}

/*body {
    margin: 0;
    color: #3e5569;
    background: #fff;
   
}*/

.btn-light {
  background-color: #e8e8eb;
}

.btn-primary {
  background-color: #00b9ff;
  border-color: #00b9ff;
}

.sticky-table table tr.sticky-header th,
.sticky-table table tr.sticky-header td,
.sticky-table table tr.sticky-footer th,
.sticky-table table tr.sticky-footer td {
  padding-right: 26px;
}

.page-breadcrumb {
  padding: 10px 20px 0;
}

h2 {
  text-align: center;
  padding: 20px;
}

/* Slider */

.score {
  text-align: center;
  font-weight: bold;
  font-size: 16px;
  margin-top: 10px;
}

.profile2 {
  width: 100%;
  float: left;
  overflow: hidden;
}

.profilename2 {
  font-size: 13px;
  font-weight: 600;
  width: 83px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  color: #373d4b;
}

.profile2 label {
  font-size: 9px;
}

.profilename3 {
  font-size: 11px;
  font-weight: 600;
  width: 60px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  color: #373d4b;
  display: inline-block;
}

.slick-arrow {
  background: #323743;
  border: none;
  font-size: 16px;
  padding: 8px;
  height: 35px;
  color: #fff;
}

.slick-prev {
  float: left;
}

.slick-prev {
  float: left;
}

.slick-next {
  float: right;
  top: -34px;
  position: inherit;
}

.slick-slide {
  margin: 0px 1px;
}

.slick-slide img {
  width: 100%;
}

.slick-slider {
  position: relative;
  display: block;
  box-sizing: border-box;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  -webkit-touch-callout: none;
  -khtml-user-select: none;
  -ms-touch-action: pan-y;
  touch-action: pan-y;
  -webkit-tap-highlight-color: transparent;
}

.slick-list {
  position: relative;
  display: block;
  overflow: hidden;
  margin: 0;
  padding: 0;
}

.slick-list:focus {
  outline: none;
}

.slick-list.dragging {
  cursor: pointer;
  cursor: hand;
}

.slick-slider .slick-track,
.slick-slider .slick-list {
  -webkit-transform: translate3d(0, 0, 0);
  -moz-transform: translate3d(0, 0, 0);
  -ms-transform: translate3d(0, 0, 0);
  -o-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0);
}

.slick-track {
  position: relative;
  top: 0;
  left: 0;
  display: block;
}

.slick-track:before,
.slick-track:after {
  display: table;
  content: "";
}

.slick-track:after {
  clear: both;
}

.slick-loading .slick-track {
  visibility: hidden;
}

.slick-slide {
  display: none;
  float: left;
  height: 100%;
  min-height: 1px;
  font-weight: 600;
  text-align: center;
  height: 20px;
  color: #111319;
  height: 35px;
  padding-top: 9px;
}

table.dataTable thead .sorting:before,
table.dataTable thead .sorting:after,
table.dataTable thead .sorting_asc:before,
table.dataTable thead .sorting_asc:after,
table.dataTable thead .sorting_desc:before,
table.dataTable thead .sorting_desc:after,
table.dataTable thead .sorting_asc_disabled:before,
table.dataTable thead .sorting_asc_disabled:after,
table.dataTable thead .sorting_desc_disabled:before,
table.dataTable thead .sorting_desc_disabled:after {
  position: absolute;
  bottom: 0.9em;
  display: block;

  font-weight: bold;
  font-size: 17px;
}

.slick-slide:hover {
  display: none;
  float: left;
  height: 100%;
  min-height: 1px;
  font-weight: 600;
  text-align: center;
  height: 20px;
  /* border: red; */
  background: #50ce50;
  height: 35px;
  padding-top: 9px;
}

.act {
  display: none;
  float: left;
  height: 100%;
  min-height: 1px;
  font-weight: 600;
  text-align: center;
  height: 20px;
  /* border: red; */
  background: #50ce50;
  height: 35px;
  padding-top: 9px;
}

[dir="rtl"] .slick-slide {
  float: right;
}

.slick-slide img {
  display: block;
}

.slick-slide.slick-loading img {
  display: none;
}

.slick-slide.dragging img {
  pointer-events: none;
}

.slick-initialized .slick-slide {
  display: block;
}

.slick-loading .slick-slide {
  visibility: hidden;
}

.slick-vertical .slick-slide {
  display: block;
  height: auto;
  border: 1px solid transparent;
}

.slick-arrow.slick-hidden {
  display: none;
}

.profileag {
  width: 100%;
  float: left;
  overflow: hidden;
}

.profile {
  width: 50%;
  float: left;
  overflow: hidden;
}

.profilename {
  font-size: 13px;
  font-weight: 600;
  width: 136px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  color: #373d4b;
}

.profilename a {
  color: #373d4b;
}

.prpfilesubtitle {
  font-size: 10px;
  font-weight: 500;
  color: #00b9ff;
  font-weight: bold;
  padding-left: 5px;
}

.Tenure {
  text-align: center;
  float: left;
  padding-right: 13px;
  font-weight: 600;
  font-size: 12px;
  color: #00b9ff;
}

.topbar {
  position: relative;
  z-index: 50;
  background: #323743;
  height: 65px;
}

.soclingnv {
  width: 100%;
  margin: 10px auto 0 auto;
  padding: 15px 25px;
  position: relative;
}

.soclingnv .arrowLeft {
  position: absolute;
  left: 10px;
  top: 22px;
  cursor: pointer;
}

.soclingnv .arrowRight {
  position: absolute;
  right: 10px;
  top: 22px;
  cursor: pointer;
}

.soclingnv div {
  overflow: hidden;
  position: relative;
  height: 23px;
}

.soclingnv ul {
  position: absolute;
  left: 0;
  width: 100%;
  top: 0;
}

.soclingnv ul li {
  float: left;
  list-style: none;
}

.soclingnv ul li:hover {
  background-color: #272933;
}

.soclingnv ul li a {
  text-decoration: none;
  color: #000;
  padding: 8px 12px;
  font-family: Arial, Helvetica, sans-serif;
  font-size: 13px;
  font-weight: bold;
}

.droptitle {
  font-weight: bold;
}

.droptitlesub {
  color: #00b9ff;
}

.bstimeslider {
  width: 100%;
  height: 40px;
  background: #ccc;
  position: relative;
}

.bktibx {
  float: left;
  margin: 0 2px 0 0;
  font-size: 17px;
  /* width: 62px; */
  display: block;
  /* background: #000; */
  color: #242424;
  padding: 6px 24px 6px;
  font-weight: 500;
}

#tslshow {
  position: absolute;
  left: 0;
  width: 1200px;
}

#leftArrow {
  width: 40px;
  height: 40px;
  /* background: #ff0000; */
  position: absolute;
  left: 0px;
  font-size: 22px;
  padding: 5px;
  z-index: 9999;
  background: #fff;
}

#rightArrow {
  width: 40px;
  height: 40px;
  position: absolute;
  right: 0px;
  font-size: 22px;
  padding: 5px;
  z-index: 9999;
  background: #fff;
}

#viewContainer {
  width: 93%;
  height: 100%;
  position: absolute;
  left: 3%;
  /* margin-left: -180px; */
  overflow: hidden;
}

.bktibx:hover {
  background-color: #75c709;
}

.ab {
  background-color: #75c709;
}

.floatright {
  float: right;
}

.exeicon img {
  width: 40px;
}

.tdspana {
  padding-right: 120px;
  font-weight: bold;
}

.bg-darkg {
  padding: 5px 20px;
  background: -moz-linear-gradient(45deg,
      rgba(39, 41, 51, 1) 0%,
      rgba(55, 61, 75, 1) 100%);
  /* ff3.6+ */
  background: -webkit-gradient(linear,
      left bottom,
      right top,
      color-stop(0%, rgba(39, 41, 51, 1)),
      color-stop(100%, rgba(55, 61, 75, 1)));
  /* safari4+,chrome */
  background: -webkit-linear-gradient(45deg,
      rgba(39, 41, 51, 1) 0%,
      rgba(55, 61, 75, 1) 100%);
  /* safari5.1+,chrome10+ */
  background: -o-linear-gradient(45deg,
      rgba(39, 41, 51, 1) 0%,
      rgba(55, 61, 75, 1) 100%);
  /* opera 11.10+ */
  background: -ms-linear-gradient(45deg,
      rgba(39, 41, 51, 1) 0%,
      rgba(55, 61, 75, 1) 100%);
  /* ie10+ */
  background: linear-gradient(45deg,
      rgba(39, 41, 51, 1) 0%,
      rgba(55, 61, 75, 1) 100%);
  /* w3c */
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#373d4b', endColorstr='#272933', GradientType=1);
  /* ie6-9 */
}

.navbar-light .navbar-nav .nav-link {
  color: #fff;
  font-size: 12px;
  font-weight: 550;
}

.navbar-light .navbar-nav .nav-link:hover {
  color: #bdbdbd;
}

.navbar-light .navbar-brand,
.navbar-light .navbar-brand:focus,
.navbar-light .navbar-brand:hover {
  color: rgba(164, 164, 164, 0.9);
}

.navbar-light .navbar-brand,
.navbar-light .navbar-brand:focus,
.navbar-light .navbar-brand:hover {
  color: #fff;
}

.table-responsive {
  display: block;
  width: 100%;

  overflow-x: auto;
  -webkit-overflow-scrolling: touch;
  -ms-overflow-style: -ms-autohiding-scrollbar;
}

.selectcutitle {
  font-size: 11px;
  line-height: 2;
  text-align: center;
}

.selectcu {
  height: 250px;
  box-shadow: 0 0 2px 1px hsla(0, 0%, 52%, 0.2);
}

.droptext {
  font-size: 15px;
  text-align: center;
  line-height: 2;
  padding-top: 40px;
  font-weight: bold;
}

.selectcu option {
  padding: 9px 9px;
  font-size: 14px;
  /* background: #f1f1f1; */
  margin-top: 10px;
  border: 1px solid #f3f3f3;
}

/*Extra small devices (portrait phones, less than 576px)*/
@media (max-width: 575px) {
  .floatright {
    float: none;
  }

  .xs-hidden {
    display: none;
  }

  .exeicon img {
    width: 30px;
  }
}

#wrapper {
  position: relative;
  width: 300px;
  height: 300px;
  overflow: hidden;
  /* Prevent native touch events on Windows */
  -ms-touch-action: none;
  /* Prevent the callout on tap-hold and text selection */
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  /* Prevent text resize on orientation change, useful for web-apps */
  -webkit-text-size-adjust: none;
  -moz-text-size-adjust: none;
  -ms-text-size-adjust: none;
  -o-text-size-adjust: none;
  text-size-adjust: none;
}

#scroller {
  position: absolute;
  /* Prevent elements to be highlighted on tap */
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  /* Put the scroller into the HW Compositing layer right from the start */
  -webkit-transform: translateZ(0);
  -moz-transform: translateZ(0);
  -ms-transform: translateZ(0);
  -o-transform: translateZ(0);
  transform: translateZ(0);
}

.nav-pills .nav-link.active,
.nav-pills .show>.nav-link {
  border-bottom-color: #000;
  background-color: #373d4b;
  color: #fff;
}

.task-mgr-toolbar {
  border-style: none none solid;
  border-width: 1px 1px 2px;
  border-color: rgba(50, 52, 65, 0.1) rgba(50, 52, 65, 0.1) rgba(50, 52, 65, 0.23);
  border-radius: 3px;
  background-color: rgba(104, 108, 134, 0.11);
  margin-bottom: 6px;
}

.tabtask {
  display: flex;
  height: 30px;
  padding-left: 5px;
  border-bottom: 1px solid #e8e8f4;
  background-color: #f8f8fc;
  color: #323441;
  text-transform: capitalize;
}

.taskh {
  color: #323441;
  font-weight: bold;
  padding: 7px;
}

.taskdivrow {
  padding: 9px;
  font-size: 12px;
  border-bottom: 1px solid #e8e8eb;
}

.current {
  border-left-color: #272933;
  background-color: #f1f5ff;
  color: #373d4b;
  font-size: 14px;
}

.div-block-133 {
  display: flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
}

.image-31 {
  width: 50px;
  height: 50px;
  border-radius: 100%;
}

.paddingtask {
  padding-right: 3px;
}

.small {
  width: 40px;
}

.small-right {
  width: 60px;
  margin-right: 10px;
}

.div-block-134 {
  display: flex;
  margin-left: 10px;
  padding-bottom: 0;
  -webkit-box-orient: vertical;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: start;
  -webkit-align-items: flex-start;
  -ms-flex-align: start;
  align-items: flex-start;
}

.taskinmg {
  float: left;
  padding-right: 5px;
}

.singleline {
  display: inline-block;
  overflow: hidden;
  /* height: 1.5em; */
  color: #0056ff;
  font-size: 12px;
  width: 62px;
  text-overflow: ellipsis;
  white-space: nowrap;
  font-weight: bold;
}

.gray {
  color: #686c86;
  width: 70px;
  display: inline-block;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.calibrationtask {
  background-color: #ffd54f;
  color: #373d4b;
  font-size: 11px;
}

.calibrationtask-right {
  background-color: #ffd54f;
  color: #373d4b;
  font-size: 15px;
  font-weight: bold;
  text-align: center;
}

.testright {
  font-size: 13px;
  font-weight: bold;
}

.task-item {
  height: auto;
  padding: 6px 8px;
  border-radius: 3px;
}

.current {
  border-left-color: #272933;
  background-color: #f1f5ff;
  color: #373d4b;
  font-size: 14px;
}

.taskbord {
  border-left: 3px solid #000;
}

.taskbord1 {
  border-left: 5px solid #e7e7e7;
}

.recognize {
  background-color: #ba68c8;
  color: #fff;
  font-size: 10px;
}

.coach-task {
  background-color: #5c6bc0;
  color: #fff;
}

.sidetexth {
  font-size: 20px;
  line-height: 1.5;
}

.bgtaskbg {
  background-color: #fff;
}

.bgtask {
  display: block;
  height: 100%;
  margin-bottom: 20px;
  padding: 0;
  border-radius: 3px;
  background-color: #fff;
  box-shadow: 0 0 2px 1px hsla(0, 0%, 52%, 0.27);
}

.disabledtextinput {
  width: 100%;
  height: 40px;
  padding: 9px 15px;
  border: 1px solid #e8e8eb;
  border-radius: 5px;
  background-color: #f6f6f6;
  font-size: 14px;
}

.task-mgr-button {
  border-style: solid;
  border-width: 1px;
  font-size: 12px;
}

.w-button {
  display: inline-block;
  /* padding: 9px 15px; */
  background-color: #3898ec;
  color: #fff;
  border: 0;
  line-height: inherit;
  text-decoration: none;
  cursor: pointer;
  border-radius: 50px;
}

.task-mgr-button {
  margin-left: 10px;
  padding: 6px 15px;
  border-color: rgba(0, 0, 0, 0.41);
  border-radius: 50px;
  background-color: #323441;
  color: #fff;
  cursor: pointer;
}

.cu-table td {
  padding: 11px;
}

.current_history {
  background-color: #373d4b;
  color: #fff;
}

.cu-table th {
  padding: 5px;
}

.padding0 {
  padding: 0px;
}

.cellbg {
  padding-bottom: 2px;
  background-color: rgba(50, 52, 65, 0.03);
  color: #6b6b6b;
  font-size: 12px;
  text-transform: uppercase;
  padding: 9px;
  border-right: 1px solid #ebebeb;
  border-bottom: 1px solid #ebebeb;
  border-top: 1px solid #ebebeb;
}

.tile-wrap {
  display: block;
  height: 100%;
  margin-bottom: 20px;
  padding: 0;
  border-radius: 3px;
  background-color: #fff;
  box-shadow: 0 0 2px 1px hsla(0, 0%, 52%, 0.27);
}

.divrow2 {
  padding: 0px 13px;
  font-size: 12px;
}

.bo {
  border-bottom: 1px solid rgba(50, 52, 65, 0.1);
}

.iner_2 {
  padding: 9px 2px;
  font-size: 11px;
  font-weight: 500;
  height: 51px;
}

.metitle {
  font-size: 11px;
}

.metitlesub {
  color: #8c8fa7;
  font-size: 10px;
  font-weight: 400;
}

.box_back {
  width: 100%;
  opacity: 0.9;
  position: relative;
  background-color: #ef5350;
  height: 51px;
  position: absolute;
}

.boxsub {
  background-color: rgba(0, 0, 0, 0.3);
  color: #fff;
  padding: 5px 10px;
  border-radius: 3px;
  margin: 10px;
}

.inner_3 {
  margin: 2px;
  height: 43px;
}

.lefval {
  width: 50%;
  float: left;
}

.overcell {
  position: relative;
  padding-left: 0px;
  z-index: 1;

  height: 0;
}

.newcell {
  font-size: 10px;
}

.calred {
  margin-bottom: 12px;
  padding: 14px 0 15px 8px;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-align: start;
  -webkit-align-items: flex-start;
  -ms-flex-align: start;
  align-items: flex-start;
  border-style: solid;
  border-width: 1px;
  border-color: rgba(50, 52, 65, 0.1);
  border-radius: 2px;
  background-color: #ff000017;
  font-size: 14px;
}

.calfont {
  font-size: 12px;
  padding: 5px;
  color: red;
}

.calfont2 {
  font-size: 12px;

  color: red;
}

.performance-preview-wrap {
  margin-bottom: 18px;
  padding: 14px;
  border-radius: 3px;
  background-color: #fff;
}

.performance-preview-wrap,
.performance-preview-wrap.history {
  overflow: visible;
  border-style: solid;
  border-width: 1px;
  border-color: rgba(50, 52, 65, 0.1);
}

.modal-header {
  /* display: inline; */
  align-items: flex-start;
  justify-content: space-between;
  padding: 1rem;
  border-bottom: 1px solid #e9ecef;
  border-top-left-radius: 2px;
  border-top-right-radius: 2px;
}

.nodatapadding {
  padding-top: 5px;
}

@media (min-width: 576px) {
  .modal-dialog {
    max-width: 80%;
    margin: 1.75rem auto;
  }
}

.modal-title {
  margin-bottom: 0;
  text-align: center;
}

.timer {
  padding: 10px 20px;
  border-radius: 5px;
  background-color: #ebebeb;
}

.poptop {
  width: 250px;
  margin: auto;
  font-size: 19px;
  font-weight: bold;
}

.topdivleft:hover {
  background-color: #373d4b;
  color: #fff;
}

.tabbutton {
  display: flex;
  /* margin-right: 15px; */
  /* padding: 15px; */
  width: 180px;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-flex-basis: 25%;
  -ms-flex-preferred-size: 25%;
  flex-basis: 25%;
  /* border-style: solid; */
  /* border-width: 1px; */
  border-bottom-color: rgba(0, 0, 0, 0.24);
  border-radius: 5px;
  background-color: transparent;
  box-shadow: 0 2px 0 0 #fff;
  -webkit-transition: background-color 0.2s ease, color 0.2s ease,
    box-shadow 0.2s ease;
  transition: background-color 0.2s ease, color 0.2s ease, box-shadow 0.2s ease;
  text-align: center;
}

.tavbtncolor1 {
  border: 1px solid #5c6bc0;
  color: #5c6bc0;
  height: auto;
}

.tavbtncolor1:hover {
  border: 1px solid #5c6bc0;
  background-color: #5c6bc0;
  color: #fff;
  height: auto;
  box-shadow: 0 2px 0 0 #18277c;
}

.nav-pills .nav-link.tavbtncolor1.active {
  border: 1px solid #5c6bc0;
  background-color: #5c6bc0;
  color: #fff;
  height: auto;
  box-shadow: 0 2px 0 0 #18277c;
}

.tavbtncolor2 {
  border: 1px solid #64b5f6;
  color: #64b5f6;
}

.tavbtncolor2:hover {
  border: 1px solid #64b5f6;
  background-color: #64b5f6;
  color: #fff;
  height: auto;
  box-shadow: 0 2px 0 0 #0672c9;
}

.tavbtncolor2 .active {
  border: 1px solid #64b5f6;
  background-color: #64b5f6;
  color: #fff;
  height: auto;
  box-shadow: 0 2px 0 0 #0672c9;
}

.nav-pills .nav-link.tavbtncolor2.active {
  border: 1px solid #64b5f6;
  background-color: #64b5f6;
  color: #fff;
  height: auto;
  box-shadow: 0 2px 0 0 #0672c9;
}

.tavbtncolor3 {
  border: 1px solid #ba68c8;
  color: #ba68c8;
}

.tavbtncolor3:hover {
  border: 1px solid #ba68c8;
  background-color: #ba68c8;
  color: #fff;
  height: auto;
  box-shadow: 0 2px 0 0 #7b3f86;
}

.nav-pills .nav-link.tavbtncolor3.active {
  border: 1px solid #ba68c8;
  background-color: #ba68c8;
  color: #fff;
  height: auto;
  box-shadow: 0 2px 0 0 #7b3f86;
}

.tavbtncolor4 {
  border: 1px solid #ba68c8;
  color: #ff8a65;
}

.tavbtncolor4:hover {
  border: 1px solid #ff8a65;
  background-color: #ff8a65;
  color: #fff;
  height: auto;
  box-shadow: 0 2px 0 0 #da3806;
}

.nav-pills .nav-link.tavbtncolor4.active {
  border: 1px solid #ff8a65;
  background-color: #ff8a65;
  color: #fff;
  height: auto;
  box-shadow: 0 2px 0 0 #da3806;
}

.nav-pills .nav-link {
  border-radius: 5px;
}

.cunav li {
  padding: 10px 20px;
}

.fonttab {
  padding: 20px;
  font-size: 20px;
}

.tabborderline {}

.nav-pills .nav-link.tab4 {
  border-radius: 0px;
  padding: 10px 26px;
}

.nav-pills .nav-link.tab4 {
  border: 1px solid #373d4b;
}

.tabborderline li a:first-child {
  border-radius: 5px 0px 5px 0px;
}

.tabborderline li a {
  color: #373d4b;
}

.input-copy {
  height: 50px;
  border-style: none;
  border-radius: 5px;
  background-color: #ebecf6;
  font-weight: 400;
}

.emp ul {
  padding: 0px;
  margin: 0px;
  margin-top: 10px;
  list-style: none;
}

.emp ul li {
  float: left;

  padding-left: 10px;
  padding-top: 10px;
}

.emp label {
  text-transform: uppercase;
}

.tabicon {
  font-size: 42px;
  text-align: center;
}

.tabtext {
  text-align: center;
  font-weight: bold;
  padding: 8px;
}

button,
input,
optgroup,
select,
textarea {
  margin: 0;
  font-size: inherit;
  line-height: inherit;
}

.popup {
  width: 58%;
  margin: 0 auto;
}

.box3 {
  background: white;
  border-radius: 10px;
  height: 400px;
  box-shadow: 0 0 2px 1px hsla(0, 0%, 52%, 0.27);
  padding: 20px;
}

.nav-link,
.navbar {
  padding: 7px 8px;
}

.popvagents ul {
  padding: 0px;
  margin: 0px;
  list-style: none;
  width: 100px;
  margin-top: 34px;
  font-weight: bold;
}

.popvagents ul li {
  margin: 11px 0px;
}

@media (min-width: 768px) and (max-width: 991.98px) {
  .page-breadcrumb .page-title {
    font-size: 10px;
    font-weight: bold;
  }

  .small-right {
    width: 40px;
    margin-right: 10px;
  }

  .popup {
    width: 100%;
    margin: 0 auto;
  }

  .sidetexth {
    font-size: 10px;
    line-height: 1.5;
  }

  .calibrationtask-right {
    background-color: #ffd54f;
    color: #373d4b;
    font-size: 10px;
    font-weight: 500;
    text-align: center;
  }
}

@media (max-width: 766px) {
  .page-breadcrumb .page-title {
    font-size: 10px;
    font-weight: bold;
  }
}

/* Small devices (landscape phones, less than 768px)*/
@media (max-width: 768px) {
  .divrow2 {
    padding: 0px 7px;
    font-size: 12px;
  }

  .boxsub {
    background-color: rgba(0, 0, 0, 0.3);
    color: #fff;
    padding: 5px 2px;
    border-radius: 3px;
    margin: 10px;
  }

  .nav-link,
  .navbar {
    padding: 14px 12px;
  }

  .tabtext {
    font-size: 4px;
    text-align: center;
    font-weight: bold;
    padding: 2px;
    line-height: 2;
    display: none;
  }

  .tabicon {
    font-size: 22px;
    /* text-align: center; */
    padding-right: 18px;
    margin: 0px;
  }

  .disabledtextinput {
    width: 100%;
    height: 33px;
    padding: 9px 11px;
    border: 1px solid #e8e8eb;
    border-radius: 5px;
    background-color: #f6f6f6;
    font-size: 10px;
  }

  .calibrationtask-right {
    background-color: #ffd54f;
    color: #373d4b;
    font-size: 7px;
    font-weight: bold;
    text-align: center;
  }

  .small-right {
    width: 40px;
    margin-right: 10px;
  }

  .sidetexth {
    font-size: 10px;
    line-height: 1.5;
  }
}

/*Medium devices (tablets, less than 992px)*/
@media (max-width: 991px) {
  .singleline {
    width: 50px;
  }
}

/*Large devices (desktops, less than 1200px)*/
@media (max-width: 1199px) {
  .profilename2 {
    font-size: 13px;
    font-weight: 600;
    width: 80px;
  }
}

@media (min-width: 992px) and (max-width: 1199.98px) {
  .scorecard-wrap {
    padding: 10px 4px;
  }

  .scorecard-wrap {
    font-size: 10px;
  }
}

@media (min-width: 1200px) {
  .profilename2 {
    font-size: 13px;
    font-weight: 600;
    width: 81px;
  }
}

@media only screen and (min-width: 1824px) {
  .profilename2 {
    font-size: 13px;
    font-weight: 600;
    width: 120px;
  }

  .topdivleft {
    display: inline-block;
    width: 245px;
    padding: 12px 27px;
    text-align: center;
    font-size: 14px;
    background-color: rgba(104, 108, 134, 0.11);
  }

  /* Styles */
}

@media (min-width: 481px) and (max-width: 767px) {
  .popup {
    width: 100%;
    margin: 0 auto;
  }
}

/* 
  ##Device = Most of the Smartphones Mobiles (Portrait)
  ##Screen = B/w 320px to 479px
*/

@media (min-width: 320px) and (max-width: 480px) {
  .popup {
    width: 100%;
    margin: 0 auto;
  }
}



.task-management table.dataTable thead .sorting {
  background-image: none !important;
}

.task-management .newStatus {
  border-right: 5px solid #156DAB !important;
}

.task-management .initials_hist {
  float: left;
  background-image: linear-gradient(45deg, #212529, #495057);
  background-color: #ccc;
  border-radius: 100%;
  color: #fff;
  padding: 10px;
  width: 30px;
  height: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 12px;
  margin-left: 3px;
  margin-right: 6px;
}

.task-management tr:hover {
  background-color: #f5f8fa !important;
}

.task-management tbody>tr.active {
  background-color: #e5eff8 !important;
}

.task-management tr {
  border: 1px solid #ccc
}

.task-management .profile {
  width: 100% !important;
}

.task-management .profilename {
  width: 100% !important;
}

.task-management .profilename {
  white-space: normal !important;
}

.task-management .btn-primary:hover {
  background-color: #D7EAFF !important;
  border: 1px solid #D7EAFF !important;
}

.swal2-checkbox {
  display: none !important;
  margin: 0px;
}

#swal2-checkbox {
  display: none;
  margin: 0px;
}

.swal2-popup .swal2-styled.swal2-confirm {
  background-color: #22c7a9 !important;
}