.table {
    border-color: #dee2e6;
}

.dataTables_wrapper {
    padding: 0;
    position: relative;
    overflow: auto;
    width: 100%;
}

.dataTables_wrapper .dataTables_paginate {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
}

.dataTables_wrapper .dataTables_paginate .paginate_button:active {
    border: 1px solid cadetblue !important;
    color: cadetblue !important;
    background-color: rgba(34, 198, 171, .17) !important;
    background: rgba(34, 198, 171, .17) !important;
    font-weight: 700 !important;
    border-radius: 3px !important;
    font-size: 13px !important;
}

.dataTables_wrapper .dataTables_paginate .paginate_button:hover {
    border: 1px solid cadetblue !important;
    color: cadetblue !important;
    background-color: rgba(34, 198, 171, .17) !important;
    background: rgba(34, 198, 171, .17) !important;
    font-weight: 700 !important;
    border-radius: 3px !important;
    font-size: 13px !important;
}

.dataTables_wrapper .dataTables_paginate {
    color: cadetblue !important;
    font-size: 13px !important;
    margin: 5px;
}

.dataTables_wrapper .dataTables_paginate .paginate_button.disabled:hover {
    background-color: #f9fafb !important;
    border: 0px !important;
}

.dataTables_wrapper .display td {
    text-align: center;
}

.dataTables_scrollHead .dataTables_scrollHeadInner {
    /* padding-right: 0px !important; */
}

.dataTables_wrapper tbody {
    border-right: 2px solid #dfe3eb;
    border-left: 2px solid #dfe3eb;
}

.lr-no-border .dataTables_wrapper tbody {
    border-right: 0px;
    border-left: 0px;
}

.dataTables_wrapper tbody tr td {
    border-right-color: transparent;
    /* border-bottom: 1px solid #dfe3eb !important; */
    font-size: 12px;
    font-weight: normal !important;
    text-align: center;
    vertical-align: middle;
    border-left-color: transparent;
    background-color: #fff;
    padding: 15px 20px !important;
}

table.dataTable.cell-border>tbody>tr>td {
    border-top: 1px solid transparent;
    border-right: 1px solid transparent;
}

table.dataTable>thead>tr>th {
    border-right: 1px solid #dfe3eb;
    background-color: #f5f8fa;
    border-bottom-color: #dfe3eb !important;
    border-top: 1px solid #dfe3eb !important;
    text-transform: uppercase;
    font-size: 12px;
    text-align: center;
    font-weight: 500;
    padding: 15px 30px !important;
    white-space: nowrap !important;
}

/* .dataTables_wrapper tr td {
    text-align: center;
    white-space: nowrap !important;
    max-width: fit-content;
} */


table.dataTable thead>tr>th.sorting:before {

    content: "\f0dd" !important;
    font-family: FontAwesome;
    font-size: 10px;
    font-weight: normal;
    bottom: 30% !important;
}

table.dataTable thead .sorting_desc:before {
    bottom: 30% !important;
}

table.dataTable thead>tr>th.sorting:after {
    content: "\f0de" !important;
    font-family: 'FontAwesome' !important;
    font-size: 10px;
    font-weight: normal;

}

.custom-data-table {}



.custom-data-table thead tr td {
    border-right: 1px solid #dfe3eb;

}


.dataTables_wrapper .dataTables_scroll .dataTables_scrollBody {
    border: 0;
}

/* Override sorting header --> DataTable Bug */
.dataTables_scrollBody>table>thead>tr {
    visibility: collapse;
    height: 2px !important;
}

.col-header-img {
    width: 20px !important;
}

.hide-header thead {
    visibility: collapse;
}

.transparent-bg td {
    background-color: transparent !important;
}

/* CSS classes for text alignment */
.left-align {
    text-align: left !important;
}

.right-align {
    text-align: right !important;
}

.center-align {
    text-align: center !important;
}