/* new summary css*/
.top_bar .input-group {
    min-width: 250px;
    width: 250px;
    margin-right: 1em;
    margin-bottom: 0 !important;
    display: flex;
    flex-wrap: nowrap;
    /* border-color: #000; */
}

.top_bar .input-group .form-control {
    font-size: 13px !important;
    /*color: #323441;*/
    padding: 8px 5px 8px 15px;
    border-color: #e4e7eb;
    color: #495057 !important;
}

#searchautofill.form-control {
    padding: 0;
    border-color: transparent !important;
}

.input-group .k-widget {
    border-color: #dee2e6;
}

.top_bar .input-group .form-control:focus {
    outline: none !important;
    box-shadow: none !important;
}

.top_bar {
    padding: 15px;
}

.top_bar .input-group-text {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    padding: 5px 12px;
    margin-bottom: 0;
    font-size: 14px;
    font-weight: 400;
    line-height: 1.5;
    color: #fff;
    text-align: center;
    white-space: nowrap;
    background-color: #22c7a9;
    border: 1px solid #ced4da;
    border-radius: 4px;
}

.modal-content {
    padding: 15px;
}

div#eval_history_length {
    text-align: right;
    margin-top: -50px;
    font-weight: 600;
}

#eval_history_length select.form-control-sm:not([size]):not([multiple]) {
    height: 32px !important;
    font-size: 14px;
}

.evaluation_history .card-header h3 {
    font-size: 14px;
    margin: 0;
    font-weight: 600;
}

.evaluation_history .card-header {
    padding: 15px;
    background-color: #f5f8fa;
}

table.dataTable thead .sorting:before,
table.dataTable thead .sorting_asc:before,
table.dataTable thead .sorting_desc:before,
table.dataTable thead .sorting_asc_disabled:before,
table.dataTable thead .sorting_desc_disabled:before {
    content: "\f0dd";
    font-family: FontAwesome;
    font-size: 18px;
    font-weight: normal;
}

table.dataTable thead .sorting:after,
table.dataTable thead .sorting_asc:after,
table.dataTable thead .sorting_desc:after,
table.dataTable thead .sorting_asc_disabled:after,
table.dataTable thead .sorting_desc_disabled:after {
    content: "\f0de";
    font-family: FontAwesome;
    font-size: 18px;
    font-weight: normal;
    right: 1em;
}

th.sorting_desc::before {
    color: #22c6ab;
}

th.sorting_asc::after {
    color: #22c6ab;
}

table.dataTable thead .sorting:before,
table.dataTable thead .sorting:after,
table.dataTable thead .sorting_asc:before,
table.dataTable thead .sorting_asc:after,
table.dataTable thead .sorting_desc:before,
table.dataTable thead .sorting_desc:after,
table.dataTable thead .sorting_asc_disabled:before,
table.dataTable thead .sorting_asc_disabled:after,
table.dataTable thead .sorting_desc_disabled:before,
table.dataTable thead .sorting_desc_disabled:after {
    bottom: 0.5em;
    opacity: 0.7 !important;
}

table.dataTable thead .sorting:before,
table.dataTable thead .sorting_asc:before,
table.dataTable thead .sorting_desc:before,
table.dataTable thead .sorting_asc_disabled:before,
table.dataTable thead .sorting_desc_disabled:before {
    content: "\f0dd";
    font-family: FontAwesome;
    font-size: 18px;
    font-weight: normal;
}

table.dataTable thead .sorting:after,
table.dataTable thead .sorting_asc:after,
table.dataTable thead .sorting_desc:after,
table.dataTable thead .sorting_asc_disabled:after,
table.dataTable thead .sorting_desc_disabled:after {
    content: "\f0de";
    font-family: FontAwesome;
    font-size: 18px;
    font-weight: normal;
    right: 1em;
}

th.sorting_desc::before {
    color: #22c6ab;
}

th.sorting_asc::after {
    color: #22c6ab;
}

table.dataTable thead .sorting:before,
table.dataTable thead .sorting:after,
table.dataTable thead .sorting_asc:before,
table.dataTable thead .sorting_asc:after,
table.dataTable thead .sorting_desc:before,
table.dataTable thead .sorting_desc:after,
table.dataTable thead .sorting_asc_disabled:before,
table.dataTable thead .sorting_asc_disabled:after,
table.dataTable thead .sorting_desc_disabled:before,
table.dataTable thead .sorting_desc_disabled:after {
    bottom: 0.5em;
    opacity: 0.7 !important;
}

table.dataTable thead .sorting:before,
table.dataTable thead .sorting_asc:before,
table.dataTable thead .sorting_desc:before,
table.dataTable thead .sorting_asc_disabled:before,
table.dataTable thead .sorting_desc_disabled:before {
    content: "\f0dd";
    font-family: FontAwesome;
    font-size: 18px;
    font-weight: normal;
}

table.dataTable thead .sorting:after,
table.dataTable thead .sorting_asc:after,
table.dataTable thead .sorting_desc:after,
table.dataTable thead .sorting_asc_disabled:after,
table.dataTable thead .sorting_desc_disabled:after {
    content: "\f0de";
    font-family: FontAwesome;
    font-size: 18px;
    font-weight: normal;
    right: 1em;
}

th.sorting_desc::before {
    color: #22c6ab;
}

th.sorting_asc::after {
    color: #22c6ab;
}

table.dataTable thead .sorting:before,
table.dataTable thead .sorting:after,
table.dataTable thead .sorting_asc:before,
table.dataTable thead .sorting_asc:after,
table.dataTable thead .sorting_desc:before,
table.dataTable thead .sorting_desc:after,
table.dataTable thead .sorting_asc_disabled:before,
table.dataTable thead .sorting_asc_disabled:after,
table.dataTable thead .sorting_desc_disabled:before,
table.dataTable thead .sorting_desc_disabled:after {
    bottom: 8px;
    right: 5px;
    opacity: 0.7 !important;
    font-size: 12px;
}

a.previous {
    color: #949494;
    padding: 4px 8px;
    border: 1px solid transparent;
    border-radius: 3px;
    font-size: 13px;
}

a.paginate_button.current {
    border: 1px solid #0e7e97;
    background-color: rgba(34, 198, 171, .17);
    color: #0e7e97;
    padding: 4px 8px;
    font-weight: 700;
    border-radius: 3px;
}

a.text-secondary {
    text-decoration: underline;
    cursor: pointer;
    color: #5c6bc0 !important;
}

a.paginate_button {
    padding: 4px 8px;
    color: #0e7e97;
}

a.paginate_button.disabled {
    color: #949494;
    padding: 4px 8px;
    border: 1px solid transparent;
    border-radius: 3px;
    font-size: 13px;
}

div.dataTables_wrapper div.dataTables_paginate {
    text-align: center;
}

div.dataTables_wrapper div.dataTables_paginate ul.pagination {
    margin: 2px 0;
    font-size: 13px !important;
    display: flex;
    justify-content: center !important;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
}

.page-item.disabled .page-link {
    color: #949494 !important;
    border: 0;
}

.page-link:not(:disabled):not(.disabled),
summary {
    cursor: pointer;
    color: cadetblue;
}

.page-link {
    border: 0px !important;
    padding: 4px 8px !important;
    margin-left: 0px !important;
}

.page-item.active .page-link {
    border: 1px solid #0e7e97 !important;
    background-color: rgba(34, 198, 171, .17);
    color: #0e7e97;
    font-weight: 700;
    border-radius: 3px;
    font-size: 13px;
    padding: 4px 8px;
}

.page-link:hover {
    border: 1px solid #0e7e97 !important;
    background-color: rgba(34, 198, 171, .17);
    color: #0e7e97;
    font-weight: 700;
    border-radius: 3px;
    font-size: 13px;
    padding: 4px 8px;
}

.m-l-0 {
    margin-left: 0px !important;
}

div.dataTables_wrapper div.dataTables_paginate {
    padding: 16px 0px;
}

.evaluation_history .table thead tr th {
    border-bottom-style: none;
    background-color: #f5f8fa;
    font-size: 12px;
    font-weight: 700;
    text-transform: uppercase;
    border-bottom: 1px solid #dfe3eb;
    border-left-style: solid;
    border-left-color: #f5f8fa;
    border-left: 1px solid #cbd6e2;
    white-space: nowrap;
    line-height: 18px;
    padding: 8px 20px !important;
    text-align: center;
    box-sizing: border-box !important;
}

.evaluation_history .table tbody tr td {
    padding: 10px 20px !important;
    font-weight: normal;
    font-size: 12px !important;
    text-align: center;
}

td a {
    text-decoration: underline !important;
}

td:first-child {
    text-align: left !important;
}

div.dataTables_scrollBody table {
    margin-top: -17px !important;
}

td img {
    cursor: pointer;
    width: 18px !important;
}

/* td a {
    color: #5c6bc0 !important;
    font-weight: normal;
} */
td a:hover {
    text-decoration: underline !important;
}

#eval_history_wrapper {
    padding: 0;
}

#eval_history_details .btn-close {
    width: 48px;
    height: 48px;
    cursor: pointer;
    background-color: #dfe3eb;
    border-radius: 5px;
    border: none !important;
    display: flex;
    justify-content: center;
    align-items: center;
}

#eval_history_details .btn-close img {
    width: 20px;
}

#eval_history_details .modal-header {
    align-items: center;
    padding: 10px 15px;
}

#eval_history_details .btn-close:hover {
    background-color: #22c6ab;
}

.evaluation_history .table td,
.table th {
    vertical-align: middle;
    /* border-top: 1px solid #dee2e6;*/
}

div.dataTables_wrapper div.dataTables_length label {
    font-weight: 550;
}

#eval_history_details .modal-dialog {
    max-width: 80%;
}

#eval_history_details .modal-content {
    padding: 0px;
    min-height: 85vh;
    max-height: 90vh;
    overflow: auto;
}

#eval_history_details .modal-body {
    padding: 15px;
}

.btn-submit {
    background-color: #22c7a9;
    color: #fff;
    font-size: 14px !important;
    font-weight: 600;
    padding: 8px 15px;
}

#eval_history_details .form-group label {
    font-weight: 600;
    font-size: 13px;
}

#eval_history_details .modal-title {
    font-weight: 600;
    font-size: 16px;
}

#eval_history_details .form-control {
    /* font-weight: 600; */
    font-size: 13px !important;
    padding: 5px 10px;
    font-weight: 600;
}

#eval_history_details .form-control[readonly] {
    border-color: transparent !important;
    background-color: #f8f9fa;
}

td a {
    cursor: pointer;
}

.categories_questionsandans {
    font-weight: bold;
    padding-top: 15px;
}

.question_block {
    padding: 10px 0px;
}

.categories_questionsandans h3 {
    font-weight: 600;
    font-size: 14px;
    margin: 0;
}

.w-80 {
    width: 80%;
}

.categories_questionsandans .questionn {
    font-size: 14px;
    padding-bottom: 10px;
}

.categories_questionsandans .answerr {
    font-size: 14px;
    padding-bottom: 10px;
    font-weight: bold;
    text-transform: uppercase;
}

.categories_questionsandans .answerr.green {
    color: #2ac92a;
}

.categories_questionsandans .w-20 {
    width: 20%;
    text-align: center !important;
    font-size: 14px;
}

.categories_questionsandans .card-header {
    padding: 15px 15px;
    margin-bottom: 0;
    background-color: #dee2e6;
    border-bottom: 1px solid rgba(0, 0, 0, .125);
}

#eval_history_details h3 {
    font-weight: 600;
    font-size: 14px;
    margin: 0;
}

#eval_history_details .card-header {
    padding: 15px 15px;
    margin-bottom: 0;
    background-color: #dee2e6;
    border-bottom: 1px solid rgba(0, 0, 0, .125);
}

.Editor-editor:focus {
    border: 1px solid #22ccff !important;
}

.btn-default {
    background-color: #22c6ab !important;
    border-radius: 3px;
}

.form-check-label {
    margin-bottom: 0;
    font-weight: 600;
    padding-left: 10px;
    font-size: 14px;
}

#eval_history_details .btn-close {
    font-size: 14px !important;
}

#evaluationSummaryExport select.form-control {
    height: 38px;
    font-size: 14px !important;
    border-radius: 3px;
    margin-bottom: 10px;
    font-weight: 600;
}

.k-state-default h3 {
    font-size: 14px;
    font-weight: normal;
}

.k-footer {
    font-size: 14px;
    font-weight: 600;
    padding: 5px 15px;
}

.evaluation_history .card {
    margin-bottom: 0;
}

.evaluation_history .card-body {
    padding: 0;
}