#cox_incentive .modal-dialog {
    max-width: 95%;
}

#cox_incentive .inc_leftblock {
    background-color: #f5f8fa;
    padding: 20px 0px 0px 20px;
}

#cox_incentive .inc_right_block1 {
    background-color: #f5f8fa;
    padding: 30px 20px 0px 0px;
    height: 100%;
}



#cox_incentive .inc_profile.flex.align-start {
    padding-bottom: 15px;
}

#cox_incentive .fs {
    padding: 10px;
    margin: auto;
    justify-content: center;
    display: flex;
    line-height: 44px;
    background-color: #cccccc75;
    border-radius: 60px;
    height: 63px !important;
    width: 63px !important;
    margin-right: 10px;
    font-weight: 700;
    font-size: 20px;
    margin-bottom: 10px;
    box-shadow: 0 4px 4px 1px rgb(0 0 0 / 15%);
}

#cox_incentive .inc_name_letters .fs {
    width: 45px !important;
    line-height: 23px;
    height: 45px !important;
    font-size: 16px;
    padding: 0;
    display: flex;
    justify-content: center;
    justify-content: center;
    align-items: center;
    border-radius: 45px !important;
    font-weight: 600;
    box-shadow: 0px 0px 5px #ccc;
    letter-spacing: 1px;
    margin-right: 1em;
    background-color: #e3e4e5;
}

#cox_incentive .namee {
    font-size: 23px;
    font-weight: 500;
}

#cox_incentive .inc_name_details p {
    font-size: 12px;
    margin-bottom: 0px;
}

#cox_incentive .incentive_block1 .space-between {
    justify-content: space-between !important;
}

#cox_incentive .estimate_block {
    background: #fff;
    box-shadow: 0 4px 4px 1px rgb(0 0 0 / 15%);
    padding: 15px;
    min-height: 85px;
    min-width: 195px;
    width: 195px;
    border-radius: 5px;
    height: 117px;
    margin-right: 20px;
}

#cox_incentive .white_bg {
    color: #75b48f;
}

#cox_incentive .inc_month.flex.align-center {
    font-weight: 600;
    font-size: 19px;
}

#cox_incentive .inc_dollar_cxpb {
    font-size: 30px;
    font-weight: 600;
    margin-top: 15px;
    margin-bottom: 10px;
}

#cox_incentive .inc_green {
    color: #75b48f;
}

#cox_incentive .inc_red {
    color: #e34819;
}

#cox_incentive .incentive_block2 .table {
    margin-bottom: 0px;
}

#cox_incentive .table thead tr th {
    text-transform: uppercase !important;
}

#cox_incentive .incentive_block2 .table tr td {
    white-space: nowrap;
}

#cox_incentive .incentive_block2 .table tbody tr td:first-child {
    text-align: left !important;
}

#cox_incentive .term_cond {
    padding-top: 15px;
}

#cox_incentive .inc_terms_cond {
    font-size: 12px;
    padding: 0px 20px 15px 20px;
}

#cox_incentive .inc_details {
    padding: 15px;
}

#cox_incentive .inc_details button.btn.btn-primary {
    background: #348bf2;
    border-color: #348bf2;
    text-transform: uppercase;
    font-size: 12px;
}

#cox_incentive a:not([href]):not([tabindex]),
#cox_incentive a:not([href]):not([tabindex]):focus,
#cox_incentive a:not([href]):not([tabindex]):hover {
    color: #fff;
    text-decoration: none
}

#cox_incentive .inc_details .mr-5 {
    margin-right: 5px !important;
}

#cox_incentive .bgg {
    background: #fff;
    padding: 15px 0px 0px 0px;
    box-shadow: 0px 0px 5px #ccc;
    border-radius: 5px;
}

#cox_incentive .bgg .pl-15 {
    padding: 0px 15px;
}

#cox_incentive .inc_potential_tile {
    background: #868686;
    min-width: 195px;
    width: 195px;
    color: #fff;
    padding: 15px;
    min-height: 85px;
    box-shadow: 0 4px 4px 1px rgb(0 0 0 / 15%);
    border-radius: 5px;
    margin: 0px 0px 15px 0px;
    height: 117px;
}

#cox_incentive .inc_val {
    margin: 15px 0px;
    /* font-weight: 600; */
    letter-spacing: 3px;
    font-size: 25px;
}

#cox_incentive .show_money {
    margin-bottom: 13px;
    padding: 10px 6px;
}

#cox_incentive .show_money .btn {
    width: 100%;
    background: #414d5a;
    text-transform: uppercase;
    border-color: #414d5a;
    font-size: 12px;
    padding: 5px;
}

#cox_incentive .show_money_gif {
    margin-bottom: 10px;
}

#cox_incentive .show_money_gif img {
    box-shadow: 0 4px 4px 1px rgb(0 0 0 / 15%);
    width: 156px;
}

#cox_incentive .potential_table {
    padding-top: 0px;
}

#cox_incentive .potential_table .table tr td {
    padding: 6px 20px !important;
}

#cox_incentive .inc_input input {
    height: 25px;
}

#cox_incentive .table tbody tr td {
    min-height: 40px;
    padding: 10px 20px !important;
    text-align: center;
    font-weight: 400;
    font-size: 12px;
    line-height: 1.4em;
    border-bottom: 1px solid #dfe3eb;
    box-sizing: border-box !important;
}

#cox_incentive .inc_dollar_s2s {
    font-size: 30px;
    font-weight: 600;
    margin-top: 15px;
    margin-bottom: 10px;
}